import styled, { css } from "styled-components";

export const ContextMenu = styled.div`
  position: absolute;
  width: 126px;
  background-color: #bababa;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
  ${({ top, left }) => css`
    top: ${top-30}px;
    left: ${left-50}px;
    z-index: 100;
  `}
  ul {
    box-sizing: border-box;
    padding: 10px;
    margin: 0;
    list-style: none;
  }
  ul li {
    padding: 18px 12px;
  }
  // workaround
  .intl-tel-input {
    display: table-cell;
  }
  .intl-tel-input .selected-flag {
    z-index: 4;
  }
  .intl-tel-input .country-list {
    z-index: 5;
  }
  .input-group .intl-tel-input .form-control {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
  }
  /* hover */
  ul li:hover {
    cursor: pointer;
    background-color: #000000;
  }
  
`;
