import styled from "styled-components";

const ThemeContainer = styled.div`
  position: relative;
  max-width: 1446px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 35px;
  @media (max-width: 767px) {
    padding: 0px 15px 25px;
  }
`;

const ThemeCard = styled.div`
  max-width: 1446px;
  @media(min-width: 768px){
    background: #ffffff;
    box-shadow: 0px 12px 250px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-inline: auto;
  }
  .mobile-up-header{
    background: #D39B2C;
    border-radius: 0px 0px 111.5px 111.5px;
    height:185px;
    margin-left:-15px;
    margin-right:-15px;
    padding:25px 25px 25px 15px;
    h1{
      font-size:21px;
      color:#fff;
    }
    i{
      font-size:40px;
      color:#fff;
      line-height:0.6;
    }
    button{
      padding-top:1px;
      color:#fff;
      font-size:17px;
    }
  }
  .avatar-layer img{
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    margin-top: -110px;
    margin-left: auto;
    margin-right: auto;
  }
  .head_ {
    @media(min-width: 768px){
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      border-bottom: 1px solid #c0bec7;
      padding: 0 30px;
    }
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      margin: 0;
    }
  }
  .body_ {
    @media(max-width: 767px){
      padding: 0px 5px 50px;
      .bottom-text{
        text-align:center;
      }
    }
    @media(min-width: 768px){
      padding: 30px;
    }
  }
`;

const P = styled.p`
  position: relative;
  color: ${(props) => (props.color ? props.color : "inherit")};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  font-size: ${(props) => (props.size ? props.size : "14px")};
  line-height: ${(props) => (props.lh ? props.lh : "17px")};
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
  max-width: ${(props) => props.maxWidth};
  text-align: ${(props) => props.align};
  &.grey {
    color: #bdbdbd;
  }
  @media (max-width: 576px) {
    font-size: 13px;
  }
`;

const ThemeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 19px 82px;
  height: 50px;
  border-radius: 25px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #000000;
  &:disabled{
    opacity:0.7;
  }
  background: linear-gradient(
    267.17deg,
    #e0aa3e 0%,
    #ffce40 49.63%,
    #b88a44 100%
  );
  &.started-btn{
    @media (max-width: 576px) {
      margin-left:auto;
      margin-right:auto;
    }
  }
`;
const HomeButton = styled.button`
  z-index: 10;
  padding: 14px 30px;
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
  width: 257px;
  height: 45px;
  background: #f7f7f7;
  box-shadow: 0px -2px 10px 0px #0000000d, -26px 27px 31px -4px rgb(0 0 0 / 7%);
  border-radius: 5px;
  border: none;
  font-weight: 700;
  transition: 0.2s linear;
  &:hover {
    background-color: var(--theme-clr);
    color: white;
  }
`;
const Label = styled.label`
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-weight: ${(props) => (props.weight ? props.weight : 600)};
  line-height: 17px;
  color: ${(props) => (props.color ? props.color : "#828282")};
  margin-bottom: ${(props) => props.mb};
  padding-top: ${(props) => props.pt};
`;
const Heading = styled.h1`
  position: relative;
  z-index: 10;
  max-width: ${(props) => props.maxWidth};
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
  margin-left: auto;
  margin-right: auto;
  padding-bottom: ${(props) => props.pb};
  text-align: ${(props) => props.align};
  font-weight: ${(props) => (props.weight ? props.weight : 600)};
  font-size: ${(props) => (props.size ? props.size + "px" : "24px")};
  line-height: 29px;
  color: ${(props) => props.color};
  text-shadow: 0px 100px 80px rgba(0, 0, 0, 0.15),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.113889),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0911111),
    0px 20px 13px rgba(0, 0, 0, 0.075),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0588889),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0361111);
  @media (max-width: 768px) {
    font-size: 22px;
  }
  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const MaxWidth = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.maxWidth};
`;

const NumberPagination = styled.div`
  .swiper-pagination {
    top: 0;
  }
  .swiper-pagination-bullet {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #828282;
    opacity: 1;
    margin: 0 15px !important;
    @media (min-width: 767px) {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }
    @media (max-width: 768px) {
      width: 23px;
      height: 23px;
      font-size: 13px;
    }
  }
  .swiper-pagination-bullet-active {
    background: var(--theme-clr);
    color: #fff;
  }
`;

const Margin = styled.div`
  margin: ${(props) => props.margin};
`;

const BgGrey = styled.div`
  background: rgb(46, 46, 46, 0.05);
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;
const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
const Div = styled.div`
  display: block;
`;

const ApplyForm = styled.form`
  background: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 40px 0 125px 0;
  padding: 40px;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.0168519),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0274815),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.035),
    0px 20px 13px rgba(0, 0, 0, 0.0425185),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0531481),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.07);
  @media (max-width: 778px) {
    padding: 30px;
  }
  @media (max-width: 576px) {
    padding: 30px 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 45px;
  background: #f7f7f7;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 14px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  &::placeholder {
    color: #bdbdbd;
  }
`;

const Radio = styled.label`
  cursor: pointer;
  position: relative;
  display: block;
  padding-left: 28px;
  margin: ${(props) => (props.margin ? props.weight : "0 50px 10px 0")};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1px solid #bdbdbd;
    border-radius: 50%;
    &::after {
      content: "";
      position: absolute;
      display: none;
      top: 5px;
      left: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input:checked ~ .checkmark {
    background-color: black;
    border: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
`;

const BackDrop = styled.div`
  background: #2e2e2e;
  opacity: 0.2;
  z-index: 400;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 194px;
  background: #f7f7f7;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  padding: 14px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  &::placeholder {
    color: #bdbdbd;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const ListItem = styled.li`
  position: relative;
  font-size: 14px;
  line-height: 30px;
  color: var(--text-clr);
  margin: 0 0 15px 17px;
  &::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    left: -17px;
    top: 5px;
    background: #e0e0e0;
  }
`;
const Box = styled.div`
  display: block;
`;

const CustomFileUpload = styled.div`
  position: relative;
  width: max-content;
  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
`;

const BorderHeading = styled.div`
  flex: 1;
  height: 1px;
  border-bottom: 1px solid #e0e0e0;
`;
const AttachFileButton = styled.label`
  font-size: 14px;
  color: var(--theme-clr);
  cursor: pointer;
  span.attach-head {
    font-weight: 700;
  }
  span:not(.attach-head) {
    color: #828282;
  }
  svg {
    transform: rotate(45deg);
  }
`;
const DiscoverTemplateStyled = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  a{
    color:#111013;
  }
  small {
    background: linear-gradient(180deg, #ff8961 0%, #fd649d 100%);
    border-radius: 24px;
    width: 43px;
    height: 21px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  .discover-img img {
    border-radius: 24px 24px 0px 0px;
    height: 350px;
    object-fit: cover;
  }
  .btn-cirlce-round {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: 0.3s transform ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    &:nth-child(1) i {
      color: #f58a1b;
    }
    &:nth-child(2) i {
      color: #9a9a9a;
    }
    &:nth-child(3) i {
      color: #fd649d;
    }
    &:nth-child(4) i {
      color: #a7ed9d;
    }
    &:not(.lg-circle-btn) {
      height: 50px;
      width: 50px;
      i {
        font-size: 25px;
      }
    }
    &.lg-circle-btn {
      height: 60px;
      width: 60px;
      i {
        font-size: 30px;
      }
    }
  }
  .traslate-circles {
    margin-top: -30px;
    padding-left: 35px;
    padding-right: 35px;
  }
`;
export {
  ThemeContainer,
  ThemeCard,
  HomeButton,
  Heading,
  P,
  MaxWidth,
  NumberPagination,
  Label,
  Margin,
  BgGrey,
  ApplyForm,
  Input,
  Radio,
  FlexBox,
  Box,
  Div,
  ThemeButton,
  BackDrop,
  Textarea,
  List,
  ListItem,
  CustomFileUpload,
  AttachFileButton,
  BorderHeading,
  DiscoverTemplateStyled
};
