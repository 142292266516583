import React,{useState,useEffect} from "react";
import styled from "styled-components";
import {Pagination} from "../Components";
import { Box } from "../Style";
const DataTable = ({ column, rows }) => {
  const [posts, setPosts] = useState(rows);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  useEffect(() => {
    setPosts(rows)
  }, [rows])
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = pageNumber => setCurrentPage(pageNumber);
  return (
    <>
      <TableStyled>
        <thead>
          <tr>
            {column.map((item, index) => {
              return (
                <th key={index}>
                  <Box>{item.title}</Box>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((list, i) => {
            return (
              <tr key={i}>
                {column.map((item, index) => {
                  return (
                    <td key={i + index}>{item.field==="id"?i+1:list[item.field]}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableStyled>
      <Box className="d-flex justify-content-center">
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={posts.length}
          paginate={paginate}
        />
      </Box>
    </>
  );
};
const TableStyled = styled.table`
  display: table;
  border-collapse: collapse;
  width: 100%;
  tr,
  td,
  th {
    border: 0;
    padding: 0;
  }
  thead {
    tr th {
      &:first-child div {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-child div {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      div {
        padding: 17px;
        background: #f2f2f2;
        color: #828282;
        font-weight: 700;
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
  }
  tbody td {
    padding: 10px 17px;
    font-size: 13px;
  }
`;
export default DataTable;