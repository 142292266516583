import React, { useState } from "react";
import {
  AttachFileButton,
  Box,
  FlexBox,
  Input,
  Label,
  P,
  ThemeButton,
} from "../components/Style";
import styled from "styled-components";
import { Avatar } from "../components/AllImages";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { buildFormData, ValidationMessage } from "../helpers/helper";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReadFiles } from "../helpers/helper";
import * as yup from "yup";
import { toast } from "react-toastify";
import { SpinnrLoader } from "../components/Components";
import { updateAdminProfile } from "../constant/api/Api";
import { LOGIN_SUCCESS } from "../redux/constants/userConstant";
import { ASSET_URL } from "../constant/Keys";
const AdminProfile = () => {
  const UpdateProfileSchema = yup.object().shape({
    name: yup.string().required().min(3),
    email: yup.string().required(),
  });
  const [loader, setLoader] = useState(false);
  const [imageView, setImageView] = useState();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(UpdateProfileSchema),
  });
  const { admin_user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    setLoader(true);
    if (data.password !== "" && data.password.length < 6) {
      toast.error("סיסמא חייבת להיות לפחות בת 6 תווים");
    } else {
      const postData = data;
      console.log(postData);
      postData['profile'] = data.profile;
      const formData = new FormData();
      buildFormData(formData, postData);
      try {
        const res = await updateAdminProfile(formData);
        if (res.data.status == 200) {
          toast.success(res.data.message);
          dispatch({
            type: LOGIN_SUCCESS,
            admin: res.data.data,
            token: localStorage.datingAppauthToken,
          });
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error("משהו השתבש");
      }
    }
    setLoader(false);
  };
  return (
    <MdContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box className="mt-4">
          <P className="mb-3" color="#2E2E2E">
            Profile image
          </P>
          <FlexBox>
            <Box className="me-3">
              <img
                src={ imageView?imageView:admin_user?.profile?ASSET_URL+admin_user.profile:Avatar }
                height="100px"
                width="100px"
                className="rounded-circle"
                style={{objectFit:"cover"}}
              />
            </Box>
            <AttachFileButton>
              &nbsp;<span className="attach-head">Attach image</span>
              &nbsp;<span>|&nbsp;profile.jpg</span>
              <Controller
                control={control}
                name="profile"
                render={({ field: { onChange, value } }) => (
                <Form as="input" type="file" {...register("profile")} onChange={(e)=>{ReadFiles(e.target.files[0],setImageView);onChange(e.target.files[0])}} accept="image/*" className="d-none" 
                />
                )}
              />
            </AttachFileButton>
          </FlexBox>
          <Box className="mt-5">
            <Label size="14px" className="mb-3" weight={400} color="#2E2E2E">
              Admin name
            </Label>
            <Controller
              control={control}
              name="name"
              defaultValue={admin_user.name}
              render={({ field: { onChange, value } }) => (
                <Input
                  type="text"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  className="bg-transparent"
                  {...register("name")}
                />
              )}
            />
            {errors?.name ? <ValidationMessage error={errors?.name} /> : null}
          </Box>
          <Box className="mt-3">
            <Label size="14px" className="mb-3" weight={400} color="#2E2E2E">
              Admin email
            </Label>
            <Controller
              control={control}
              name="email"
              defaultValue={admin_user.email}
              render={({ field: { onChange, value } }) => (
                <Input
                  type="email"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  className="bg-transparent"
                  {...register("email")}
                />
              )}
            />
            {errors?.email ? <ValidationMessage error={errors?.email} /> : null}
          </Box>
          <Box className="mt-3">
            <Label size="14px" className="mb-3" weight={400} color="#2E2E2E">
              Admin Password
            </Label>
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <Input
                  type="password"
                  placeholder="*********"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  className="bg-transparent"
                  {...register("password")}
                />
              )}
            />
            {errors?.password ? (
              <ValidationMessage error={errors?.password} />
            ) : null}
          </Box>
          <Box className="text-end">
            <ThemeButton className="border-0 mt-4" disabled={loader}>
              {loader ? <SpinnrLoader color="#fff" /> : "Update profile"}
            </ThemeButton>
          </Box>
        </Box>
      </Form>
    </MdContainer>
  );
};
const MdContainer = styled.div`
  max-width: 600px;
`;
export default AdminProfile;
