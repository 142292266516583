import React from 'react'
import Nearby from '../pages/Nearby'
import Profile from '../pages/Profile'
import WishList from '../pages/WishList'
import Inbox from '../pages/Inbox'
import Upgrade from '../pages/Upgrade'
import EditProfile from '../pages/EditProfile'
import Dashboard from '../admin/Dashboard'
import Settings from '../admin/Settings'
import AdminProfile from '../admin/AdminProfile'
import UsersManagement from '../admin/UsersManagement'
import UsersMessages from '../admin/UsersMessages'
import ChatManagement from '../admin/ChatManagement'
import UserSettings from '../pages/UserSettings'
import Gallery from '../pages/Gallery'
import AdminChat from '../admin/AdminChat'
import terms from "../pages/Terms"
import Paymentsuccess from '../pages/Paymentsuccess';


const Home = React.lazy(() => import('../pages/Home'))
// const Discover = React.lazy(() => import('../pages/Discover'));
// const notFound = React.lazy(() => import('../views/errorPages/NotFound'))


const routes = [
    { path: '/home', exact:true, name:'home', component:Home, auth:true},
    { path: '/profile', exact:true, name:'profle', component:Profile, auth:true},
    { path: '/nearby', exact: true, name: 'nearby', component:Nearby, auth:true},
    { path: '/wishlist', exact: true, name: 'whishlist', component:WishList, auth:true},
    { path: '/inbox', exact: true, name: 'inbox', component:Inbox, auth:true  },
    { path: '/gallery',  exact: true, name: 'Gallery', component: Gallery },
    { path: '/upgrade', exact: true, name: 'upgrade', component:Upgrade, auth:true},
    { path: '/paymentsuccess', exact: true, name: 'paymentsuccess', component:Paymentsuccess, auth:true},
    { path: '/settings',  exact: true, name: 'Settings', component:UserSettings},
    // { path: '/discover', exact: true, name: 'upgrade', component: Discover,auth:true  },
    { path: '/profile/edit', exact: true, name: 'upgrade', component:EditProfile, auth:true},
    { path: '/profile/:id', exact: true, name: 'upgrade', component:Profile, auth:true},
    { path: '/admin/dashboard',  exact: true, name: 'Dashboard', component:Dashboard},
    { path: '/admin/settings',  exact: true, name: 'Website Settings', component:Settings},
    { path: '/admin/profile',  exact: true, name: 'Admin Profile', component:AdminProfile},
    { path: '/admin/users',  exact: true, name: 'Users Management', component:UsersManagement},
    { path: '/admin/user-messages',  exact: true, name: 'Users Messages', component:UsersMessages},
    { path: '/admin/chat',  exact: true, name: 'Chat Management', component:ChatManagement},
    { path: '/admin/chat/self',  exact: true, name: 'Chat Management', component:AdminChat},
    { path: '/admin/user/:id',  exact: true, name: 'User Profile', component:Profile},
    { path: '/terms-and-condition',  exact: true, name: 'terms', component:terms},
]


export default routes