import styled from "styled-components";
import * as yup from "yup";
import moment from "moment";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { FemalePicture, MalePicture } from "../components/AllImages";
import { IMAGE_URL } from "../constant/Keys";
import Select from 'react-select';
import { useState, useEffect } from "react";


const ErrorMessage = styled.small`
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  &::first-letter {
    text-transform: capitalize;
  }
`;
const ValidationMessage = ({ error, mt }) => {
  const styles = { marginBottom: "10px" };
  return (
    <>
      <ErrorMessage
        className={`text-danger d-block error__text ${mt ? "mt-1" : ""}`}
        style={styles}
      >
        {error.message.replace(/_/g, " ")}
      </ErrorMessage>
    </>
  );
};
function ReadFiles (images,setImagesStream) {
  let file = images;
  const reader = new FileReader();
  reader.onload = () => {
    const single = reader.result;
    setImagesStream(single);
  };
  reader.readAsDataURL(file);
}
const cityOptions = [
  { value: "miami", label: "Miami" },
  { value: "california", label: "California" },
];
const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    textAlign: "left",
    color: "#212529",
    padding:'0.5rem 0.5rem 0.5rem 0.5rem',
    marginBottom:'10rem',
    backgroundColor:'wheat',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const customSelectStylesCity = {
  menu: (provided, state) => ({
    ...provided,

    textAlign: "left",
    color: "#212529",
    padding:'0.5rem 0.5rem 0.5rem 0.5rem',
    // backgroundColor:'wheat',
    // marginBottom:'4rem', 
    position:'relative',

    
    "::-webkit-scrollbar": {
      width: "1px",
      height: "0px",
    },
  }),
  
  menuPortal: base => ({ ...base, zIndex: 9999 }) ,

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};



const customSelectStylesForMobile = {
  menu: (provided, state) => ({
    ...provided,
    textAlign: "left",
    color: "#212529",
    padding:'0.5rem',
    marginBottom:'4rem',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};


const InputControl = ({ control, initValue, name, reg }) => {
  return (
    <Controller
      control={control}
      defaultValue={initValue}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Form.Control
          value={value}
          onChange={(e) => onChange(e.target.value)}
          as="input"
          type="text"
          {...reg(name)}
        />
      )}
    />
  );
};


const RangeInputControl = ({ control, initValue, name, reg }) => {
  return (
    <Controller
      control={control}
      defaultValue={initValue}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          <Form.Range
            value={value}
            onChange={(e) => onChange(e.target.value)}
            as="input"
            type="range"
            {...reg(name)}
            // min={140}
            />
          <>
            {value}
          </>
        </>
      )}
    />
  );
};



const SelectList = ({ control, initValue, name, reg , options,errors}) => {
  return(
    <>
      <Controller   
        control={control}
        defaultValue={initValue}
        name={name}
        render={({ field: { onChange, value }}) => (
          <Select options={options} 
            placeholder="Select" style={{width:"100%"}}  {...reg(name)}   
            value={options.find(c => c.value === value)}
            onChange={val => onChange(val.value)} 
          />
        )}
      />
      {errors?.[name]?<ValidationMessage mt={2} error={errors?.[name]} />:null}
    </>
  )
}


const ValidationSchemaEdit = yup.object().shape({
  // email: yup.string().email().required(),
  // password: yup.string().required().min(6),
  // confirm_password: yup.string().required()
  // .oneOf([yup.ref('password'), null], 'Confirm password did not match'),
  username: yup.string().required().min(3).max(20),
  dob: yup
    .string()
    .required("Please select a date")
    .test("dob", "Your age is not between 18 to 100 years", (value) => {
      if (!value) return true;
      return (
        moment().diff(moment(value), "years", false) >= 18 &&
        moment().diff(moment(value), "years", false) <= 100
      );
    }),
  // gender: yup.string().required(),
  // city: yup.string().required().min(2).max(20),
  height: yup.number().nullable(),
  // weight: yup.string().required(),
  // looking_for: yup.string().nullable(),
  // purpose_relationship: yup.string().nullable(),
  // body_type:yup.string().nullable(),
  // occupation:yup.string().nullable(),
  // smoke:yup.string().nullable(),
  // alcohol:yup.string().nullable(),
  // about: yup.string().min(25),
  // relationship_status: yup.string(),
  // language:yup.array().required(),
  // education:yup.string().required(),
  // religion:yup.string().required(),
  // sign:yup.string().required(),
  // diet:yup.string().required(),
  // children:yup.string().required(),
  // pets:yup.string().required(),
});
const getDate = (date) => {
    const month = moment(date, "YYYY-MM-DD").format("MMMM"),
    day = moment(date, "YYYY-MM-DD").format("DD"),
    year = moment(date, "YYYY-MM-DD").format("YYYY");
  return `${month} ${day}, ${year}`;
};
const getAge = (date) => {
  const age = moment().diff(moment(date,"YYYY-MM-DD"), "years", false);
  return `${age}`;
};
export const rebuildData = (values, gallery) => {
  const formData = {};
  Object.keys(values).forEach((key) => {
    if (key == "profile" && gallery.length > 0) {
      formData["profile"] = [];
      gallery
        .filter((x) => x.file !== "")
        .map((item, key) => {
          formData["profile"][key] = item.file;
        });
    } else {
      formData[key] = values[key];
    }
  });
};

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}
const validateEmail = (email) => {
  return String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
const userAvatar = (data) => {
  return data?.image == null && data?.gender == "male"
  ? MalePicture
  : data?.image == null && data?.gender == "female"
  ? FemalePicture
  : IMAGE_URL+data?.image?.profile;
}




function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

export {
  ValidationMessage,
  cityOptions,
  customSelectStyles,
  customSelectStylesCity,
  customSelectStylesForMobile,
  ValidationSchemaEdit,
  InputControl,
  RangeInputControl,
  getDate,
  getAge,
  buildFormData,
  genderOptions,
  validateEmail,
  userAvatar,
  SelectList,
  ReadFiles,
  useWindowSize,
};
