import styled, { css } from 'styled-components'

const btn = (light, dark) => css`
  white-space: nowrap;
  display: inline-block;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 16px;
  color: white;
  &:visited {
    color: white;
  }
  background-image: linear-gradient(${light}, ${dark});
  border: 1px solid ${dark};
  &:hover {
    background-image: linear-gradient(${light}, ${dark});
    &[disabled] {
      background-image: linear-gradient(${light}, ${dark});
    }
  }
  &:visited {
    color: black;
  }
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

const btnDefault = css`
  ${btn('#ffffff', '#d5d5d5')} color: #555;
`
const btnPrimary = btn('#D39B2C', '#D39B2C')
const btnDanger = btn('#e27c79', '#c9302c')
const btnDanger1 = btn('#e27c79', '#000000')


export default styled.div`
    @media screen and (min-width: 500px) {
        background-color: rgba(137, 137, 137, 0.8);
        position:fixed;
        top:0;
        left:0;
    
        max-height:100vh;
        height:100%;
        width:100%;
        padding-top:0px;
        padding-bottom:20px;
        z-index:99;
        overflow-y: auto;

        form{
            background-color: white;
            padding:2rem;
            box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 27%);
            border-radius: 15px;
            border:1px solid rgb(0 0 0 / 24%);

            position: center;
            text-align: center;
            top:5%;
            margin-bottom:20px;
            margin: 2rem;
        }

        form, div.form {
            text-align: left;
            max-width: 500px;
            margin: 10px auto;
            position: center;
        
            & > div {
              display: flex;
              flex-flow: row nowrap;
              line-height: 2em;
              position: relative;
              padding: 8px 5px;
              border: 1px solid transparent;
              &.active {
                background-color: paleturquoise;
                border-color: turquoise;
              }
            & > label {
            color: #333;
            width: 110px;
            min-width: 60px;
            font-size: 1em;
            line-height: 32px;
            }
            & > input:not(.cheee__),
            & > .downshift > input,
            & > select,
            & > textarea {
            flex: 1;
            padding: 6px 9px;
            font-size: 1em;
            margin-left: 15px;
            border: 1px solid #ccc;
            color:#b5b5b5;
            &::placeholder{
                color:#b5b5b5;
            }
            background: transparent;
            border-radius: 50px;
            &[disabled] {
                background: #eee;
            }
            }
            & > input[type='checkbox'] {
            margin-top: 7px;
            }
            & > div {
            margin-left: 16px;
            & > label {
                margin-left: 0;
                display: block;
                & > input {
                margin-right: 3px;
                }
            }
            &.downshift {
                margin-left: 0;
                padding-left: 15px;
                flex: 1;
                & > input:not(.cheee__) {
                width: 100%;
                padding: 6px 5px;
                font-size: 1em;
                margin-left: 0;
                border: 1px solid #ccc;
                border-radius: 50px;
                }
            }
            }
            & > span {
            line-height: 32px;
            margin-left: 10px;
            color: #800;
            font-weight: bold;
            }
            & > button.remove {
            ${btnDanger};
            }
            }
            & > .buttons {
              display: flex;
              flex-flow: row nowrap;
              justify-content: center;
              margin-top: 15px;
            }
        
            .error {
              display: flex;
              font-weight: bold;
              color: #800;
              flex-flow: row nowrap;
              justify-content: center;
            }
            pre {
              position: relative;
              border: 1px solid #ccc;
              background: rgba(0, 0, 0, 0.1);
              box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
              padding: 20px;
            }
            .submitting {
              display: block;
              position: absolute;
              top: -5px;
              left: -5px;
              right: -5px;
              padding: 0;
              text-align: center;
              background: rgba(0, 0, 0, 0.4);
              color: white;
              z-index: 10;
              font-weight: bold;
              font-size: 0.8em;
            }
            .saving {
              font-size: 0.8em;
              font-weight: bold;
              color: darkblue;
              margin: 8px 0 0 7px;
            }
          }
    }


  h1 {
    text-align: center;
    color: #222;
  }

  h2 {
    text-align: center;
    color: #222;
  }

  & > div {
    text-align: center;
  }

  a {
    display: block;
    text-align: center;
    color: #222;
    margin-bottom: 10px;
  }

  p {
    max-width: 500px;
    margin: 10px auto;
    & > a {
      display: inline;
    }
  }

  .loading {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    margin: 50px;
  }

  
  
  button {
    margin: 0 10px;
    &[type='submit'] {
      ${btnPrimary};
    }
    &[type='button'] {
      ${btnDefault};
    }
  }
  .downshift-options {
    border: 1px solid #ddd;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    & > div {
      padding: 3px 5px;
    }
  }




















  @media screen and (max-width: 500px) {
    background-color: white;
    position:fixed;
    top:0;
    left:0;

    max-height:100vh;
    width:100%;
    height:100%;
    padding-top:0px;
    padding-bottom:20px;
    z-index:99;
    overflow-y: auto;
    

    form{
        height:100vh;
        background-color: white;
        padding:1rem;
        padding-top:2rem;

        position: center;
        padding:2rem;
    }

    form, div.form {
        max-width: 500px;
        position: center;
    
        & > div {
          display: flex;
          justify-content: center !important;
          align-items: center !important;

          flex-flow: row nowrap;
          line-height: 2em;
          position: relative;
          padding: 8px 5px;
          border: 1px solid transparent;
          &.active {
            background-color: paleturquoise;
            border-color: turquoise;
          }

        & > label {
            color: #333;
            width: 110px;
            min-width: 60px;
            font-size: 1em;
            line-height: 32px;
            
        }
        & > input:not(.cheee__),
        & > .downshift > input,
        & > select,
        & > textarea {
            background-color: red;
            
            display: flex;
            justify-content: center;
            align-items: center ;

            flex: 1;
            padding: 6px 9px;
            font-size: 1em;
            margin-left: 15px;
            border: 1px solid #ccc;
            color:#b5b5b5;
            &::placeholder{
                color:#b5b5b5;
        }
        background: transparent;
        border-radius: 50px;
        &[disabled] {
            background: #eee;
        }
        }
        
        & > input[type='checkbox'] {
            margin-top: 7px;
        }
        & > div {
            margin-left: 16px;
        & > label {
            margin-left: 0;
            display: block;
            & > input {
            margin-right: 3px;
            }
        }
        &.downshift {
            margin-left: 0;
            padding-left: 15px;
            flex: 1;
            & > input:not(.cheee__) {
            width: 100%;
            padding: 6px 5px;
            font-size: 1em;
            margin-left: 0;
            border: 1px solid #ccc;
            border-radius: 50px;
            }
        }
        }
        & > span {
        line-height: 32px;
        margin-left: 10px;
        color: #800;
        font-weight: bold;
        }
        & > button.remove {
            ${btnDanger1};
            }
        }
        & > .buttons {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          margin-top: 15px;
        }
    
        .error {
          display: flex;
          font-weight: bold;
          color: #800;
          flex-flow: row nowrap;
          justify-content: center;
        }
        pre {
          position: relative;
          border: 1px solid #ccc;
          background: rgba(0, 0, 0, 0.1);
          box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
          padding: 20px;
        }
        .submitting {
          display: block;
          position: absolute;
          top: -5px;
          left: -5px;
          right: -5px;
          padding: 0;
          text-align: center;
          background: rgba(0, 0, 0, 0.4);
          color: white;
          z-index: 10;
          font-weight: bold;
          font-size: 0.8em;
        }
        .saving {
          font-size: 0.8em;
          font-weight: bold;
          color: darkblue;
        }
      }
}

`