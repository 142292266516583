import { BASE_URL } from "../Keys";
import { JobView } from "../../redux/actions/ClientActions";
import API from "../../config/AxiosBase";
import { Axios } from "axios";


export const headers = () => {
  const token = localStorage.datingAppauthToken;
  if (token) {
    API.defaults.headers.common["Authorization"] = "Bearer " + token;
    return API;
  } else {
    return API.defaults.headers;
  }
};
export const GetAllUsers = async () => {
  headers();
  return API.get("/get/users");
}
export const deleteMyAccountApi = async (password="") => {
  headers();
  return API.post("/delete/me", {password});
}

export const GetAllLikes = async () => {
  headers();
  return API.get("/get/liked/user");
}
export const PostLikeUser = async (data) => {
  headers();
  return API.post("/like/user",data);
}
export const RemoveLike = async (data) => {
  headers();
  return API.post("/dislike/user",data);
}
export const updateProfile = async (data) => {
  // console.log('got data :', data.values()) // delete
  headers();
  return API.post("/edit-profile",data);
}
export const PostForgetPassword = async (data) => {
  headers();
  return API.post("/forget-password",data);
}
export const GetRandomUsers = async (data) => {
  headers();
  return API.get("/get/random/users");
}

export const GetAllConnections = async (id) => {
  headers();
  // console.log('get connection :', id)
  return id?API.get(`/get/connection/${id}`):API.get(`/get/connection`);
}


export const sendMessage = async (data) => {
  headers();
  return API.post("/send/message",data);
}


export const connectUser = async (data) => {
  headers();
  return API.post("/connect/user",data);
}
export const SavePackageAmount = async (data) => {
  headers();
  return API.post("/save-package-amount",data);
}
export const GetPackageAmount = async () => {
  headers();
  return API.get("/get-package-amount");
}
export const updateUserStatus = async (data) => {
  headers();
  return API.post("/update-user-status",data);
}
export const deleteUser = async (data) => {
  headers();
  return API.post("/delete-user",data);
}
export const addUser = async (data,url) => {
  headers();
  return API.post(url,data);
}
export const updateAdminProfile = async (data) => {
  headers();
  return API.post('/admin/update-profile',data);
}
export const getSingleUser = async (id) => {
  headers();
  return API.get(`/get/user_profile/${id}`);
}
export const PaymentApi = async (data) => {
  headers();
  return API.post('/payment',data);
}
export const UserStatus = async (data) => {
  headers();
  return API.post('/online-status',data);
}
export const deleteImage = async (data) => {
  headers();
  return API.post('/delete-image',data);
}
export const emailUpdate = async (data) => {
  headers();
  return API.post('/update-settings',data);
}
export const nameUpdate = async (data) => {
  headers();
  return API.post('/update-name',data);
}
export const updatePassword = async (data) => {
  headers();
  return API.post('/update-password',data);
}
export const getTransactions = async () => {
  headers();
  return API.post('/get-transactions');
}
export const cancelSubscription = async () => {
  headers();
  return API.get('/cancel-subscription');
}
export const seenMessages = async (data) => {
  headers();
  return API.post('/read-messages',data);
}

export const getPaginationUsers = (url) => {
  headers();
  return API.get(url);
}

export const deleteUserChat = (id) => {
  headers();
  return API.post('/delete-conversation',id)
}

export const getGallery = (id) => {
  headers();
  if(id){
    return API.get('/get-gallery/'+id)
  }
  else {
    return API.get('/get-gallery')
  }
}

export const uploadGalleryAPi = (payload) => {
  headers();
  return API.post('/upload-gallery',payload)
}

export const galleryDeleteApi = (id) => {
  headers();
  return API.post('/delete-gallery',id)
}

// keywords block Api

export const keywordsGet = () => {
  headers();
  return API.get('/get-keywords');
}

export const keywordsPost = (paylaod) => {
  headers();
  return API.post('/save-keywords',paylaod);
}

export const keywordsDelete = (id) => {
  headers();
  return API.get('/delete-keyword/'+id);
}

export const initializePayment = (id) => {
  headers();
  return API.get('/initialize-payment/'+id);
}

export const recurringPayment = (id, terminal, profile, op) => {
  headers();
  return API.get('/recurring-payment/'+id+'/'+terminal+'/'+profile+'/'+op);
}