import React,{useEffect,useState} from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Avatar } from "../AllImages";
import { FlexBox, Heading } from "../Style";
import { logout } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import routes from "../../routes/Routes";
import { ASSET_URL } from "../../constant/Keys";

const TopBar = () => {
  const { admin_user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const [page,setPage] = useState(null);
  useEffect(()=>{
    setPage(routes.find((x)=>x.path===pathname));
  },[pathname])
  return (
    <FlexBox className="w-100">
      <Heading
        color="#828282"
        size="26"
        className="mb-0 mx-0"
        weight="600"
        style={{ textShadow: "none" }}
      >
        {page?.name}
      </Heading>
      <Dropdown className="ms-auto dropdown-topbar">
        <Dropdown.Toggle bsPrefix="dropdown-custom shadow-none bg-transparent border-0 p-0">
          <img
            src={admin_user?.profile?ASSET_URL+admin_user?.profile:Avatar}
            className="rounded-circle"
            style={{width:"45px", height:"45px", objectFit:"cover"}}
            alt='img'
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item className="p-0">
            <Link className="text-decoration-none d-block px-3 py-1" to="/admin/profile">Profile</Link>
          </Dropdown.Item>
          <Dropdown.Item className="p-0">
            <Link className="text-decoration-none d-block px-3 py-1" onClick={()=>{toast.success('Logout Successfully');dispatch(logout())}}>Logout</Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </FlexBox>
  );
};

export default TopBar;
