import React from 'react'
import { useSelector } from 'react-redux';
import { Box } from '../components/Style'
import EnglishLanguage from '../constant/langauges/EnglishLanguage';
import HebrewLanguage from '../constant/langauges/HebrewLanguage';
import Inbox from '../pages/Inbox'

const AdminChat = () => {
 const { langauge } = useSelector((state)=>state.clients);
 const lang = langauge == "heb"? HebrewLanguage: EnglishLanguage
  return (
    <Box>
      <Inbox lang={ lang } />
    </Box>
  )
}

export default AdminChat