import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContainer } from '../components/Style';
import HebrewLanguage from '../constant/langauges/HebrewLanguage';
import EnglishLanguage from '../constant/langauges/EnglishLanguage';
import Footer from '../components/Ui/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LanguageDropdownSelector } from '../components/Components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import logo from '../assets/images/jojo.png';
import {DOMAIN_URL} from "../constant/Keys"; // Importing the logo image

const Optin = () => {
    const { langauge } = useSelector((state) => state.clients);
    const lang = langauge === 'heb' ? HebrewLanguage : EnglishLanguage;

    const [optOutSuccess, setOptOutSuccess] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you would handle the form submission logic, for now, just show success message
        setOptOutSuccess(true);
    };



    return (
        <React.Fragment>
            <div lang={langauge === 'heb' ? HebrewLanguage : EnglishLanguage}/>
            <div className="main-content">
                <ThemeContainer>
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-6 mt-5">
                                <div className="card px-5 py-5" id="form1" style={{height: 'auto'}}>
                                    <div className="text-center mb-4">
                                        <a href={DOMAIN_URL}><img src={logo} alt="Logo" style={{height: '83px', marginBottom: '20px'}}/></a>
                                    </div>
                                    <div className="form-data">
                                        <form onSubmit={handleSubmit}>
                                            <div className="forms-inputs mb-4 mt-4">
                                                <h3 style={{
                                                    textAlign: 'center',
                                                    fontWeight: '700',
                                                    marginBottom: '50px'
                                                }}>
                                                    Opt-in SMS
                                                </h3>
                                                <p style={{textAlign:"center",fontWeight:'500',marginBottom:'20px', width:"94%"}} >To
                                                    receive SMS messages from us, please enter your phone number
                                                    below:</p>


                                                <div className="input-group">
                                                    <IntlTelInput required style={{width: '100%'}}
                                                                  containerClassName="intl-tel-input"
                                                                  inputClassName="form-control"/>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <button style={{background: '#D39B2C', borderColor: "#D39B2C"}}
                                                        type="submit"
                                                        className="btn btn-primary w-100">
                                                    Opt-out SMS
                                                </button>
                                            </div>
                                        </form>
                                        {optOutSuccess && (
                                            <div className="alert alert-success" role="alert">
                                                Thank you for opting in to receive SMS notifications from us!
                                            </div>
                                        )}
                                        <p style={{ textAlign:'center',fontSize:'11px'}} id="additional-text">
                                            By entering your number on this website, you consent to receive occasional
                                            updates and promotional messages via SMS or phone call. Your privacy is
                                            important to us. We will not share your number with any third parties
                                            without your explicit permission, Msg&data rates may apply.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ThemeContainer>
            </div>

            <footer className=" text-center" style={{background: "none", color: "#D39B2C", marginTop: "1px"}}>
                {langauge === 'heb' ? (
                    <div style={{direction: "rtl"}}>
                        <Link style={{color: "#D39B2C", direction: "rtl"}} to="/terms-and-condition">תנאים והגבלות</Link>| 
                        <Link style={{color: "#D39B2C", direction: "rtl"}} to="/privacy-policy">מדיניות הפרטיות</Link>| 
                        <Link style={{color: "#D39B2C", direction: "rtl"}} to="/optin">הסכמה ל-SMS</Link>|
                        <Link style={{color: "#D39B2C", direction: "rtl"}} to="/optout">ביטול הסכמה ל-SMS</Link>
                    </div>
                ):(
                    <div style={{direction: "ltr"}}>
                        <Link style={{color: "#D39B2C"}} to="/terms-and-condition">{lang.terms_conditions}</Link> | 
                        <Link style={{color: "#D39B2C"}} to="/privacy-policy">{lang.privacy_policy}</Link> | 
                        <Link style={{color: "#D39B2C"}} to="/optin">{lang.Optin}</Link> | 
                        <Link style={{color: "#D39B2C"}} to="/optout">{lang.Optout}</Link>
                    </div>
                )}
            </footer>
        </React.Fragment>
    );
};

export default Optin;
