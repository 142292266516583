import React,{useState,useEffect} from "react";
import {
  Box,
  Heading,
  Input,
  Label,
  ThemeButton,
} from "../components/Style";
import styled from "styled-components";
import { SavePackageAmount,GetPackageAmount,keywordsGet,keywordsPost,keywordsDelete } from "../constant/api/Api";
import { toast } from "react-toastify";
import { TagsInput } from "react-tag-input-component";
import Badge from 'react-bootstrap/Badge';
import { SpinnrLoader } from "../components/Components";
const Settings = () => {
  const [loader,setLoader] = useState(false);
  const [filed,setField] = useState("");
  const [selected, setSelected] = useState([]);
  const [words, setWords] = useState([]);
  const styles = {
    width: "fit-content",
    maxWidth:" fit-content",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRadius:"6px",
    fontSize:"16px"
  }
  useEffect( async () => {
    setLoader(true);
    try{
      const res = await GetPackageAmount();
      if(res.data.status==200){
        setField(res.data?.data?.amount)
      }else{
        toast.error(res.data.message);
      }
    }
    catch(error){
      toast.error('משהו השתבש');
    }

    try {
      const res = await keywordsGet();
      if(res.data.status == 200){
        setWords(res.data?.data)
      }else{
        toast.error(res.data.message);
      }
    }
    catch(error){
      toast.error('משהו השתבש');
    }
    setLoader(false);
  },[])
  const submitData = async () => {
    if (!filed.replace(/\s/g, '').length) {
      // toast.error("Package price should not be empty");
      return;
    } 
    setLoader(true);
    try{
      const res = await SavePackageAmount({amount:filed});
      if(res.data.status==200){
        toast.success(res.data.message);
      }
      else{
        toast.error(res.data.message);
      }
    }
    catch(error){
      toast.error('משהו השתבש');
    }
    setLoader(false);
  }
  const postkeywordsTags = async () => {
    const formData = new FormData();
    console.log(selected)
    if(selected.length>0) {
      selected.map((x, i) => {
        formData.append(`key_words[${i}]`,x);
      })
    }else {
      toast.error('אנא הזן מילת מפתח אחת לפחות');
      return;
    }
    setLoader(true);
    try {
      const res = await keywordsPost(formData);
      setSelected([]);
      setWords(res.data?.data)
    }
    catch(err){

    }
    setLoader(false);
  }
  const deletekeywordsTags = async (id) => {
    setLoader(true);
    try {
      const res = await keywordsDelete(id);
      setSelected([]);
      setWords(res.data?.data)
    }
    catch(err) {

    }
    setLoader(false);
  }
  return (
    <MdContainer>
      <Box className="mt-4">
        {/* <Box className="mt-5">
          <Label size="14px" weight={600} color="#2E2E2E">
            Package Price
          </Label>
          <Input className="mt-2" value={filed} disabled={loader} onChange={(e)=>setField(e.target.value)} type="number" placeholder="$0" />
        </Box> */}
        {/* <Box className="text-end">
          <ThemeButton type="button" disabled={loader} className="border-0 mt-4 w-fit-content" onClick={submitData} style={styles}>Update settings</ThemeButton>
        </Box> */}
        <Box className='mt-5'>
          <Label className='mb-2' size="14px" weight={600} color="#2E2E2E">
            Block chat keywords
          </Label>
          <TagsInput
            value={selected}
            onChange={setSelected}
            name="keywords"
            placeHolder="Enter blocking keywords for chat"
          />
          <Box className="text-end">
            <ThemeButton type="button" disabled={loader} className="border-0 mt-4 w-fit-content" onClick={postkeywordsTags} style={styles}>Save keywords</ThemeButton>
          </Box>
        </Box>
        <Box className='mt-4 pt-2'>
          <Label className='mb-3' size="14px" weight={600} color="#2E2E2E">
            Blocked keywords {!loader || words.length>0 ?`(${words.length})`:null}
          </Label>
          {words && words.length>0 ? (
            <BlockedChartWordsBlock className='bg-white user-side-wrap mb-4'>
              <ul className='list-unstyled mb-0 d-flex flex-wrap mt-1'>
                {words.map((item,key) => (
                  <li className='mb-1 me-2' key={key}>
                    <Badge>
                      {item.keyword}<i className="ri-close-line" onClick={ () => deletekeywordsTags(item.id) }></i>
                    </Badge>
                  </li>
                ))}
              </ul>
            </BlockedChartWordsBlock>
            ):
            (
              loader?
                <Box>
                  <SpinnrLoader />
                </Box>
              :
              <Heading className='text-danger'>
                No Tags Found
              </Heading>
            )
          }
        </Box>
      </Box>
    </MdContainer>
  );
};
const MdContainer = styled.div`
  max-width: 500px;
  button.w-fit-content {
    height: 45px;
    font-size: 14px !important;
  }
  .rti--container {
    min-height: 45px;
    &,input {
      background: transparent;
      width: 100%;
    }
    input, & {
      &::placeholder{
        color: #000;
        color: #000;
        font-size: 14px;
      }
    }
    .badge {
      background: 
    }
  }
`;
const BlockedChartWordsBlock = styled.div`
  border: 0;
  box-shadow: 0px 12px 250px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  overflow: hidden;
  padding: 20px;
  .badge {
    background: #3a3a3a !important;
    background-color: #3a3a3a !important;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    i {
      font-size: 14px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

`
export default Settings;
