import React from "react"
import { useSelector } from "react-redux"
import Footer from "../components/Ui/Footer"
import Nav from "../components/Ui/Nav"
import EnglishLanguage from "../constant/langauges/EnglishLanguage"
import HebrewLanguage from "../constant/langauges/HebrewLanguage"
import { Content } from "./index"
import { Link } from 'react-router-dom'

const Layout = ({logout, socket, socketConnect}) => {
  const { langauge } = useSelector((state)=>state.clients);
  const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;
  return (
    <React.Fragment>
      {/* <div className="main-div"> */}
        <Nav lang={langauge==="heb"?HebrewLanguage:EnglishLanguage} />
          <div className="main-content">
            <Content logout={logout} socket={socket} socketConnect={socketConnect} lang={langauge==="heb"?HebrewLanguage:EnglishLanguage} />
          </div>
          <div className={"d-md-none d-block"} style={{bottom:"66px", height:"auto", zIndex:10, textAlign:"center"}}>
          <Link to={'/terms-and-condition'} style={{color:"#656565", textDecoration:"underline"}}>{lang.terms_conditions}</Link>
            &nbsp;|&nbsp;
          <Link to={'/privacy-policy'} style={{color:"#656565", textDecoration:"underline"}}>{lang.privacy_policy}</Link>
        </div>

        <Footer />
      {/* </div> */}
    </React.Fragment>
  )
}

export default Layout;
