import React,{useCallback,useState} from 'react'
import { arrayBufferToBlob, createBlob } from 'blob-util';
import { Row,Col,Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { Box, Label } from '../components/Style';
import { buildFormData, cityOptions, InputControl,RangeInputControl, jsonToFormData, ReadFiles, rebuildData, SelectList, userAvatar, ValidationMessage } from '../helpers/helper';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import { useSelector,useDispatch } from 'react-redux';
import { ValidationSchemaEdit } from '../helpers/helper';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { deleteImage, updateProfile } from '../constant/api/Api';
import { alcoholOptions, body_type_options, dietOptions, education, genderOptions, languages_options, looking_options, occupation_options, relation_options, relation_purpose_options, relegion_options, sign_options, smokeOptions } from '../helpers/SelectOptions';
import { abstract, cheers, FemalePicture, globe, Homepage, MalePicture, BlankPicture, man, PremiumAvatar, locationIcon, userIcon, CalendarIcon } from '../components/AllImages';
import { citiesOptions } from './auth/Register';
import { relationsOptions } from './auth/Register';
import { purposesOptions } from './auth/Register';
import { bodiesOptions } from './auth/Register';
import { hairColorsOptions } from './auth/Register';
import { eyeColorsOptions } from './auth/Register';

import { childrenOptions } from './auth/Register';
// import { petsOptions } from './auth/Register';
import { speaksOptions } from './auth/Register';
import { qualificationsOptions } from './auth/Register';
import { jobsOptions } from './auth/Register';
import { religiousesOptions } from './auth/Register';
import { starsOptions } from './auth/Register';
import { cigratesOptions } from './auth/Register';
import { drinksOptions } from './auth/Register';
import { hobbyOptions } from './auth/Register';
import { foodsOptions } from './auth/Register';
import { LOGIN_SUCCESS } from '../redux/constants/userConstant';
import { useHistory } from 'react-router';
import { ASSET_URL } from '../constant/Keys';
import Select from 'react-select';
import Toggle from 'react-toggle'
import './Upgrade.css'


export const EditProfile = ({lang}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [profile,setProfileImages] = useState([]);
    const [loader,setLoader] = useState(false);
    const [cityOptions,setCityOptions]=useState([]);
    const [relationOptions,setRelationOptions]=useState([]);
    const [purposeOptions,setpurposeOptions]=useState([]);
    const [bodyOptions,setbodyOptions]=useState([]);
    const [hairColorOptions,sethairColorOptions]=useState([]);
    const [eyeColorOptions,seteyeColorOptions]=useState([]);

    const [childOptions,setchildOptions]=useState([]);
    // const [petOptions,setpetOptions]=useState([]);
    const [speakOptions,setspeakOptions]=useState([]);
    const [qualificationOptions,setqualificationOptions]=useState([]);
    const [jobOptions,setjobOptions]=useState([]);
    const [religiousOptions,setreligiousOptions]=useState([]);
    const [starOptions,setstarOptions]=useState([]);
    const [cigratOptions,setcigratOptions]=useState([]);
    const [drinkOptions,setdrinkOptions]=useState([]);
    const [hobbiesOptions,sethobbiesOptions]=useState([]);

    

    const [foodOptions,setfoodOptions]=useState([]);
    const { register, handleSubmit, control, watch, getValues, formState: { errors } } =  useForm({
        resolver:yupResolver(ValidationSchemaEdit)
    });
    const {user} = useSelector((state)=>state.auth);
    const { langauge } = useSelector((state)=>state.clients);

    const user_height = user?.height > 0 ? user?.height : 140;
    const [newHeight, setNewHeight] = useState(user_height) ;
    const toggleState = user?.height > 0 ? true : false;
    const [toggleChecked,settoggleChecked]=useState(toggleState);




    
    const onSubmit =  async (data) => {
        setLoader(true);
        // console.log("Data :", data)

        const postData = data;
        let profile_data = [];
        profile.map((item,x)=>{
            profile_data.push({image:item.image,image_index:item.image_index});
        })

        postData['data'] = profile_data;
        const formData = new FormData();
        if(toggleChecked === true){
            // console.log("toggle check is true :", newHeight)
            data['height']=parseInt(newHeight)
            formData.append("height",newHeight);
        }else{
            data['height']= null;
            formData.append("height",newHeight);
        }
        buildFormData(formData, postData);

        for (let i = 0; i < postData.profile; i++) {
            formData.append(
                `data[${i}]`,
                postData.profile[i].image,
                postData.profile[i].filename
            );
        }

        // for (const value of formData.values()) {
        //     console.log(value);
        //   } // delete   

        try{
            const res = await updateProfile(formData);
            if(res.data.success){
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data.data,
                    token: localStorage.datingAppauthToken,
                });
                toast.success(res.data.message);
                history.push(`/profile/${user?.id}`)
            }else{
                toast.error(res.data.message);
            }
        }catch(error){
            toast.error('משהו השתבש');
        }
        setLoader(false);
    };
    useEffect(()=>{
        citiesOptions(lang,setCityOptions);
    },[langauge])

    useEffect(()=>{
        relationsOptions(lang,setRelationOptions);
    },[langauge])

    useEffect(()=>{
        purposesOptions(lang,setpurposeOptions);
    },[langauge])

    useEffect(()=>{
        bodiesOptions(lang,setbodyOptions);
    },[langauge])
    useEffect(()=>{
        hairColorsOptions(lang,sethairColorOptions);
    },[langauge])
    useEffect(()=>{
        eyeColorsOptions(lang,seteyeColorOptions);
    },[langauge])

    useEffect(()=>{
        childrenOptions(lang,setchildOptions);
    },[langauge])

    // useEffect(()=>{
    //     petsOptions(lang,setpetOptions);
    // },[langauge])

    useEffect(()=>{
        speaksOptions(lang,setspeakOptions);
    },[langauge])
    useEffect(()=>{
        qualificationsOptions(lang,setqualificationOptions);
    },[langauge])

    useEffect(()=>{
        jobsOptions(lang,setjobOptions);
    },[langauge])

    useEffect(()=>{
        religiousesOptions(lang,setreligiousOptions);
    },[langauge])

    useEffect(()=>{
        starsOptions(lang,setstarOptions);
    },[langauge])

    useEffect(()=>{
        cigratesOptions(lang,setcigratOptions);
    },[langauge])

    useEffect(()=>{
        drinksOptions(lang,setdrinkOptions);
    },[langauge])

    useEffect(()=>{
        hobbyOptions(lang,sethobbiesOptions);
    },[langauge])

    useEffect(()=>{
        foodsOptions(lang,setfoodOptions);
    },[langauge])


    let arraylanguages = [];
    speakOptions.map((item) => {
        arraylanguages.push( {
            label: item.label,
            value: item.value,
        })
    })

    const ProfileData = ({item, keyVal}) => {
        var pic = "";
        // console.log("====> ", item)

        if(item.url && !item.edit){
            pic = ASSET_URL+item.url;
        }
        else if(item.edit && item.image){
            pic = item.url;
        }
        else{ 
            // console.log("Key is :", keyVal)
            if(keyVal!==0){
                pic = BlankPicture
            }else{
                pic = user?.gender === "male"? MalePicture:FemalePicture  
            }
        }
        if(pic===""){
            return <img src="blank"/>
            // return <div className='col-4'></div>
        }
        return  <img src={pic}/>
    }


    useEffect(async()=>{
        console.log("user :", user)

        const new_array = [];
        if(user?.profile.length>0 && user?.profile){
            await [...Array(6)].map((i,x)=>{
                if(user?.profile[x]?.profile){
                    new_array.push({image:null,url:user?.profile[x]?.profile,image_index:x,edit:false,save_url:user?.profile[x]?.profile,id:user?.profile[x].id});
                }else{
                    new_array.push({image:null,url:"",image_index:x,edit:false,save_url:"",id:""});
                }
            })
            setProfileImages(new_array);
        }else{
            const new_array = [];
            await [...Array(6)].map((i,x)=>{
                new_array.push({image:null,url:"",image_index:x,edit:false,save_url:"",id:""});
            })
            setProfileImages(new_array);
        }
    },[user])

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (e) => resolve(URL.createObjectURL(arrayBufferToBlob(e.target.result, file.type)));
        reader.onerror = error => reject(error);
    });  

    const update_photos = async(file,key) => {
        let profile_items = [...profile]
        profile_items[key].url = await toBase64(file);
        profile_items[key].image = file;
        profile_items[key].edit = true;
        setProfileImages(profile_items)
    }

    const deleteProfileImage = async (key) => {
        let profile_items = [...profile];
        if(profile_items[key].edit){
            if(profile_items[key].save_url){
                let profile_items = [...profile];
                profile_items[key].url = profile_items[key].save_url;
                profile_items[key].image = null;
                profile_items[key].edit = false;
                setProfileImages(profile_items)
            }else{
                let profile_items = [...profile];
                profile_items[key].url = "";
                profile_items[key].image = null;
                profile_items[key].edit = false;
                profile_items[key].save_url = "";
                setProfileImages(profile_items)
            }
        }else if(profile_items[key].save_url){
            try{
                const res = await deleteImage({image_id:profile_items[key].id});
                if(res.data.success){
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: res.data.data,
                        token: localStorage.datingAppauthToken,
                    });  
                }
            }
            catch(error){

            }
        }
    }

    const handleHeightChange = (e) =>{
        setNewHeight(e.target.value)
    }

    const handleToggleChange = (e) =>{
        // console.log("========= ", e.target.checked)
        settoggleChecked(e.target.checked)
    }
    const defalutHeight = user?.height > 0 ? user?.height : 140;




    const colourStyles = {

        // control: styles => ({ 
        //     ...styles,
        //     backgroundColor: 'white',
        //     overflow: 'hidden',
        //     // height:'100px',
        //  }),

        menuList: styles => {
          return {
            ...styles,
            maxHeight:'8rem',
            width:"100%",
            position:'absolute',
            top:'0',
            paddingBotton:'2rem',
            backgroundColor:'white',
          };
        },

        valueContainer: styles=> {
            return {
              ...styles,
              maxHeight:'5rem',
              overflowY:'visible',
            //   flexDirection: 'column-reverse',
            // alignSelf: 'flex-end

            }
        }
      };



  return (
    <main>
        <Form className='update__form__' onSubmit={handleSubmit(onSubmit)}>
            <div className="container pb-5 pb-md-0">
                <div className="card">
                    <div className="card-body px-md-3 px-0">
                        <div className="card-header px-md-0 update__card__header border-bottom justify-content-between">
                            <div className="title text-capitalize flex-1 d-flex align-items-center">
                                <i onClick={()=>history.push('/home')} className={`${langauge==="heb"?"ri-arrow-right-s-line":"ri-arrow-left-s-line"} d-md-none`} style={{fontSize:"28px",marginBottom:".5rem"}}></i>&nbsp;<h2 className="mx-auto mx-md-0">{lang.edit_profile_edit}</h2>
                            </div>
                            <button disabled={loader} className="link bg-transparent border-0" type="submit" style={{fontWeight:"bold",marginBottom:".5rem"}}>
                                {lang.save}
                            </button>
                        </div>
                        <div className="row px-2 px-md-0">
                            <div className="col-md-4">
                                <div className="gallary">
                                    <div className="row align-items-stretch">
                                        {profile.map((item,key)=>{
                                            return(
                                                <>
                                                    {key!==1 && key!==2?
                                                        <div className={`${key===0?"col-8":"col-4"}`} key={key}>
                                                            <div className={`${key===0?"main-thumb":key===1 || key===2?"sub-thumb":"inner-thumb"} thumb p-0 profile-each-image-div`}>
                                                                                                                                
                                                                {<ProfileData item={item} keyVal={key}/>}
                                                                {true?

                                                                <div className="upload-label position-absolute">
                                                                    <label>
                                                                        <input type="file" className="d-none" accept="image/*" onChange={(e)=>update_photos(e.target.files[0],key)} />
                                                                        <i className="ri-add-circle-fill pointer"></i>
                                                                    </label>
                                                                </div>
                                                                :null}
                                                                {item.url?
                                                                    <button className="bg-transparent border-0 position-absolute" type="button" style={{top:"8px",left:"8px"}} onClick={()=>deleteProfileImage(key)}>
                                                                        <svg width="30" height="30" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="26" cy="26" r="26" fill="#959595" fillOpacity="0.2" stroke="#a5a5a5"/>
                                                                            <g clipPath="url(#clip0_26_312)">
                                                                            <path d="M25.9999 24.2325L32.1874 18.045L33.9549 19.8125L27.7674 26L33.9549 32.1875L32.1874 33.955L25.9999 27.7675L19.8124 33.955L18.0449 32.1875L24.2324 26L18.0449 19.8125L19.8124 18.045L25.9999 24.2325Z" fill="#959595"/>
                                                                            </g>
                                                                            <defs>
                                                                            <clipPath id="clip0_26_312">
                                                                            <rect width="30" height="30" fill="#959595" transform="translate(11 11)"/>
                                                                            </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                :null}
                                                            </div>
                                                        </div>
                                                    :null}

                                                    {key===1 ?
                                                    <div className={`col-4`} key={key}>
                                                        <div className={`sub-thumb thumb p-0 profile-each-image-div`}>
                                                            <ProfileData item={item} keyVal={key}/>
                                                            {/* <span className="badge">{key+1}</spainitialn> */}
                                                            {!item.save_url?
                                                            <div className="upload-label position-absolute">
                                                                <label>
                                                                    <input type="file" className="d-none" accept="image/*" onChange={(e)=>update_photos(e.target.files[0],key)} />
                                                                    <i className="ri-add-circle-fill pointer"></i>
                                                                </label>
                                                            </div>
                                                            :null}
                                                            {item.url?
                                                                <button className="bg-transparent border-0 position-absolute" type="button" style={{top:"8px",left:"8px"}} onClick={()=>deleteProfileImage(key)}>
                                                                    <svg width="30" height="30" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="26" cy="26" r="26" fill="#959595" fillOpacity="0.2" stroke="#a5a5a5"/>
                                                                        <g clipPath="url(#clip0_26_312)">
                                                                        <path d="M25.9999 24.2325L32.1874 18.045L33.9549 19.8125L27.7674 26L33.9549 32.1875L32.1874 33.955L25.9999 27.7675L19.8124 33.955L18.0449 32.1875L24.2324 26L18.0449 19.8125L19.8124 18.045L25.9999 24.2325Z" fill="#959595"/>
                                                                        </g>
                                                                        <defs>
                                                                        <clipPath id="clip0_26_312">
                                                                        <rect width="30" height="30" fill="#959595" transform="translate(11 11)"/>
                                                                        </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                            :null}
                                                        </div>
                                                        
                                                        <div className={`sub-thumb thumb p-0 profile-each-image-div`}>
                                                            <ProfileData item={profile[key+1]}  keyVal={key}/>
                                                            {/* <span className="badge">{key+2}</span> */}
                                                            {!profile[key+1].save_url?
                                                            <div className="upload-label position-absolute">
                                                                <label>
                                                                    <input type="file" className="d-none" accept="image/*" onChange={(e)=>update_photos(e.target.files[0],key+1)} />
                                                                    <i className="ri-add-circle-fill pointer"></i> 
                                                                    {/* working */}
                                                                </label>
                                                            </div>
                                                            :null}
                                                            {profile[key+1].url?
                                                                <button className="bg-transparent border-0 position-absolute" type="button" style={{top:"8px",left:"8px"}} onClick={()=>deleteProfileImage(key+1)}>
                                                                    <svg width="30" height="30" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="26" cy="26" r="26" fill="#959595" fillOpacity="0.2" stroke="#a5a5a5"/>
                                                                        <g clipPath="url(#clip0_26_312)">
                                                                        <path d="M25.9999 24.2325L32.1874 18.045L33.9549 19.8125L27.7674 26L33.9549 32.1875L32.1874 33.955L25.9999 27.7675L19.8124 33.955L18.0449 32.1875L24.2324 26L18.0449 19.8125L19.8124 18.045L25.9999 24.2325Z" fill="#959595"/>
                                                                        </g>
                                                                        <defs>
                                                                        <clipPath id="clip0_26_312">
                                                                        <rect width="30" height="30" fill="#959595" transform="translate(11 11)"/>
                                                                        </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                            :null}
                                                        </div>
                                                    </div>
                                                    :null}
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>






                            {/* about section */}
                            <div className="col-md-8 profile-widget edit__widget px-3 pe-4 pe-md-3 mx-1 mx-md-0">
                                <div className="flex-column-reverse flex-md-column d-flex">
                                    <div className="card-header w-100 px-0">
                                        <div className="title about__title w-100 pt-3">
                                            <h6 className="mb-0 table-row-title padding_bottom_div">{lang.about}</h6>
                                            <Box>
                                                <Controller   
                                                    control={control}
                                                    name="about"
                                                    defaultValue={user?.about}
                                                    render={({ field: { onChange, value }}) => (
                                                        <Form.Control {...register('about')} as="textarea" className={`w-100 border rounded px-2 py-2 textarea-div ${langauge==="heb"?"margin-left":"margin-right"}`}
                                                            value={value}
                                                            style={{resize:"none", minHeight:1, unicodeBidi:"plaintext"}}  type="text" 
                                                        />
                                                    )}
                                                />
                                                {errors?.about?<ValidationMessage mt={1} error={errors?.about} />:null}
                                            </Box>
                                            <Box className="d-none">
                                                <InputControl control={control} reg={register} initValue={user?.email} type="text" name="email"  />
                                            </Box>
                                        </div> 
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            {/* <table className="edit__profile__table"> */}
                                            <div className="full-row-div">
                                                <div className='col-sm-6 each-custom-input-div'>
                                                    <div className={`custom-input-div ${langauge==="heb"?"margin-left-top":"margin-right-top"}`}>
                                                        <div className='text-capitalize'><label className='table-row-title d-inline-block'><img src={userIcon} height="20px" /></label><b>{lang.username}</b></div>
                                                        {/* <td style={{display: "flex"}}> */}
                                                        <div className={`${langauge==="heb"?"text-right":"text-left"}`}>
                                                            <InputControl className="test" control={control} reg={register} initValue={user?.name} type="text"  name="username"  />
                                                        </div>
                                                    </div>
                                                        {errors?.username?
                                                            <div>
                                                                <td><ValidationMessage mt={1} error={errors?.username} /></td>
                                                            </div>
                                                        :null}
                                                </div>

                                                <div className='col-sm-6 each-custom-input-div'>
                                                    <div className='custom-input-div'>
                                                        <div className='text-capitalize'><label className='table-row-title d-inline-block'><img src={CalendarIcon} height="20px" /></label><b>{lang.date_birth}</b></div>
                                                        <div className={`${langauge==="heb"?"text-right":"text-left"}`}>
                                                            <Controller   
                                                                control={control}
                                                                name="dob"
                                                                defaultValue={user?.birth_date}
                                                                render={({ field: { onChange, value }}) => (
                                                                    <Form.Control as="input" {...register('dob')} className="date__field" value={value} max-length="4" maxLength="4" max="9999-12-31" 
                                                                        pattern="[0-9]{4}" type="date"
                                                                        onChange={e => onChange(e.target.value)} 
                                                                    />
                                                                )}
                                                            />    
                                                        </div>
                                                    </div>
                                                        {errors?.dob?
                                                            <div>
                                                                <td><ValidationMessage mt={1} error={errors?.dob} /></td>
                                                            </div>
                                                        :null}
                                                </div>
                                            </div>

                                            {/* <div className="card-header border-0 p-0 mt-md-0 mt-4 pt-1">
                                                <div className="mb-0 table-row-title spacing">
                                                    <h6 className="mb-0 table-row-title"><img src={locationIcon} height="25px" marginRight="5px" />{lang.location}</h6>
                                                </div>
                                            </div> */}
                                            {/* <table className='edit__profile__table'>
                                            </table> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <table className='edit__profile__table'>
                                            {/* <tr>
                                                <td className="text-capitalize"><b>{lang.gender}</b></td>
                                                <td>
                                                    <Box>
                                                        <Controller   
                                                            control={control}
                                                            defaultValue={user?.gender}
                                                            name="gender"
                                                            render={({ field: { onChange, value }}) => (
                                                                <select  {...register('gender')} value={value} onChange={e => onChange(e.target.value)}>
                                                                    <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="" disabled>{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                    {genderOptions.map((list,key)=>{
                                                                        return(
                                                                            <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={genderOptions[list.value]}>
                                                                                {list.label}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            )}
                                                        />
                                                    </Box>
                                                </td>
                                            </tr> */}
                                            <div className="table-row-cont">
                                                <td>
                                                    <h6 className="mb-0 table-row-title spacing"><img src={abstract} height="25px" />{lang.basic_profile}</h6>
                                                </td>
                                                {/* <td></td> */}
                                            </div>

                                            {/* location */}
                                            <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td className="border-0"><b>{lang.current_location}</b></td>
                                                <td className="text-capitalize border-0">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.city} 
                                                        name="city"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('city')} value={value} onChange={e => onChange(e.target.value)}>
                                                                {/* {langauge==="heb"?():()} */}
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="" >{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {cityOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                                {errors?.city?
                                                    <tr>
                                                        <td><ValidationMessage mt={1} error={errors?.city} /></td>
                                                    </tr>
                                                :null}
                                            </tr>

                                            <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td><b>{lang.rel_status}</b></td>
                                                <td>
                                                    <Box>
                                                        <Controller   
                                                            control={control}
                                                            defaultValue={user?.relationship_status}
                                                            name="relationship_status"
                                                            render={({ field: { onChange, value }}) => (
                                                                <select className='select_elememnt'  {...register('relationship_status')} value={value} onChange={e => onChange(e.target.value)}>
                                                                    <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="">{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                    {relationOptions.map((list,key)=>{
                                                                        return(
                                                                            <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                                {list.label}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            )}
                                                        />
                                                    </Box>
                                                </td>
                                            </tr>
                                                {errors?.relationship_status?
                                                    <tr>
                                                        <td><ValidationMessage mt={1} error={errors?.relationship_status} /></td>
                                                    </tr>
                                                :null}

                                            <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td><b>{lang.rel_purpose}</b></td>
                                                <td className="text-capitalize">
                                                    <Box>
                                                        <Controller   
                                                            control={control}
                                                            defaultValue={user?.purpose_relationship}
                                                            name="purpose_relationship"
                                                            render={({ field: { onChange, value }}) => (
                                                                <select  {...register('purpose_relationship')} value={value} onChange={e => onChange(e.target.value)}>
                                                                    <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="">{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                    {purposeOptions.map((list,key)=>{
                                                                        return(
                                                                            <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>{list.label}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            )}
                                                        />
                                                    </Box>
                                                </td>
                                            </tr>
                                            {errors?.purpose_relationship?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.purpose_relationship} /></td>
                                                </tr>
                                            :null}

                                            {/* children */}
                                            <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td><b>{lang.children}</b></td>
                                                <td className="text-capitalize">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.children || ""} 
                                                        name="children"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('children')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="" >{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {childOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                            {errors?.children?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.children} /></td>
                                                </tr>
                                            :null}


                                            {/* language */}
                                            <tr className={`lang-div ${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <div><b className={`text-capitalize ${langauge==="heb"?"":"padding-right"}`}>{lang.languages}</b></div>
                                                {/* <div className='custom-select-lang lang_select'> */}
                                                <div className={`lang_select ${langauge==="heb"?"text-left":"text-right"}`}>
                                                    {/*  */}
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.language || []}
                                                        name="language"
                                                        render={({ field: { onChange, value }}) => (
                                                            <Select 
                                                                placeholder={langauge==="heb"?"בחר/י":"Select"}
                                                                className={`custom-select-each ${langauge==="heb"?"text-right-imp":"text-left-imp"}`}
                                                                defaultValue={JSON.parse(user?.language)}
                                                                isMulti
                                                                options={arraylanguages}
                                                                onChange={val => onChange(val)}/> )} />
                                                </div>
                                            </tr>
                                                {errors?.language?
                                                    <tr>
                                                        <td><ValidationMessage mt={1} error={errors?.language} /></td>
                                                    </tr>
                                                :null}



                                        {/* education */}
                                        <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                            <td><b className="text-capitalize">{lang.education}</b></td>
                                            <td>
                                                <Controller   
                                                    control={control}
                                                    defaultValue={user?.education || ""}
                                                    name="education"
                                                    render={({ field: { onChange, value }}) => (
                                                        <select  {...register('education')} value={value} onChange={e => onChange(e.target.value)}>
                                                            <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="">{langauge==="heb"?"בחר/י":"Select"}</option>
                                                            {qualificationOptions.map((list,key)=>{
                                                                return(
                                                                    <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                        {list.label}
                                                                    </option>
                                                                )
                                                            })}
                                                        </select>
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                            {errors?.education?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.education} /></td>
                                                </tr>
                                            :null}


                                        {/* occupation */}
                                        <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                        
                                                <td><b className="text-capitalize">{lang.occupation}</b></td>
                                                <td>
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.occupation || ""}
                                                        name="occupation"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select className='custom-select'  {...register('occupation')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="">{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {jobOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                            {errors?.occupation?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.occupation} /></td>
                                                </tr>
                                            :null}

                                            {/* religion */}
                                            <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td><b className="text-capitalize">{lang.religion}</b></td>
                                                <td>
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.religion || ""}
                                                        name="religion"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select className='custom-select'  {...register('religion')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="">{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {religiousOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                            {errors?.religion?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.religion} /></td>
                                                </tr>
                                            :null}


                                        {/* sign */}
                                        <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td><b className="text-capitalize">{lang.sign}</b></td>
                                                <td>
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.sign || ""}
                                                        name="sign"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('sign')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="">{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {starOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                            {errors?.sign?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.sign} /></td>
                                                </tr>
                                            :null}

                                            {/* <div className="table-row-cont">
                                                <td>
                                                    <h6 className="mb-0 table-row-title"><img src={globe} height="20px" />{lang.background}</h6>
                                                </td>
                                            </div> */}
                                        </table>
                                    </div>




                                    {/* <div className="col-sm-6 mt-md-minus-60"> */}
                                    <div className="col-sm-6">
                                        <table>
                                        <div className="table-row-cont">
                                                <td>
                                                    <h6 className="mb-0 table-row-title spacing"><img src={man} height="23px" />{lang.looks}</h6>
                                                </td>
                                                {/* <td></td> */}
                                            </div>
                                            {errors?.body_type?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.body_type} /></td>
                                                </tr>
                                            :null}

                                            
                                            {/* <div className="table-row-cont">
                                                <td>
                                                    <h6 className="mb-0 table-row-title spacing"><img src={Homepage} height="18px" />{lang.family}</h6>
                                                </td>
                                            </div> */}
                                            <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <div className={`text-capitalize horizontally-center-div ${langauge==="heb"?"padding-left":"padding-right"}`}><b>{lang.height}</b>
                                                            <Toggle
                                                                defaultChecked={user?.height > 0 ? true : false}
                                                                icons={false}
                                                                onChange={handleToggleChange} />
                                                </div>
                                                <td>
                                                    <Box style={{display: "flex"}}>
                                                        {/* <RangeInputControl control={control} className="px-1" reg={register} initValue={user?user.height:140} type="range" pattern="[140-210]*"  name="height" /> */}  
                                                        {toggleChecked && (
                                                            // <div className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                            <>
                                                                <input type="range" className="form-range form-danger custom-range-color" defaultValue={defalutHeight}  min="140" max="210" onChange={handleHeightChange}/>
                                                                <div className='range-text'>{newHeight}</div>
                                                                <label className="label-measure">{lang.cm}</label>
                                                            </>
                                                            // </div>
                                                        )}
                                                        {!toggleChecked && (
                                                            <label className="label-measure bg-red">{lang.change_height_hint}</label>
                                                        )}
                                                    </Box>
                                                </td>
                                            </tr>
                                            {errors?.height?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.height} /></td>
                                                </tr>
                                            :null}
                                        
                                        <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td><b>{lang.body_type}</b></td>
                                                <td className="text-capitalize">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.body_type}
                                                        name="body_type"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('body_type')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="">{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {bodyOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>

                                            {/* hair color */}
                                            <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td><b>{lang.hair_color}</b></td>
                                                <td className="text-capitalize">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.hair_color}
                                                        name="hair_color"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('hair_color')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="">{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {hairColorOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>

                                            {/* eye color */}
                                        <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                            
                                                <td><b>{lang.eye_color}</b></td>
                                                <td className="text-capitalize options_left">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.eye_color}
                                                        name="eye_color"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select {...register('eye_color')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="">{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {eyeColorOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>

                                            {/* looking for */}
                                            {/* <tr>
                                                <td><b>{lang.looking_for}</b></td>
                                                <td>
                                                    <Box>
                                                        <Controller   
                                                            control={control}
                                                            defaultValue={user?.looking_for}
                                                            name="looking_for"
                                                            render={({ field: { onChange, value }}) => (
                                                                <select  {...register('looking_for')} value={value} onChange={e => onChange(e.target.value)}>
                                                                    <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="" disabled>{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                    {looking_options.map((list,key)=>{
                                                                        return(
                                                                            <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={looking_options[list.value]}>
                                                                                {list.label}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            )}
                                                        />
                                                    </Box>
                                                </td>
                                            </tr>
                                            {errors?.looking_for?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.looking_for} /></td>
                                                </tr>
                                            :null} */}
                                    </table>

                                            {/* pets */}
                                             {/* <tr>
                                                <td><b>{lang.pets}</b></td>
                                                <td className="text-capitalize">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.pets || ""} 
                                                        name="pets"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('pets')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="" >{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {petOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={petOptions[list.value]}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                            {errors?.pets?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.pets} /></td>
                                                </tr>
                                            :null} */}
                                        
                                            {/* <tr>
                                                <td><b className="text-capitalize">{lang.do_you_smoke}</b></td>
                                                <td className="text-capitalize">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.smoke}
                                                        name="smoke"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('smoke')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="" >{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {cigratOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={cigratOptions[list.value]}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                            {errors?.smoke?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.smoke} /></td>
                                                </tr>
                                            :null} */}


                                        <table className='edit__profile__table'>
                                            <div className="table-row-cont">
                                                <td>
                                                    <h6 className="mb-0 table-row-title spacing"><img src={cheers} height="25px" />{lang.life_style}</h6>
                                                </td>
                                            </div>











                                        
                                          {/* hobbies */}
                                          <tr className={`lang-div ${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <div><b className={`text-capitalize ${langauge==="heb"?"":"padding-right"}`}>{lang.hobbies}</b></div>
                                                {/* <div className='custom-select-lang lang_select'> */}
                                                <div className={`lang_select ${langauge==="heb"?"text-left":"text-right"}`}>
                                                    {/*  */}
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.hobbies || []}
                                                        name="hobbies"
                                                        render={({ field: { onChange, value }}) => (
                                                            <Select 
                                                                placeholder={langauge==="heb"?"בחר/י":"Select"}
                                                                className={`custom-select-each ${langauge==="heb"?"text-right-imp":"text-left-imp"}`}
                                                                defaultValue={JSON.parse(user?.hobbies)}
                                                                isMulti
                                                                options={hobbiesOptions}
                                                                onChange={val => onChange(val)}
                                                                styles={colourStyles}
                                                                />
                                                                )} />
                                                </div>

                                            </tr>
                                                {errors?.hobbies?
                                                    <tr>
                                                        <td><ValidationMessage mt={1} error={errors?.hobbies} /></td>
                                                    </tr>
                                                :null}











                                            {/* alcohol */}
                                        <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td className="text-capitalize"><b>{lang.drink}</b></td>
                                                <td className="text-capitalize">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.alcohol} 
                                                        name="alcohol"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('alcohol')} value={value} onChange={e => onChange(e.target.value)}>
                                                                {/* בחר/י */}
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="">{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {drinkOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                                {errors?.alcohol?
                                                    <tr>
                                                        <td><ValidationMessage mt={1} error={errors?.alcohol} /></td>
                                                    </tr>
                                                :null}



                                            {/* smoke */}
                                            <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td><b className="text-capitalize">{lang.smoke}</b></td>
                                                <td className="text-capitalize">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.smoke}
                                                        name="smoke"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('smoke')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="" >{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {cigratOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                                {errors?.smoke?
                                                    <tr>
                                                        <td><ValidationMessage mt={1} error={errors?.smoke} /></td>
                                                    </tr>
                                                :null} 














                                            {/* hobbies */}
                                            {/* <tr className={`${langauge==="heb"?"margin-left":"margin-right"}`}>
                                                <td className="text-capitalize"><b>{lang.hobbies}</b></td>
                                                <td className="text-capitalize">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.hobbies} 
                                                        name="hobbies"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('hobbies')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="" >{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {hobbiesOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                                {errors?.hobbies?
                                                    <tr>
                                                        <td><ValidationMessage mt={1} error={errors?.hobbies} /></td>
                                                    </tr>
                                                :null} */}



                                          

                                            {/* diet */}
                                            {/* <tr>
                                                <td><b>{lang.diet}</b></td>
                                                <td className="text-capitalize">
                                                    <Controller   
                                                        control={control}
                                                        defaultValue={user?.diet || ""} 
                                                        name="diet"
                                                        render={({ field: { onChange, value }}) => (
                                                            <select  {...register('diet')} value={value} onChange={e => onChange(e.target.value)}>
                                                                <option className={`${langauge==="heb"?"options_right":"options_left"}`} value="" >{langauge==="heb"?"בחר/י":"Select"}</option>
                                                                {foodOptions.map((list,key)=>{
                                                                    return(
                                                                        <option className={`${langauge==="heb"?"options_right":"options_left"}`} key={key} value={foodOptions[list.value]}>
                                                                            {list.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                            {errors?.diet?
                                                <tr>
                                                    <td><ValidationMessage mt={1} error={errors?.diet} /></td>
                                                </tr>
                                            :null} */}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    </main>
  )
}


const DropStyled = styled.div`
  border:3px dashed #000;
  padding:20px;
  cursor:pointer;
  height:150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius:.25rem;
`;
const CicrleAvatar = styled.label`
  width:100px;
  height:100px;
  box-shadow:0px 0px 12px 0px #e1e1e1;
  span,img{
    height:100%;
    width:100%;
  }
  img{
    object-fit: cover;
    width:100%;
  }
`;
export default EditProfile;