import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { ThemeContainer, ThemeCard, ThemeButton, Box } from '../components/Style'
import { recurringPayment } from '../constant/api/Api'
import { userAvatar } from '../helpers/helper'
import { useHistory } from 'react-router'
import { useWindowSize } from '../helpers/helper';
import { DOMAIN_URL } from "../constant/Keys"

const Paymentsuccess = ({lang}) => {
    const {user} = useSelector((state)=>state.auth);
    const [field, setField] = useState("");
    const [showPayment,setPaymentFormShow] = useState(false);
    const { langauge } = useSelector((state)=>state.clients);
    const history = useHistory();
    const {width} = useWindowSize();

    useEffect(async () => {
        try {
            const queryParameters = new URLSearchParams(window.location.search)
            const terminalnumber = queryParameters.get("terminalnumber");
            const lowprofilecode = queryParameters.get("lowprofilecode");
            const operation = queryParameters.get("Operation");
            const res = await recurringPayment(user.id, terminalnumber, lowprofilecode, operation);
            if(res.status === 200) {
                window.parent.location.href = '/upgrade/';
            }else {
                toast.error(res.data.message);
            }
        }
        catch(error){
            toast.error('משהו השתבש');
        }
    },[])

    return (
        <Wrapper>
            <ThemeContainer>
                <ThemeCard>
                    <div className="body_">
                        test
                    </div>
                </ThemeCard>
            </ThemeContainer>
        </Wrapper>
    )
}



const Wrapper = styled.div`
  
    .image-card {
        box-shadow: 0px 12px 250px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        margin-bottom: 20px;
        max-width: 350px;
        width: 100%;
        img {
            border-radius: 24px 24px 0px 0px;
            object-fit: cover;
            width: 100%;
            height: 450px;
        }
        .info {
            padding: 25px 20px;
            h2 {
                font-weight: 700;
                font-size: 23px;
                margin-bottom: 5px;
            }
            h6 {
                font-weight: 600;
                font-size: 16px;
                color: #9A9A9A;
            }
        }
    }
    .title-small{
        // background-color:black;
        font-size:1rem;
        font-weight: 700;
        text-align:center;
        width:90%;
        padding-right:1rem;
        padding-left:1rem;
    }
    .title-big{
        text-align:center;
        width:90%;
        padding-right:1rem;
        padding-left:1rem;
    }
    .arrow-left-margin{
        position: absolute;
        top:0;
        left:0;
        padding-right:2rem;
        padding:1.5rem;
        width:10%:

    }
    .arrow-right-margin{
        position: absolute;
        top:0;
        right:0;
        padding-left:2rem;
        padding:1.5rem;
        width:10%:
    }

    .features-upgrade-div{
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items:flex-start;
    }
    .features-upgrade-div-top{
        padding-bottom:4rem;
    }



    .features-upgrade-div-bottom{
        // background-color:red;
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items:flex-start;
    }


    .features {
        display: flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items: center;
        padding:0.5rem;

        margin-bottom: 20px;
        @media(max-width:767px){
            margin-bottom: 30px !important;
        }
        .icon {
            min-width: 60px;
            width: 60px;
            height: 60px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
            border-radius: 50%;
        }
        h5 {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 2px;
        }
        p {
            font-weight: 400;
            font-size: 15px;
            color: #9A9A9A;
        }
    }
    .bottom-text {
        max-width: auto;
        font-size:1rem;
    }



    @media (max-width: 800px) {
        .flex-800-column {
            flex-direction: column;
        }
        .image-card {
            margin-right: 0px;
            margin-bottom: 20px;
            max-width: 100%;
        }
        .bottom-text {
            max-width: 400px;
            font-size:0.8rem;
        }

        .features-upgrade-div-top{
            padding-bottom:2rem;
        }
    }
`;


export default Paymentsuccess