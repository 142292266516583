import AxiosBase from "../../config/AxiosBase";
import {
  LOAD_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_USER_FAIL,
  LOGOUT_USER_SUCCESS,
  REGISTER_USER_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
} from "../constants/userConstant";
import { headers } from "../../constant/api/Api"; 
import {  toast } from 'react-toastify';
import history from "../../helpers/history";
// import { socket } from "../../App";


export const SignUp = (formData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const response = await AxiosBase.post(`/register`, {
      ...formData,
    });
    if(response.data.success){
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: formData,
      });
      toast.success(response?.data.message);
      setTimeout(()=>{
        history.push("/login");
      },2000)
    }else{
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: response?.data.message,
      });
      if(response?.data.message === "The email has already been taken."){
        toast.error('האימייל כבר קיים במערכת!');
      }
    }
  } catch (error) {
    toast.error('הכניסה נכשלה!');
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.response?.data,
    });
  }
};


// Login action
export const LogIn_Request = (formData, path) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const response = await AxiosBase.post(path, {
      ...formData,
    });
    if(response.data.success){
      const token = response.data.data.token;
      const user = response.data.data;
      localStorage.setItem("datingAppauthToken",token);
      headers();
      toast.success(response?.data.message);
      setTimeout(()=>{
        if(user.is_admin!==1){
          dispatch({
            type: LOGIN_SUCCESS,
            payload: user,
            token: token,
          });
          history.push("/home");
        }else{
          dispatch({
            type: LOGIN_SUCCESS,
            admin:user,
            token: token,
          });
          history.push("/admin/dashboard");
        }
      },2000)
    }else{
      toast.error(response.data.message);
      dispatch({
        type: LOGIN_FAIL,
        payload: response?.data,
      });
    }
  } catch (error) {
    const {response} = error;
    if(response){
      dispatch({
        type: LOGIN_FAIL,
        payload: error.response?.data,
      });
      toast.error('!הכניסה נכשלה');
    }
  }
};


// Load user
export const loadUser = () => async (dispatch) => {
  const token = localStorage.datingAppauthToken;
  try {
    dispatch({ type: LOAD_USER_REQUEST });

    const response = await AxiosBase.get(`/auth/me`, {
      headers: {
        "x-access-token": token || "",
      },
    });

    dispatch({
      type: LOAD_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_USER_FAIL,
      // payload: error.response.data.message,
    });
  }
};


// Logout user
export const logout = () => async (dispatch) => {
  const token = localStorage.datingAppauthToken;

  try {
    if (token) {
      localStorage.removeItem("datingAppauthToken");
    }
    dispatch({
      type: LOGOUT_USER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LOGOUT_USER_FAIL,
    });
    
  }
};
