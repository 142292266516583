import React,{useEffect,useState} from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { like, view, unlimited } from '../components/AllImages'
import PaymentCard from '../components/payment/PaymentCard'
import { ThemeContainer, ThemeCard, ThemeButton, Box } from '../components/Style'
import { GetPackageAmount } from '../constant/api/Api'
import { userAvatar } from '../helpers/helper'
import { useHistory } from 'react-router'
import {useWindowSize} from '../helpers/helper';




const Upgrade = ({lang}) => {
    const {user} = useSelector((state)=>state.auth);
    const [field, setField] = useState("");
    const [showPayment,setPaymentFormShow] = useState(false);
    const { langauge } = useSelector((state)=>state.clients);
    const history = useHistory();
    const {width} = useWindowSize()

    const showPaymentFormOrRedirect = () => {
        const serviceApp = window.localStorage.getItem("service_app");
        console.log("User:", user)
        if(serviceApp && serviceApp !== 'browser'){
            if(serviceApp==='apple'){
                window.location.href = `/apple-pay?userId=${user.id}&amount=299&currency=ILS`
            }else if (serviceApp==='android'){
                window.location.href = `/google-pay?userId=${user.id}&amount=299&currency=ILS`;
            }
        }else{
            setPaymentFormShow(true)
        }
    }

    useEffect(async () => {
        try {
            const res = await GetPackageAmount();
            if(res.data.status===200) {
            }else {
                setField(res.data?.data?.amount)
                toast.error(res.data.message);
            } 
        }
        catch(error){
          toast.error('משהו השתבש');
        }
    },[])

    useEffect(() => {
        if(user.paid===1 || user.gender==="female"){
            document.querySelector('body').classList.remove('overflow-hidden');
            history.push('/settings');
        }
    },[user])

    useEffect(()=>{
        if(showPayment){
            document.querySelector('body').classList.add('overflow-hidden');
        }else{
            document.querySelector('body').classList.remove('overflow-hidden');
        }
    },[showPayment])


    return (
        <Wrapper>
            <ThemeContainer>
                <ThemeCard>
                    <div className="d-md-none mobile-up-header mb-4 d-flex justify-content-center">
                        <i onClick={()=>history.goBack()} className={`ri-arrow-${langauge==="heb"?"right":"left"}-s-line arrow-${langauge==="heb"?"right":"left"}-margin`}></i>
                        {width>500 ? (<h1 className='mb-0 title-big'>{(user.free_trial===0)?lang.vip_upgrade:lang.premium_upgrade_message}</h1>):(<h1 className='mb-0 title-small'>{(user.free_trial===0)?lang.vip_upgrade_mobile:lang.premium_upgrade_message_mobile}</h1>)}
                        {/* <button className="border-0 bg-transparent align-self-start" onClick={()=>history.goBack()}>{lang.skip}</button> */}
                    </div>
                    <div className='d-md-none d-flex justify-content-center avatar-layer mb-4 pb-2'>
                        <img src={userAvatar(user)} alt="" />
                    </div>
                    <div className="head_ d-md-flex d-none">
                        <h1>{lang.vip_upgrade}</h1>
                        {/* <button className="border-0 bg-transparent trans-btn">Skip</button> */}
                    </div>
                    <div className="body_">
                        <div className="d-flex aign-items-start flex-800-column">
                            <div className="image-card bg-white d-md-block d-none">
                                <img src={userAvatar(user)} alt="" />
                                <div className="info center text-center">
                                    <div>
                                        <h2 className="text-black">{user?.name}</h2>
                                        <h6 className="m-0 text-capitalize">{lang.cities[user?.city]}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-1 features-upgrade-div">
                                <div className='features-upgrade-div-top'>
                                    <div className="features">
                                        <div className="icon bg-white center">
                                            <img src={like} alt="icon" />
                                        </div>
                                        <div>
                                            <h5 className="text-black">{lang.up_liked}</h5>
                                            {/* <p className="m-0">{lang.up_ph}</p> */}
                                        </div>
                                    </div>
                                    <div className="features">
                                        <div className="icon bg-white center">
                                            <img src={view} alt="icon" />
                                        </div>
                                        <div>
                                            <h5 className="text-black">{lang.up_visited}</h5>
                                        </div>
                                    </div>
                                    <div className="features">
                                        <div className="icon bg-white center">
                                            <img src={unlimited} alt="icon" />
                                        </div>
                                        <div>
                                            <h5 className="text-black">{lang.up_ur}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className='features-upgrade-div-bottom'>
                                    <ThemeButton onClick={showPaymentFormOrRedirect} className="started-btn">
                                        {user.free_trial===0?lang.get_started:lang.premium_upgrade}
                                    </ThemeButton>
                                    <p className="text-black mt-4 pt-2 bottom-text">{user.free_trial===0?lang.update_package:lang.update_premium_package}<br/>{lang.update_package_discreet}</p>
                                    
                                    {/* <p className="text-black mt-4 pt-2 bottom-text">{lang.update_package} {field?"$"+field:""}<br/>{lang.update_package_discreet}</p> */}
                                        
                                        {/* <p className="text-black bottom-text">{lang.update_package_discreet}</p> */}
                                        {/* <p className="text-grey mt-3 pt-2 bottom-text">{lang.update_package_discreet}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </ThemeCard>
            </ThemeContainer>
            {showPayment?<Box><PaymentCard amount={field} setPaymentFormShow={setPaymentFormShow}/></Box>:null}
        </Wrapper>
    )
}



const Wrapper = styled.div`
  
    .image-card {
        box-shadow: 0px 12px 250px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        margin-bottom: 20px;
        max-width: 350px;
        width: 100%;
        img {
            border-radius: 24px 24px 0px 0px;
            object-fit: cover;
            width: 100%;
            height: 450px;
        }
        .info {
            padding: 25px 20px;
            h2 {
                font-weight: 700;
                font-size: 23px;
                margin-bottom: 5px;
            }
            h6 {
                font-weight: 600;
                font-size: 16px;
                color: #9A9A9A;
            }
        }
    }
    .title-small{
        // background-color:black;
        font-size:1rem;
        font-weight: 700;
        text-align:center;
        width:90%;
        padding-right:1rem;
        padding-left:1rem;
    }
    .title-big{
        text-align:center;
        width:90%;
        padding-right:1rem;
        padding-left:1rem;
    }
    .arrow-left-margin{
        position: absolute;
        top:0;
        left:0;
        padding-right:2rem;
        padding:1.5rem;
        width:10%:

    }
    .arrow-right-margin{
        position: absolute;
        top:0;
        right:0;
        padding-left:2rem;
        padding:1.5rem;
        width:10%:
    }

    .features-upgrade-div{
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items:flex-start;
    }
    .features-upgrade-div-top{
        padding-bottom:4rem;
    }



    .features-upgrade-div-bottom{
        // background-color:red;
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items:flex-start;
    }


    .features {
        display: flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items: center;
        padding:0.5rem;

        margin-bottom: 20px;
        @media(max-width:767px){
            margin-bottom: 30px !important;
        }
        .icon {
            min-width: 60px;
            width: 60px;
            height: 60px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
            border-radius: 50%;
        }
        h5 {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 2px;
        }
        p {
            font-weight: 400;
            font-size: 15px;
            color: #9A9A9A;
        }
    }
    .bottom-text {
        max-width: auto;
        font-size:1rem;
    }



    @media (max-width: 800px) {
        .flex-800-column {
            flex-direction: column;
        }
        .image-card {
            margin-right: 0px;
            margin-bottom: 20px;
            max-width: 100%;
        }
        .bottom-text {
            max-width: 400px;
            font-size:0.8rem;
        }

        .features-upgrade-div-top{
            padding-bottom:2rem;
        }
    }
`;


export default Upgrade