import React,{useState} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom"; // useHistory
import { useSelector, useDispatch } from "react-redux";
import { LogIn_Request } from "../../redux/actions/authActions";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ValidationMessage, validateEmail } from "../../helpers/helper";
import 'react-toastify/dist/ReactToastify.css';
import {ForgetPasswordModel, LanguageDropdownSelector, LogoSite} from '../../components/Components'
import {PostForgetPassword} from '../../constant/api/Api';
import { toast } from 'react-toastify';
import { Box } from "../../components/Style";
import HebrewLanguage from "../../constant/langauges/HebrewLanguage";
import EnglishLanguage from "../../constant/langauges/EnglishLanguage";
import { ArrowLeft } from "../../components/AllImages";
import { useEffect } from "react";


export const Login = () => {
  const [email,setEmail] = useState("");
  const [successForget,setSuccessForget] = useState({show:false,success:false,loading:false});
  const { langauge } = useSelector((state)=>state.clients);
  const { loading } = useSelector((state) => state.auth);
  const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;
  let loginSchema = yup.object().shape({
    email: yup.string().email(lang.validations.email_valid).required(lang.validations.email_required),
    password: yup.string().required(lang.validations.password_required).min(6,lang.validations.password_min)
  });
  const { register, handleSubmit, watch, getValues, reset, formState: { errors } } =  useForm({
    resolver: yupResolver(loginSchema)
  });
  const dispatch = useDispatch();
  const onSubmit =  async (data) => {
    dispatch(LogIn_Request(data, "/login"))
  };
  const forgetSubmitEvent = async () => {
    if(!validateEmail(email)){
      toast.error(lang.validations.email_valid);
      return;
    }
    setSuccessForget({...successForget,loading:true});
    try{
      const res = await PostForgetPassword({email:email});
      if(res.data.status){
       toast.success("סיסמה מעודכנת נשלחה לכתובת האימייל שלך");
       setSuccessForget({...successForget,success:true,loading:false});
      }else{
        toast.error(res.data.message);
        setSuccessForget({...successForget,loading:false});
      }
    }catch{
      toast.error('משהו השתבש');
      setSuccessForget({...successForget,loading:false});
    }
  }
  useEffect(()=>{
    const loginFields =  getValues(['email','password']);
    reset({email:loginFields[0],password:loginFields[1]});
  },[langauge])
  return (
    <div className="user-side-wrap" dir={langauge==="heb"?"rtl":"ltr"}>
      <section className="signup-wrapper white-bg-wrap">
        <Box className="py-4 d-flex justify-content-between align-items-center px-3">
          <Link to="/" className="text-white text-decoration-none back-arrow">
            <img src={ArrowLeft} alt="arrow left" height="20px" />
          </Link>
          <Box className="ms-auto">
            <LanguageDropdownSelector lang={lang} />
          </Box>
        </Box>
        <div className="container">
          <Row className="justify-content-center">
            <Col md={6} sm={12} lg={4}>
              <div className="signup-widget pb-4">
                <div className="card">
                  <div className="card-body">
                    <div className="logo color mb-2">
                      <LogoSite height="50px"/>
                    </div>
                    <div className="signup-content">
                      <div className="text-center">
                        <h3>{lang.login}</h3>
                      </div>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input">
                          <input {...register('email')} type="text" placeholder={lang.email}/>
                          <i className="ri-mail-unread-line"></i>
                        </div>
                        {errors?.email ? <ValidationMessage error={errors?.email}/> : null}
                        <div className="input">
                          <input type="password" {...register('password')} placeholder={lang.password}/>
                          <i className="ri-lock-line"></i>
                        </div>
                        {errors?.password ? <ValidationMessage error={errors?.password}/> : null}
                        <button disabled={loading} className="btn">
                          {loading ? lang.loading : lang.login}
                        </button>
                      </Form>
                      <h6 className="my-2" style={{fontSize: "14px", fontWeight: 700}}>
                        <span style={{cursor: "pointer", color: "#212529"}}
                              onClick={() => setSuccessForget({
                                ...successForget,
                                show: true,
                                success: false,
                                loading: false
                              })}
                        >{lang.forget_password}
                        </span>
                      </h6>
                      <Link to="/register" className="btn pink">
                        {lang.create_account}
                      </Link>
                      <div className="text-center">
                        <p>
                          {lang.click_to_start}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Col>
            <footer  className=" text-center" style={{background: "none",color: "#D39B2C", marginTop: "-41px"}}>
              <div>
                <Link style ={{ color: "#D39B2C"}}  to="/terms-and-condition">{lang.terms_conditions}</Link> | <Link style ={{ color: "#D39B2C"}}
                  to="/privacy-policy">{lang.privacy_policy}</Link> | <Link style ={{ color: "#D39B2C"}} to="/optin">{lang.Optin}</Link> | <Link style ={{ color: "#D39B2C"}}
                  to="/optout">{lang.Optout}</Link>
              </div>
            </footer>
          </Row>
        </div>
        {successForget.show ?
            <ForgetPasswordModel
                forgetSubmitEvent={forgetSubmitEvent}
                onChangeEmail={setEmail}
                show={successForget}
                setVisibility={setSuccessForget}
                lang={lang}
            />
            : null}
      </section>
    </div>
  );
};
export default Login;
