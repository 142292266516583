import React,{useEffect, useState} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm , Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SignUp } from "../../redux/actions/authActions";
import { customSelectStyles, customSelectStylesCity, customSelectStylesForMobile, ValidationMessage } from "../../helpers/helper";
import moment from 'moment';
import Select from "react-select";
import './auth.css';
import HebrewLanguage from "../../constant/langauges/HebrewLanguage";
import EnglishLanguage from "../../constant/langauges/EnglishLanguage";
import { LanguageDropdownSelector, LogoSite } from "../../components/Components";
import { Box } from "../../components/Style";
import styled from 'styled-components'
import { ArrowLeft } from "../../components/AllImages";
// import { DatePicker } from 'react-responsive-datepicker';
import 'react-responsive-datepicker/dist/index.css';
import {useWindowSize} from '../../helpers/helper';
import '../Upgrade.css';


export const citiesOptions = (lang,setCityOptions) => {
  const options = [];
  Object.keys(lang.cities).forEach(function(key) {
    options.push({ value: key, label: lang.cities[key] })
  });
  setCityOptions(options)
}

export const relationsOptions = (lang,setrelationOptions) => {
  const options = [];
  Object.keys(lang.relations).forEach(function(key) {
    options.push({ value: key, label: lang.relations[key] })
  });
  setrelationOptions(options)
}

export const purposesOptions = (lang,setpurposeOptions) => {
  const options = [];
  Object.keys(lang.purposes).forEach(function(key) {
    options.push({ value: key, label: lang.purposes[key] })
  });
  setpurposeOptions(options)
}

export const bodiesOptions = (lang,setbodyOptions) => {
  const options = [];
  Object.keys(lang.bodies).forEach(function(key) {
    options.push({ value: key, label: lang.bodies[key] })
  });
  setbodyOptions(options)
}

// hair color and eye color options 
export const hairColorsOptions = (lang,sethairColorOptions) => {
  const options = [];
  Object.keys(lang.hairColors).forEach(function(key) {
    options.push({ value: key, label: lang.hairColors[key] })
  });
  sethairColorOptions(options)
}
export const eyeColorsOptions = (lang,seteyeColorOptions) => {
  const options = [];
  Object.keys(lang.eyeColors).forEach(function(key) {
    options.push({ value: key, label: lang.eyeColors[key] })
  });
  seteyeColorOptions(options)
}



export const childrenOptions = (lang,setchildOptions) => {
  const options = [];
  Object.keys(lang.kids).forEach(function(key) {
    // console.log("Key :", key)
    options.push({value:key, label: lang.kids[key] })
  });
  setchildOptions(options)
}

// export const petsOptions = (lang,setpetOptions) => {
//   const options = [];
//   Object.keys(lang.animals).forEach(function(key) {
//     options.push({ value: key, label: lang.animals[key] })
//   });
//   setpetOptions(options)
// }

export const speaksOptions = (lang,setspeakOptions) => {
  const options = [];
  Object.keys(lang.speaks).forEach(function(key) {
    options.push({ value: key, label: lang.speaks[key] })
  });
  setspeakOptions(options)
}

export const qualificationsOptions = (lang,setqualificationOptions) => {
  const options = [];
  Object.keys(lang.qualifications).forEach(function(key) {
    options.push({ value: key, label: lang.qualifications[key] })
  });
  setqualificationOptions(options)
}

export const jobsOptions = (lang,setjobOptions) => {
  const options = [];
  Object.keys(lang.jobs).forEach(function(key) {
    options.push({ value: key, label: lang.jobs[key] })
  });
  setjobOptions(options)
}

export const religiousesOptions = (lang,setreligiousOptions) => {
  const options = [];
  Object.keys(lang.religiouses).forEach(function(key) {
    options.push({ value: key, label: lang.religiouses[key] })
  });
  setreligiousOptions(options)
}

export const starsOptions = (lang,setstarOptions) => {
  const options = [];
  Object.keys(lang.stars).forEach(function(key) {
    options.push({ value: key, label: lang.stars[key] })
  });
  setstarOptions(options)
}

export const cigratesOptions = (lang,setcigratOptions) => {
  const options = [];
  Object.keys(lang.cigrates).forEach(function(key) {
    options.push({ value: key, label: lang.cigrates[key] })
  });
  setcigratOptions(options)
}

export const drinksOptions = (lang,setdrinkOptions) => {
  const options = [];
  Object.keys(lang.drinks).forEach(function(key) {
    options.push({ value: key, label: lang.drinks[key] })
  });
  setdrinkOptions(options)
}
export const hobbyOptions = (lang,sethobbiesOptions) => {
  const options = [];
  Object.keys(lang.hobbiesList).forEach(function(key) {
    options.push({ value: key, label: lang.hobbiesList[key] })
  });
  sethobbiesOptions(options)
}





export const foodsOptions = (lang,setfoodOptions) => {
  const options = [];
  Object.keys(lang.foods).forEach(function(key) {
    options.push({ value: key, label: lang.foods[key] })
  });
  setfoodOptions(options)
}



export const Register = () => {
  const {width} = useWindowSize()
  const [cityOptions,setCityOptions]=useState([]);
  const [isOpen, setIsOpen] = React.useState(false)
  const { langauge } = useSelector((state)=>state.clients);
  const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;
  const ValidationSchema = yup.object().shape({
    email: yup.string().email(lang.validations.email_valid).required(lang.validations.email_required),
    password: yup.string().required(lang.validations.password_required).min(6,lang.validations.password_min),
    confirm_password: yup.string().required(lang.validations.confirm_password_required)
    .oneOf([yup.ref('password'), null], lang.validations.confirm_password),
    username: yup.string().required(lang.validations.username_required).min(3,lang.validations.username_min).max(20,lang.validations.username_max),
    dob: yup
    .string().required(lang.validations.birth_date)
    .test(
      "dob",
      lang.validations.age_limit,
      (value) => {
        if (!value) return true;
        return moment().diff(moment(value), 'years', false) >= 18 && moment().diff(moment(value), 'years', false) <= 100;
      }
    ).nullable(),
    gender: yup.string().required(lang.validations.gender_required),
    city: yup.string().required(lang.validations.city_required),
  });

  const { register, handleSubmit, getValues, reset, control, watch, formState: { errors } } =  useForm({
    resolver: yupResolver(ValidationSchema)
  });

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const onSubmit =  async (data) => {
    dispatch(SignUp(data));
  };

  const fieldWidth={width:"99%"};


  useEffect(() => {
    citiesOptions(lang,setCityOptions);
  },[langauge])
  const genderOptions = [
    { value: "male", label: lang.male },
    { value: "female", label: lang.female },
  ];


  useEffect(()=>{
    const fie =  getValues(['email','username','password','confirm_password','dob','gender','city']);
    reset({
      email:fie[0],
      username:fie[1],
      password:fie[2],
      confirm_password:fie[3],
      dob:fie[4],
      gender:fie[5],
      city:fie[6]
    });
  },[langauge])



  return (
    <DirectionWrapper className="user-side-wrap" dir={langauge==="heb"?"rtl":"ltr"}>
      <section className="signup-wrapper white-bg-wrap">
        <Box className="py-4 px-3 d-flex justify-content-between align-items-center">
          <Link to="/" className="text-white text-decoration-none back-arrow">
            <img src={ArrowLeft} alt="arrow left" height="20px" />
          </Link>
          <Box className="ms-auto">
            <LanguageDropdownSelector lang={lang} />
          </Box>
        </Box>  
        <div className="container pb-4">
          <Row className="justify-content-center">
            <Col md={6} sm={12} lg={4}>
              <div className="signup-widget">
                <div className="card">
                  <div className="card-body">
                    <div className="logo color mb-3">
                      <LogoSite height="50px" />
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)} className="signup-content">
                      <div className="text-center">
                        <h3>{lang.create_account}</h3>
                      </div>
                      <div className="input">
                        <input type="text" {...register('email')} placeholder={lang.email} />
                        <i className="ri-mail-unread-line"></i>
                      </div>
                      {errors?.email?<ValidationMessage error={errors?.email} />:null}
                      <div className="input">
                        <input type="text" {...register('username')} placeholder={lang.username} />
                        <i className="ri-user-line"></i>
                      </div>
                      {errors?.username?<ValidationMessage error={errors?.username} />:null}
                      <div className="input">
                        <input type="password" {...register('password')} autoComplete={"on"} placeholder={lang.password} />
                        <i className="ri-lock-line"></i>
                      </div>
                      {errors?.password?<ValidationMessage error={errors?.password} />:null}
                      <div className="input w-100">
                        <input type="password" {...register('confirm_password')} autoComplete={"on"} placeholder={lang.c_password} />
                        <i className="ri-lock-line"></i>
                      </div>
                      {errors?.confirm_password?<ValidationMessage error={errors?.confirm_password} />:null}

                        {/* <input type="text"  onFocus={() => {
                          setIsOpen(true)
                        }} {...register('dob')} placeholder={lang.date_birth} readOnly className={langauge=="heb"?"text-end icon-field-calendar":"text-start icon-field-calendar"} value={getValues('dob')}
                        style={fieldWidth}
                        />
                        <Controller   
                          control={control}
                          name="dob"
                          render={({ field: { onChange, value }}) => (
                            <DatePicker
                              isOpen={isOpen}
                              onClose={() => setIsOpen(false)}
                              onChange={(date)=>onChange(moment(date).format("YYYY-MM-DD"))}
                              headerFormat='DD, MM dd'
                              minDate={new Date(moment().subtract(99, 'year').toString())}
                              />
                              )}
                            /> */}

                        <div className="input position-relative date__pes__custom" data-date={watch('dob')?watch('dob'):lang.date_birth}>
                            <Controller   
                              defaultValue = {''}
                              control={control}
                              name="dob"
                              // placeholder={watch('dob')? watch('dob') : lang.date_birth}
                              render={({ field: { onChange, value }}) => (
                                <input {...register('dob')} value={value} max-length="4" maxLength="4" className="input__date__custom" max="9999-12-31" 
                                pattern="[0-9]{4}" type="date" style={{width:"calc(100% - 12px)",height:"44px"}}
                                onChange={e => onChange(e.target.value)} 
                                />
                              )}
                            />    
                        </div>
                      {errors?.dob?<ValidationMessage error={errors?.dob} />:null}


                      <div className="input">
                        <div style={{flex:1}}>
                          <Controller   
                            defaultValue = {''}
                            control={control}
                            name="gender"
                            render={({ field: { onChange, value }}) => (
                              <Select styles={customSelectStyles} classNamePrefix="react-select" options={genderOptions} placeholder={lang.gender} style={{width:"100%"}}  {...register('gender')}   
                                value={genderOptions.find(c => c.value === value)}
                                onChange={val => onChange(val.value)} 
                              />
                            )}
                          />
                        </div>
                        <i className="ri-group-line"></i>
                      </div>
                      {errors?.gender?<ValidationMessage error={errors?.gender} />:null}


                      <div className="input">
                        <div style={{flex:1}}>
                        {width>500?(
                            <Controller   
                                defaultValue = {''}
                                control={control}
                                name="city"
                                render={({ field: {onChange, value }}) => (
                                <Select autoFocus="true" styles={customSelectStylesCity} classNamePrefix="react-select" options={cityOptions} placeholder={lang.city} style={{width:"100%"}}  {...register('city')}   
                                  value={cityOptions.find(c => c.value === value)} onChange={val => onChange(val.value)} 
                                />
                              )}
                            />
                          ):(
                            <Controller   
                                defaultValue = {''}
                                control={control}
                                name="city"
                                render={({ field: {onChange, value }}) => (
                                    <Select styles={customSelectStylesForMobile} options={cityOptions} classNamePrefix="react-select" placeholder={lang.city} style={{width:"100%"}}  {...register('city')}   
                                    value={cityOptions.find(c => c.value === value)}
                                    onChange={val => onChange(val.value)} /> )}
                                />                             
                              )}
                        </div>
                        <i className="ri-map-pin-line"></i>
                      </div>
                      {errors?.city?<ValidationMessage error={errors?.city} />:null}
                      <button disabled={loading} className="btn" type="submit">
                        {loading?lang.loading:lang.register}
                      </button>
                      <Link to="/login" className="btn pink">
                        {lang.login}
                      </Link>
                      <div className="text-center">
                        <p>
                          {lang.click_to_create}
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </DirectionWrapper>
  );
};
const DirectionWrapper = styled.div`
  .css-14el2xx-placeholder,.css-1jwi8f4-singleValue{
    text-transform: capitalize;
    text-align:${({dir})=>dir==="rtl"?"right":"left"} !important;
  }
`;
export default Register;
