import { SETCLIENTS,SETLIKES,SETRANDOMUSERS,SETMESSAGES,SETLANGUAGE } from "../../constant/Keys";

const initialState = {
  clients:[],
  likedUsers:[],
  randomUsers:[],
  messages:[],
  langauge:"heb"
};

const AllClientReducer = (state = initialState, {type,payload}) => {
  switch (type) {
    case SETCLIENTS:
    return {...state,clients:payload}
    case SETLIKES:
    return {...state,likedUsers:payload}
    case SETRANDOMUSERS:
    return {...state,randomUsers:payload}
    case SETMESSAGES:
    return {...state,messages:payload}
    case SETLANGUAGE:
    return {...state,langauge:payload}
    default: return state;
  }
};

export { AllClientReducer };
