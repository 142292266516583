// read config.json file
let config = require('../config.json');

export const BASE_URL = config.backEnd+"/api/";
export const ASSET_URL = config.backEnd+"/";
export const DOMAIN_URL = config.frontEnd;
export const IMAGE_URL = config.backEnd+"/";

export const SETCLIENTS = "SET_CLIENTS";
export const SETLIKES = "SETLIKES";
export const SETRANDOMUSERS = "SETRANDOMUSERS";
export const SETMESSAGES = "SETMESSAGES";
export const SETLANGUAGE = "SETLANGUAGE";