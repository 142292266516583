import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from 'react-redux'
import HebrewLanguage from "../../constant/langauges/HebrewLanguage";
import EnglishLanguage from "../../constant/langauges/EnglishLanguage";
import { Button, Col, Modal, Row } from "react-bootstrap";


import {
  purchase,
  LocationSearch,
  CameraPana,
  PushNotifications,
  PremiumAvatar,
} from "../AllImages";
import { LanguageDropdownSelector } from "../Components";
import { Box } from "../Style";


const PurchaseModel = () => {
  return (
    <PurchaseStyled>
      <div className="pop-up-box">
        <div className="pop-up-header">
          <img src={purchase} width="121px" height="121px" />
        </div>
        <h2>Congratulations</h2>
        <h4>Your purchase successful</h4>
        <button type="button">Goto home</button>
      </div>
    </PurchaseStyled>
  );
};



const UpgradePremiumModel = ({onModalCancel}) => {
  // const { langauge } = useSelector((state)=>state.clients);
  const { langauge } = useSelector((state)=>state.clients);
  const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;
  return (
    <PurchaseStyled>
      <div className="pop-up-box h-auto">
        <div className="pop-up-header">
            <img src={PremiumAvatar} alt='upgrade' height="121px"/>
            <button type="button" onClick={()=>onModalCancel(false)} className={`border-0 bg-transparent ms-auto btn-modal-closer-white-bg-and-black-premium upgrade-premium-cross-btn`}> 
                <i className="ri-close-circle-fill"></i>
            </button>
        </div>

        {/* <h2>Upgrade to a premium</h2>
        <h4>Upgrade your account to premium account to access all services.</h4> */}
        <Box className="d-flex align-items-center justify-content-center">
            <div style={{padding:"0.5em 1em 2em 1em"}}>
              <p style={{marginTop:"1em", paddingBottom:"1em"}}>{lang.upgrade_premium_subtext}</p>
              <Link to="/upgrade" style={{padding:"0.5em", textAligh:"center"}}>{lang.upgrade_premium}</Link>
            </div>
            {/* <button type="button" className="w-auto px-3 mb-4 bg-secondary btn-secondary text-white" onClick={()=>onModalCancel(false)}>Later</button> */}
        </Box>
      </div>
    </PurchaseStyled>
  );
};



const LocationeModel = () => {
  return (
    <LocationStyled>
      <div className="pop-up-box">
        <div className="pop-up">
          <img src={LocationSearch} />
          <h2>Location access</h2>
          <h4>Ut pellentesque sodales viverra risus</h4>
          <button type="button">Allow access</button>
          <br />
          <a href="#">Skip</a>
        </div>
      </div>
    </LocationStyled>
  );
};

const CameraModel = () => {
  return (
    <CameraStyled>
      <div className="pop-up-box">
        <div className="pop-up">
          <img src={CameraPana} />
          <h2>Camara access</h2>
          <h4>Ut pellentesque sodales viverra risus</h4>
          <button type="button">Allow access</button>
          <br />
          <a href="#">Skip</a>
        </div>
      </div>
    </CameraStyled>
  );
};

const MatchModel = () => {
  return (
    <MatchStyled>
      <div className="pop-up-box">
        <div className="pop-up-header">
          <h1 className="pop-up-heading">It’s a match</h1>
          <img src={purchase} />
        </div>
        <h2>Laura Gilbert</h2>
        <div className="container">
          <h4>Steattle, USA</h4>
          <div className="item">
            <i className="ri-women-line"></i>
            23
          </div>
        </div>
        <button type="button">Send Message</button>
        <br />
        <a href="#">Skip</a>
      </div>
    </MatchStyled>
  );
};

const Notification = () => {
  return (
    <NotificationStyled>
      <div className="pop-up-background">
        <div className="pop-up-box">
          <div className="pop-up">
            <img src={PushNotifications} />
            <h2>Enable push notification</h2>
            <h4>Ut pellentesque sodales viverra risus</h4>
            <button type="button">Allow access</button>
            <br />
            <a href="#">Skip</a>
          </div>
        </div>
      </div>
    </NotificationStyled>
  );
};

const RateModal = () => {
  return (
    <RateUsStyled>
      <div className="pop-up-box">
        <div className="pop-up-header">
          <h5 className="pop-up-heading">Do you love this app?</h5>
          <p className="pop-up-heading-des">Please rate us with 5 star</p>
        </div>
        <div className="pop-up-body">
          <ul className="raiting-star">
            <li>
              <a href="#">
                <i className="ri-star-fill"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="ri-star-fill"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="ri-star-fill"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="ri-star-fill"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="ri-star-fill"></i>
              </a>
            </li>
          </ul>
          <div className="rate-us-btn">
            <button type="button">Rate us</button>
          </div>
          <div className="maybe-later">
            <a href="#">Maybe later</a>
          </div>
        </div>
      </div>
    </RateUsStyled>
  );
};

export {
  PurchaseModel,
  MatchModel,
  Notification,
  RateModal,
  LocationeModel,
  CameraModel,
  UpgradePremiumModel,
};

const PurchaseStyled = styled.div`
  background-color: #0000008f;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;

  .pop-up-box {
    background-color: #fff;
    height: 314px;
    width: 348px;
    border-radius: 20px;
    text-align: center;
    dir: ltr;
  }

  .upgrade-premium-cross-btn{
    position:absolute;
    padding:1rem;
    right:0;
    top:10;
  }

  .pop-up-header {
    background-color: #d39b2c;
    width: 348px;
    height: 112px;
    border-radius: 20px 20px 66px 66px;
    padding-top: 27px;
    margin-bottom: 35px;
  }


  .pop-up-box h2 {
    padding-top: 10px;
    margin: 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #000000;
  }
  .pop-up-box h4 {
    padding-top: 8px;
    margin: 0px;
    color: #9a9a9a;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
  .pop-up-box button, .pop-up-box a {
    width: 109px;
    height: 35px;
    background-color: #d39b2c;
    color: var(--text-clr);
    border-radius: 50px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 29px;
    cursor: pointer;
  }
`;






const NotificationStyled = styled.div`
  background-color: #0000008f;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  .pop-up-box {
    background-color: #fff;
    height: 449px;
    width: 348px;
    border-radius: 20px;
    text-align: center;
  }
  .pop-up img {
    margin-top: 37px;
  }
  .pop-up h2 {
    padding-top: 34px;
    margin: 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #000000;
  }
  .pop-up h4 {
    padding-top: 8px;
    margin: 0px;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
  .pop-up button {
    width: 156px;
    height: 35px;
    background-color: #d39b2c;
    border-radius: 50px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 25px;
    margin-bottom: 11px;
    cursor: pointer;
  }
  .pop-up a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: #9a9a9a;
  }
`;

const CameraStyled = styled.div`
  background-color: #0000008f;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  .pop-up-box {
    background-color: #fff;
    height: 449px;
    width: 348px;
    border-radius: 20px;
    text-align: center;
  }
  .pop-up img {
    margin-top: 37px;
  }
  .pop-up h2 {
    padding-top: 34px;
    margin: 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #000000;
  }
  .pop-up h4 {
    padding-top: 8px;
    margin: 0px;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
  .pop-up button {
    width: 156px;
    height: 35px;
    background-color: #d39b2c;
    border-radius: 50px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 25px;
    margin-bottom: 11px;
    cursor: pointer;
  }
  .pop-up a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: #9a9a9a;
  }
`;

const RateUsStyled = styled.div`
  background-color: #0000008f;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  .pop-up-box {
    background-color: #fff;
    width: 348px;
    border-radius: 20px;
  }
  .pop-up-header {
    background-color: #d39b2c;
    width: 348px;
    height: 112px;
    border-radius: 20px 20px 66px 66px;
    color: #fff;
    padding-left: 50px;
    padding-right: 49px;
    padding-top: 27px;
    padding-bottom: 27px;
    text-align: center;
  }
  .pop-up-heading {
    font-size: 24px;
    font-weight: 700;
    margin: 0px;
  }
  .pop-up-heading-des {
    margin-bottom: 0px;
    margin-top: 8px;
  }
  .pop-up-body {
    padding: 30px;
  }
  .raiting-star {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 45px;
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
  .raiting-star li a {
    text-decoration: none;
    color: #d39b2c;
  }
  .rate-us-btn {
    text-align: center;
  }
  .rate-us-btn button {
    text-align: center;
    width: 109px;
    height: 35px;
    border-radius: 50px;
    margin-top: 37px;
    background-color: #d39b2c;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }
  .maybe-later {
    text-align: center;
    margin-top: 11px;
  }
  .maybe-later a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: #9a9a9a;
  }
`;

const MatchStyled = styled.div`
  background-color: #0000008f;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  .pop-up-box {
    background-color: #fff;
    height: 389px;
    width: 348px;
    border-radius: 20px;
    text-align: center;
  }
  .pop-up-header {
    background-color: #d39b2c;
    width: 348px;
    height: 157px;
    border-radius: 20px 20px 66px 66px;
    padding-top: 14px;
    margin-bottom: 35px;
  }
  .pop-up-heading {
    font-size: 38px;
    font-weight: 700;
    margin: 0px;
    padding-bottom: 11px;
    color: #fff;
    line-height: 46px;
  }
  .pop-up-box h2 {
    padding-top: 15px;
    margin: 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #000000;
  }
  .container {
    display: flex;
    justify-content: center;
    padding-top: 11px;
  }
  .item {
    margin: 0px;
    align-self: center;
    margin-left: 10px;
    height: 21px;
    width: 43px;
    background: linear-gradient(#ff8961, #fd649d);
    border-radius: 24px;
    font-size: 13px;
    color: white;
  }

  .pop-up-box h4 {
    margin: 0px;
    color: #9a9a9a;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    display: flex;
    justify-content: center;
  }
  .pop-up-box button {
    width: 166px;
    height: 35px;
    background-color: #d39b2c;
    border-radius: 50px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 31px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .pop-up-box a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: #9a9a9a;
  }
`;
const LocationStyled = styled.div`
  background-color: #0000008f;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  .pop-up-box {
    background-color: #fff;
    height: 449px;
    width: 348px;
    border-radius: 20px;
    text-align: center;
  }
  .pop-up img {
    margin-top: 37px;
  }
  .pop-up h2 {
    padding-top: 34px;
    margin: 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #000000;
  }
  .pop-up h4 {
    padding-top: 8px;
    margin: 0px;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
  .pop-up button {
    width: 156px;
    height: 35px;
    background-color: #d39b2c;
    border-radius: 50px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 25px;
    margin-bottom: 11px;
    cursor: pointer;
  }
  .pop-up a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: #9a9a9a;
  }
`;
