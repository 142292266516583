import React, { Suspense } from "react";
import Spinner from "react-bootstrap/Spinner";
// import { useLocation } from 'react-router-dom'
import {
  Route,
  Switch,
  Redirect,
  // BrowserRouter as Router
} from "react-router-dom";
import { FlexBox } from "../../components/Style";
import routes from "../Routes";
import { useSelector } from "react-redux";


const PrivateRoutes = ({lang}) => {
  const { token,admin_user} = useSelector((state) => state.auth);
  return (
    <React.Fragment>
      <Suspense
        fallback={
          <FlexBox
            className="justify-content-center"
            style={{ height: "100vh" }}
          >
            <Spinner animation="grow" size="lg" variant="light" />
          </FlexBox>
        }
      >
        <Switch>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) =>
                    token && admin_user?.is_admin===1 ? (
                      <route.component lang={lang} admin="1" {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/admin/login",
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
              )
            );
          })}
          <Route path="*" render={() => <Redirect to="/404" />} />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
};

export default React.memo(PrivateRoutes);
