// create Privacy.js in src\pages\Privacy.js
// for privacy policy
import React from 'react'
import { useSelector } from 'react-redux';
import { ThemeContainer } from '../components/Style';
import { ContactUsCSS } from '../components/ContactUsCSS';
import HebrewLanguage from '../constant/langauges/HebrewLanguage';
import EnglishLanguage from '../constant/langauges/EnglishLanguage';
import Footer from '../components/Ui/Footer';

import { LogoSite, LanguageDropdownSelector } from "../components/Components"; //LanguageDropdownSelector
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { BASE_URL } from "../constant/Keys";

const ContactUs = (props) => {
    const { langauge } = useSelector((state)=>state.clients);
    const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;
    const fullName = React.useRef(null)
    const phone = React.useRef(null)
    const email = React.useRef(null)
    const message = React.useRef(null)
    const rspMessge = React.useRef(null)
    const sendBtn = React.useRef(null)
    const validateAndSubmit = (e)=>{
        e.preventDefault()
        let fNameData = fullName.current.value;
        let phoneData = phone.current.value;
        let emailData = email.current.value;
        let messageData = message.current.value;
        rspMessge.current.innerHTML = ""
        rspMessge.current.style.color = "red";
        
        if(!fNameData){
            rspMessge.current.innerHTML = lang.contactFormFname;
            return;
        }
        if(!phoneData){
            rspMessge.current.innerHTML = lang.contactFormPhone;
            return;
        }
        if(!emailData){
            rspMessge.current.innerHTML = lang.contactFormEmail;
            return;
        }
        if(!messageData){
            rspMessge.current.innerHTML = lang.contactFormMessage;
            return;
        }
        sendBtn.current.innerHTML = lang.contactFormSubmitting;
        axios.post(BASE_URL+"contact-us-form", {
            full_name: fNameData,
            phone: phoneData,
            email: emailData,
            message: messageData
        })
        .then(rspData=>{
            console.log(rspData.data)
            if(rspData.data.success){
                rspMessge.current.style.color = "green";
                rspMessge.current.innerHTML = lang.contactFormSuccess;
                sendBtn.current.innerHTML = lang.contactFormSubmited;
            }else{
                rspMessge.current.innerHTML = lang.contactFormFaild;    
                sendBtn.current.innerHTML = lang.contactFormSubmit;
            }
        }).catch(e=>{
            console.log(e);
            rspMessge.current.innerHTML = lang.contactFormFaild;
        })
        // if()
        // if(e.validate()){

        // }
    }
    return (
        // <React.Fragment>
      <div className="main-div"> 
          <div lang={langauge==="heb"?HebrewLanguage:EnglishLanguage} />
            <div className="main-content">
                <ThemeContainer>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="main-title" style={{paddingBottom:"2em"}}>
                                    <h2 className="mb-0" style={{/**display:"flex", */marginTop:"1em"}}>
                                        <Link to="/">
                                            <LogoSite style={{width:"5em"}}  />
                                        </Link>
                                        <div style={{display:"flex", textAlign:"center", float:"right"}}>
                                            {lang.contact_us}
                                            <LanguageDropdownSelector lang={lang} style={{marginLeft:"1em"}} />
                                        </div>
                                    </h2>
                                </div>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <div className="privacy-policy" style={{minHeight:"75vh"}}>
                                    <ContactUsCSS>
                                        <div className="form-main">
                                            <div className="main-wrapper">
                                                <h2 className="form-head"> {lang.contact_us}</h2>
                                                <form className="form-wrapper">
                                                    <div className="form-card">
                                                        <input ref={fullName} style={(langauge==="eng"?{}:{"textAlign":"right"})} className="form-input" type="text" name="full_name" required="required"/>
                                                        <label className="form-label" style={(langauge==="eng"?{}:{"right":"25px", "left":"auto"})} htmlFor="full_name">{lang.name}</label>
                                                    </div>
                                                    <div className="form-card">
                                                        <input ref={email} style={(langauge==="eng"?{}:{"textAlign":"right"})} className="form-input" type="text" name="email" required="required"/>
                                                        <label className="form-label" style={(langauge==="eng"?{}:{"right":"25px", "left":"auto"})} htmlFor="email">{lang.email}</label>
                                                    </div>
                                                    <div className="form-card">
                                                        <input ref={phone} style={(langauge==="eng"?{}:{"textAlign":"right"})} className="form-input" type="number" name="phone_number" required="required" />
                                                        <label className="form-label" style={(langauge==="eng"?{}:{"right":"25px", "left":"auto"})} htmlFor="phone_number">{lang.phone}</label>
                                                    </div>
                                                    <div className="form-card">
                                                        <textarea ref={message} style={(langauge==="eng"?{}:{"textAlign":"right"})} className="form-textarea" maxLength="420" rows="3" name="message" required="required" ></textarea>
                                                        <label className="form-textarea-label" style={(langauge==="eng"?{}:{"right":"25px", "left":"auto"})} htmlFor="message" >{lang.type_message}</label>
                                                    </div>
                                                    <div className="btn-wrap">
                                                        <span style={{textAlign:"center"}} ref={rspMessge}></span>
                                                        <button ref={sendBtn} onClick={validateAndSubmit} > {lang.contactFormSubmit} </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </ContactUsCSS>
                                </div>
                            </div>
                        </div>
                    </div>
                </ThemeContainer>
            </div>
          <Footer />
        </div> 
    //   </React.Fragment>
    )
}

export default ContactUs