import { sendMessage } from "../../constant/api/Api";
import {
  SETCLIENTS,
  SETLIKES,
  SETRANDOMUSERS,
  SETMESSAGES,
  SETLANGUAGE
} from "../../constant/Keys";

const AllClientAction = (view) => {
  return {
    type: SETCLIENTS,
    payload: view,
  };
};
const AllLikedAction = (view) => {
  return {
    type: SETLIKES,
    payload: view,
  };
};
const UsersRandomalyAction = (getData,state) => async (dispatch) => {
  try {
    const response = await getData();
    if (response.data.success) {
      dispatch({
        type: SETRANDOMUSERS,
        payload: response.data.data,
      });
      if(state){
        state(response.data.data)
      }
    }
  } catch (error) {}
};
const UsersMessagesAction = (view) => {
  return {
    type: SETMESSAGES,
    payload: view,
  };
};
const userSetLanguage = (language) => {
  return {
    type: SETLANGUAGE,
    payload: language,
  };
};
const sendMessagesAction = (data) => async (dispatch) => {
  try {
    const response = await sendMessage(data);
    if (response.data.success) {
     
    }
  } catch (error) {}
};

export {
  AllClientAction,
  AllLikedAction,
  UsersRandomalyAction,
  UsersMessagesAction,
  sendMessagesAction,
  userSetLanguage
};
