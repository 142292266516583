import React,{useEffect,useState} from "react";
import { FlexBox, P } from "../components/Style";
import { GetUsers } from "../pages/Discover";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
const Dashboard = () => {
  const { clients } = useSelector((state)=>state.clients);
  const [clientsData,setClientData] = useState([]);
  const dispatch = useDispatch();
  useEffect(async() => {
    await GetUsers(dispatch);
  },[])
  useEffect(()=>{
    if(clients.length>0){
      setClientData(clients.filter((x)=>x.is_admin!==1))
    }
  },[clients])
  return (
    <React.Fragment>
    <FlexBox>
      <FlexBox className="mt-4 me-4">
        <AnalyticsBox className="text-center">
          <P color="#828282" className="mb-0 d-flex align-items-center">
            <i
              className={`ri-group-line me-2`}
            ></i>
            Total users
          </P>
          <P className="counter-analy" size="34px" weight={600}>
            {clientsData?.length}
          </P>
        </AnalyticsBox>
      </FlexBox>
      <FlexBox className="mt-4 me-4">
        <AnalyticsBox className="text-center">
          <P color="#828282" className="mb-0 d-flex align-items-center">
            <i
              className={`ri-men-line me-2`}
            ></i>
            Total Mens
          </P>
          <P className="counter-analy" size="34px" weight={600}>
            {clientsData?.filter((x)=>x.gender=="male").length}
          </P>
        </AnalyticsBox>
      </FlexBox>
      <FlexBox className="mt-4 me-4">
        <AnalyticsBox className="text-center">
          <P color="#828282" className="mb-0 d-flex align-items-center">
            <i
              className={`ri-women-line me-2`}
            ></i>
            Total Womens
          </P>
          <P className="counter-analy" size="34px" weight={600}>
            {clientsData?.filter((x)=>x.gender=="female").length}
          </P>
        </AnalyticsBox>
      </FlexBox>
      <FlexBox className="mt-4">
        <AnalyticsBox className="text-center">
          <P color="#828282" className="mb-0 d-flex align-items-center">
            <i
              className={`ri-money-dollar-circle-line me-2`}
            ></i>
            Paid Users
          </P>
          <P className="counter-analy" size="34px" weight={600}>
            {clientsData?.filter((x)=>x.paid==1).length}
          </P>
        </AnalyticsBox>
      </FlexBox>
    </FlexBox>
    {/* <ApexChart /> */}
    </React.Fragment>
  );
};
const AnalyticsBox = styled.div`
  background: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 30px 35px;
  width: 210px;
  .counter-analy {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  a {
    font-size: 14px;
    font-weight: 700;
    color: #1a75bc;
  }
`;
export default Dashboard;
