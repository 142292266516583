import React from "react";
import { useSelector } from "react-redux";

import Inbox from '../pages/Inbox'

const ChatManagement = ({lang}) => {
  const { user } = useSelector((state) => state.auth);
  const url = new URL(window.location.href);
  const chat___id = url.searchParams.get("target_user");
  return (
    <>
    <h1>Chat as {user?.name}</h1>
    <Inbox lang={lang} adminUser={true} targetUser={chat___id} />
    </>
  );
};
export default React.memo(ChatManagement);
