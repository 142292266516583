export const relation_options = [
  { label: "Monogamous (single)", value: "monogamous_single" },
  { label: "Non - monogamous)", value: "non_monogamous" },
  { label: "Single", value: "single" },
  { label: "Partnered", value: "partnered" },
  { label: "Engaged", value: "engaged" },
  { label: "Married", value: "married" },
  { label: "Open to either", value: "open_to_either" },
];
export const looking_options = [
  { value: "men", label: "Men" },
  { value: "women", label: "Women" },
  { value: "both", label: "Both" },
];
export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];
export const paymentStatus = [
  { label: "Paid", value: 1 },
  { label: "Unpaid", value: 0 },
];
export const relation_purpose_options = [
  { label: "Friendship ", value: "friendship" },
  { label: "Long-term dating", value: "long-term dating" },
  { label: "Short-term datinged", value: "short-term dating" },
  { label: "Hookups", value: "hookups" },
];

export const body_type_options = [
  { label: "Thin", value: "thin" },
  { label: "Fit", value: "fit" },
  { label: "In Shape", value: "overweight" },
  { label: "Muscular", value: "average_build" },
  { label: "Average Build", value: "jacked" },
  { label: "Few Extra Kilos", value: "a_little_extra" },
  { label: "Chubby & Juicy", value: "curvy" }
];



export const occupation_options = [
  { label: "Employed Full Time", value: "employed_full_time" },
  { label: "Employed part time", value: "employed_part_time" },
  { label: "Freelance worker", value: "freelance_worker" },
  { label: "Self employed", value: "self_employed" },
  { label: "Unemployed", value: "unemployed" },
  { label: "Retired", value: "retired" },
];
export const alcoholOptions = [
  { label: "Drinks often", value: "drinks often" },
  { label: "Drinks sometimes", value: "drinks sometimes" },
  { label: "Doesn't drink", value: "doesn't drink" },
];
export const dietOptions = [
  { label: "Vegetarian", value: "vegetarian" },
  { label: "Vegan", value: "vegan" },
  { label: "Kosher", value: "kosher" },
  { label: "Halal", value: "halal" },
  { label: "Gluten free", value: "gluten_free" },
];
export const smokeOptions = [
  { label: "Smoke cigarettes regularly", value: "Smoke cigarettes regularly" },
  { label: "Smoke cigarettes sometimes", value: "Smoke cigarettes sometimes" },
  { label: "Doesn't smoke cigarettes", value: "Doesn't smoke cigarettes" },
];
export const childrenOptions = [
  { label: "Doesn't have kids but might want them", value: "Doesn't have kids but might want them" },
  { label: "Doesn't have kids but wants them", value: "Doesn't have kids but wants them" },
  { label: "Doesn't have kids and doesn't want them", value: "Doesn't have kids and doesn't want them" },
  { label: "Has kids and doesn't want more", value: "Has kids and doesn't want more" },
  { label: "Has kids and might want more", value: "Has kids and might want more" },
  { label: "Has kids and want more", value: "Has kids and want more" },
];
export const petsOptions = [
  { label: "Doesn't have pet(s)", value: "Doesn't have pet(s)" },
  { label: "Has other pet(s)", value: "Has other pet(s)" },
  { label: "Has cat(s)", value: "Has cat(s)" },
  { label: "Has dog(s)", value: "Has dog(s)" },
];
export const languages_options = [
  { label: "Hebrew", value: "heb" },
  { label: "Russian", value: "Russian" },
  { label: "Ukrininane", value: "Ukrininane" },
  { label: "Arab", value: "Arab" },
  { label: "Spanish", value: "spanish" },
  { label: "Other", value: "other" },
];
export const education = [
  { label: "High school", value: "High school" },
  { label: "Trade/tech schoolIn College", value: "Trade/tech schoolIn College" },
  { label: "Undergraduate degree", value: "Undergraduate degree" },
  { label: "In grad School", value: "In grad School" },
  { label: "Graduate degree", value: "Graduate degree" },
];
export const relegion_options = [
  { label: "Atheism", value: "Atheism" },
  { label: "Christianity", value: "christianity" },
  { label: "Judaism", value: "judaism" },
  { label: "Catholicism", value: "catholicism" },
  { label: "Islam", value: "islam" },
  { label: "Other religion", value: "Other religion" },
];
export const sign_options = [
  { label: "Aries", value: "aries" },
  { label: "Taurus", value: "taurus" },
  { label: "Gemini", value: "gemini" },
  { label: "Cancer", value: "cancer" },
  { label: "Leo", value: "leo" },
  { label: "Vigro", value: "vigro" },
  { label: "Libra", value: "libra" },
  { label: "Scorpio", value: "scorpio" },
  { label: "Sagittaruis", value: "sagittaruis" },
  { label: "Capricornus", value: "capricornus" },
  { label: "Aquarius", value: "aquarius" },
  { label: "Pisces", value: "pisces" },
];

