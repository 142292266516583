import { createGlobalStyle } from "styled-components";
import {CalendarIcon,ArrowDownIcon} from './AllImages'


const GlobalStyle = createGlobalStyle`
:root {
    --text-clr: #000;
    --text-grey: #9A9A9A;
    --theme-clr: #CF982B;
    --tras-theme:rgba(26, 117, 188, 0.1);
}
body {
    background-color: #f7f7f7;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--text-clr);
    transition: 0.2s linear;
    overflow-x: hidden;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
p {
    font-size: 14px;
}
.f-bold{
    font-weight:700 !important;
}
.w-200 {
    font-weight: 200;
}
.w-300 {
    font-weight: 300;
}
.w-400 {
    font-weight: 400;
}
.w-500 {
    font-weight: 500;
}
.w-600 {
    font-weight: 600;
}
.w-700 {
    font-weight: 700;
}
.mt-80 {
    margin-top: 80px;
}
.text-clr {
    color: var(--text-clr);
}
.text-grey {
    color:var(--text-grey);
}
.trans-btn {
    font-weight: 700;
    color:var(--theme-clr);
    font-size: 14px;
}
.trans-theme {
    background:var(--tras-theme) !important;
}
a {
    text-decoration: none;
}
input, button , textarea, select {
    outline: none;
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.end {
    display: flex;
    align-items: center;
    justify-content: end;
}
.flex-1{
    flex:1;
}
.white-space-nowrap {
    white-space: nowrap;
}
.main-content {
    min-height: calc(100vh - 7rem);
}
.span-child-100{
    span.lazy-load-image-background{
        width:100%;
    }
}
.rangeslider-horizontal .rangeslider__fill{
    background-color: #d39b2c !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image{
    height: 300px;
    object-fit: cover; 
    border-radius:15px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image{
    height:100px;
    object-fit:cover;
}
.fullscreen .image-gallery-slide .image-gallery-image {
    height: calc(100vh - 80px);
    max-height: initial;
    object-fit:contain;
}
.image-gallery-thumbnail{
    border-radius:8px;
    overflow:hidden;
}
.image-gallery-right-nav .image-gallery-svg,
.image-gallery-left-nav .image-gallery-svg{
    width:30px;
}
.user-card.selected{
    border:3px dashed #D39B2C;
}
*[dir=rtl] {
    .card-header .filter{
        margin-right:15px;
    }
    .react-input-emoji--input {
        text-align: right !important;
    }
    .recent-user .user-avatar{
      margin-left:15px;
    }
    .attach-border-m{
        margin-left:15px !important;
        margin-right:15px !important;
    }
    .body_ .users-bar .recent-user .text-info{
        margin-left:0px !important;
    }
    .body_ .chat-box .footer .message-input-wrapper input{
        padding-right:15px;
    }
    .container_ .wrapper .head .search img{
        margin-left:15px;
    }
    .dropdown-toggle::after{
        margin-right: 0.255em;
    }
    .message-input-wrapper button img{
        transform:rotate(180deg);
    }
    .delete_messages_btn{
        margin-right:12px;
    }
}
*[dir=ltr]  {
    .image-card {
        margin-right: 30px;
    }
    .features .icon{
        margin-right: 15px;
    }
    .label-measure{
        padding-left:3px;
    }
    .delete_messages_btn{
        margin-left:12px;
    }
}
.btn-svg-white{
    path{
        fill:#fff;
    }
}
*[dir=rtl]  
{
    .output-result li{
        margin-right:0px !important;
        margin-left:0.5rem !important;
    }
    .image-card {
        margin-left: 30px;
    }
    .features .icon{
        margin-left: 15px;
    }
    .mr-rtl{
        margin-left: 1.5rem!important;
    }
    .rccs__number{
        direction:ltr;
    }
}
.filter .dropdown-toggle{
    min-width:0 !important;
}
.user-side-wrap .sc-bjUoiL.hmATqo .css-b62m3t-container{
 width:100% !important;
}
.fixed-with .sc-bjUoiL.hmATqo .css-b62m3t-container{
    width:150px;
}
.custom-select-bar .css-1s2u09g-control, .custom-select-bar .css-1pahdxg-control, .custom-select-bar .css-1insrsq-control {
    box-shadow: none !important;
    min-height: 45px !important;
    background: rgb(247, 247, 247) !important;
    border: 1px solid rgb(189, 189, 189) !important;
    border-radius: 5px !important;
}
.page-link{
    cursor:pointer;
}
.btn-modal-closer{
    font-size:40px !important;
    color:#dc3545 !important;
    background:transparent !important;
    padding:0 !important;
    z-index:100;
    margin-top:-20px !important;
    margin-right:-15px !important;
}
.btn-modal-closer-black-and-white{
    font-size:40px !important;
    color:black !important;
    background:transparent !important;
    padding:0 !important;
    z-index:100;
    margin-top:-20px !important;
    margin-right:-15px !important;
}

.btn-modal-closer-black-and-white-premium{
    font-size:40px !important;
    color:black !important;
    background:transparent !important;
    padding:0;
    z-index:100;
    margin-top:-20px !important;
    margin-right:-15px !important;
}


.btn-modal-closer-white-bg-and-black-premium{
    font-size:40px !important;
    color:white !important;
    background:transparent !important;
    padding:0;
    z-index:100;
    margin-top:-20px !important;
    margin-right:-15px !important;
}


.flex-box-input{
    display: flex;
    justify-content: center;
    align-items: center ;
}



*[dir="rtl"]{
    .update__form__ .date__pes:after{
        left: 0;
        right:auto;
        padding-left:0px;
        padding-right:10px;
    }
}
.update__form__{
    input,select{
        background-color: transparent !important;
        @media(max-width:767px){
            text-align: right;
        }
    }
    input,textarea,select{
        border:0;
        box-shadow:none !important;
        padding:0;
        font-size:14px;
        @media(max-width:767px){
            color: #9A9A9A;
            font-size: 14px;
            font-weight: 400;
        }
    }
    input{
        text-align:right;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
    select{
        background-image:url(${ArrowDownIcon});
        background-repeat:no-repeat;
        appearance:none;
        background-position: right 0px center;
        padding-right:20px;
        max-width:150px;
        width: 100%
    }
    .date__pes{
        &:after{
            content:attr(data-date);
            position: absolute;
            top:6px;
            right:0px;
            background:#fff;
            padding-left:20px;
            @media(max-width:767px){
                top: 10px;
                color: #9A9A9A;
            }
        }
        input{
            // background-image:url(${CalendarIcon});
            // padding-right:30px;
            background-size:20px;
            background-repeat:no-repeat;
            background-position:right 0 center;
        }
    }
}
.custom-select-lang{
    .css-b62m3t-container{
        max-width: 150px;
    }
    .css-1s2u09g-control{
        border:0px !important;
        .css-1okebmr-indicatorSeparator{
            width:0;
        }
        .css-tlfecz-indicatorContainer{
            padding-right: 0px;
        }
    }
}
*[dir=ltr]{
    table td select{
        text-align:right;
    }
    @media(min-width:767px) {
        .custom-select-lang{
            .css-b62m3t-container{
                margin-left: auto;
            }
        }
    }
}
@media(max-width:767px){
    .update__form__{
        .css-319lph-ValueContainer{
            padding-left:0px !important;
            padding-right:0px !important;
        }
        select,.css-b62m3t-container{
            width:150px;
            max-width:initial;
            margin-left: auto;
        }
    }
    *[dir=rtl]{
        .css-b62m3t-container{
            margin-right: auto;
            margin-left: 0;
        }
    }
}
*[dir=rtl]{
    .custom-select-lang{
        .css-b62m3t-container{
          direction: ltr;
        }
    }
    .label-measure{
        padding-right:3px;
    }
    .user-info-element{
        padding-right:15px;
        p{
            &:before{
                left:auto;
                right:-6px;
            }
        }
    }
}
.user-info-element{
    p{
        margin-left:6px;
        padding-left:6px;
        position: relative;
        font-size:14px;
        &::before{
            content:"";
            position: absolute;
            left:0;
            top:5px;
            width:1px;
            height:14px;
            background-color: #000;
        }
        &:first-child{
            &:before{
                display: none;
            }
        }
    }
}
.send-msg-input-element{
    width:100%;
    height:100%;
    border:0px;
    background-color:transparent;
}

.text-rtl{
    text-align:right !important;
}

.icon-element{
    width:30px;
    text-align: center;
}
.popup__modal{
    .modal-header .modal-title{
      font-size:16px;
    }
    .modal-body{
        padding-top:30px;
    }
    .modal-field{
        .input{
            input{
                height:50px;
                border-radius: 10px;
                width:100%;
                border:1px solid #dee2e6;
                margin-bottom:20px;
                padding-left:15px;
                padding-right:15px;
            }
        }
    }
    .modal__footer button{
        min-width:120px;
        border:0;
        &:first-child{
           
        }
    }
  }
  .btn-center:not(.dropdown-custom), .btn:not(.dropdown-custom) {
    background: linear-gradient(267.17deg,#e0aa3e,#ffce40 49.63%,#b88a44);
    border-radius: 24px;
    font-size: 17px;
    font-weight: 500;
    min-width: 170px;
    padding: 8px 15px;
  }
  .gallery__data{
    .lazy-load-image-background{
        width:100%;
        height:200px;
        img{
            object-position:top center;
        }
    }
  }
  .modal-header .btn-close{
    margin: 0px;
  }
  .emoji-mart-search-icon{
    display: none;
  }
  .Toastify__toast{
    direction: rtl;
  }
  @media(max-width: 575px){
    .gallery__data.gallery___item .user-card {
      max-width: 100% !important;
      .file-upload-gallery {
        height: 100% !important;
      }
    }
    
  }
`;

export default GlobalStyle;
