import React, { useEffect, useState } from "react";
import Styles from "./Styles1";
import { Form, Field } from "react-final-form";
import Card from "./Card";
import { useSelector, useDispatch } from "react-redux";
import {formatCreditCardNumber, formatCVC, formatExpirationDate} from "./CardUtils";
// import { PaymentApi } from "../../constant/api/Api";
import { initializePayment } from "../../constant/api/Api";
import { toast } from "react-toastify";
import { LOAD_USER_SUCCESS } from "../../redux/constants/userConstant";
import { Box } from "../Style";
import HebrewLanguage from "../../constant/langauges/HebrewLanguage";
import EnglishLanguage from "../../constant/langauges/EnglishLanguage";
import {useWindowSize} from '../../helpers/helper';
import htmlparser from 'html-react-parser';

const PaymentCard = ({ amount,setPaymentFormShow }) => {
    const [paymentIframe, setPaymentIframe] = useState('');
    const {width} = useWindowSize()
    const dispatch = useDispatch();
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const { langauge } = useSelector((state)=>state.clients);
    const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;
    const user = useSelector((state) => state.auth.user);
    // const[cardNumber, setCardNumber] = useState(lang.card_number)
    
    useEffect(async () => {
        try {
            const res = await initializePayment(user.id);
            if(res.status === 200) {
                setPaymentIframe(res.data);
            }else {
                toast.error(res.data.message);
            }
        }
        catch(error){
            toast.error('משהו השתבש');
        }
    },[])

    const onSubmit = (values) => {
        //
    };

    return (
    <Styles>
        <Form onSubmit={onSubmit} render={({handleSubmit, form, submitting, pristine, values, active,}) => {
            return (
                <form>
                    <Box className="text-end">
                        <button type="button" onClick={()=>setPaymentFormShow(false)} className="border-0 bg-transparent ms-auto btn-modal-closer-black-and-white">
                            <i className="ri-close-circle-fill"></i>
                        </button>
                    </Box>
                    
                    <div id="left-column" class="left-column">
                        {htmlparser(paymentIframe)}
                    </div>

                    <div> 
                        <p style={{width:"100%",fontSize:"14px", lineHeight:"normal", textAlign:'center'}}>
                          {lang.save_card}
                        </p>
                    </div>
                </form>
            );}}/>
    </Styles>
    );
};

export default PaymentCard;