import React from 'react';
import ReactDOM from 'react-dom';
import { AdminApp, UserApp } from './App';
// import reportWebVitals from './reportWebVitals';
import { Router, Route, Switch } from "react-router-dom"; //BrowserRouter
import { Provider } from 'react-redux'
import store from './redux/store';
import { PersistGate } from "redux-persist/integration/react";
import Login from './pages/auth/Login';
import LoginAdmin from './admin/Login';
import Register from './pages/auth/Register';
import Welcome from './pages/Welcome';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import ContactUs from './pages/ContactUs';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import history from './helpers/history';
import LoginRedirectRoute from './routes/LoginRedirectRoute';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import PrivateRoutes from './routes/admin/PrivateRoutes';
import GooglePay from './pages/GooglePay';
import ApplePay from './pages/ApplePay';
import Optin from "./pages/Optin";
import Optout from "./pages/Optout";


const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: 1000 * 6,
});


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <Switch>
              <LoginRedirectRoute exact={true} path="/admin/login" component={LoginAdmin} />
              <LoginRedirectRoute exact={true} path="/" component={Welcome} />
              <LoginRedirectRoute exact={true} path="/login" component={Login} />
              <LoginRedirectRoute exact={true} path="/register" component={Register} />
              <Route path="/terms-and-condition" component={Terms} />
              <Route path="/privacy-policy" component={Privacy} />
              <Route path="/optin" component={Optin} />
              <Route path="/optout" component={Optout} />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/apple-pay" component={ApplePay} />
              <Route path="/google-pay" component={GooglePay} />
              <Route path="/admin" component={AdminApp} />
              <Route path="/" component={UserApp} />

            </Switch>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();