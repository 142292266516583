import { useState, useEffect } from 'react';

export default function useLongPress(callback = () => {}, ms = 500) {
  const [startLongPress, setStartLongPress] = useState(false);

  const [event, setEvent] = useState(null);
  const [message, setMessage] = useState(null);
  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(()=>callback(event, message), ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [()=>callback(event, message), ms, startLongPress]);

  return {
    onTouchStart: (e, message) => {
        setStartLongPress(true)
        setEvent(e)
        setMessage(message)
    },
    onTouchEnd: (e, message) => {
        setStartLongPress(false)
        setEvent(null)
        setMessage(null)
    },
  };
}