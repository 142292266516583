
// create Terms.js in src\pages\Terms.js
// for terms and conditions
import React from 'react'
import { useSelector } from 'react-redux';
import { ThemeContainer } from '../components/Style';
import HebrewLanguage from '../constant/langauges/HebrewLanguage';
import EnglishLanguage from '../constant/langauges/EnglishLanguage';
import Footer from '../components/Ui/Footer';
import { LanguageDropdownSelector, LogoSite } from "../components/Components"; //LanguageDropdownSelector
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Terms = () => {
    const { langauge } = useSelector((state)=>state.clients);
    const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;

    return (
        <React.Fragment>
        {/* <div className="main-div"> */}
          <div lang={langauge==="heb"?HebrewLanguage:EnglishLanguage} />
            <div className="main-content">
                <ThemeContainer>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="main-title" style={{paddingBottom:"2em"}}>
                                    <h2 className="mb-0" style={{/**display:"flex",*/ marginTop:"1em"}}>
                                        <Link to="/">
                                            <LogoSite style={{width:"5em"}}  />
                                        </Link>
                                        <div style={{display:"flex", textAlign:"center", float:"right"}}>
                                            {lang.terms_conditions}
                                            <LanguageDropdownSelector lang={lang} style={{marginLeft:"1em"}} />
                                        </div>
                                    </h2>
                                </div>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <div className="privacy-policy" style={{minHeight:"75vh"}}>
                                    <p dangerouslySetInnerHTML={{__html:lang.terms_conditions_data}} style={{textAlign:((langauge==="heb")?"right":"left"), direction:((langauge==="heb")?"rtl":"ltr")}}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ThemeContainer>
            </div>
            <footer className=" text-center" style={{background: "none", color: "#D39B2C", marginTop: "-43px"}}>
                <div>
                    <Link style={{color: "#D39B2C"}} to="/terms-and-condition">{lang.terms_conditions}</Link> | <Link
                    style={{color: "#D39B2C"}}
                    to="/privacy-policy">{lang.privacy_policy}</Link> | <Link style={{color: "#D39B2C"}}
                                                                              to="/optin">{lang.Optin}</Link> | <Link
                    style={{color: "#D39B2C"}}
                    to="/optout">{lang.Optout}</Link>
                </div>
            </footer>
            {/* </div> */}
        </React.Fragment>
    )
}

export default Terms
