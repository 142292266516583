import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeContainer } from '../Style'
import { Linkedin, Twitter } from '../AllImages'
import { lang } from 'moment'
import { useSelector } from 'react-redux';

import HebrewLanguage from '../../constant/langauges/HebrewLanguage';
import EnglishLanguage from '../../constant/langauges/EnglishLanguage';

const Footer = () => {
    const { langauge } = useSelector((state)=>state.clients);
    const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;

    return (
        <footer className=" text-center" style={{background: "none", color: "#D39B2C", marginTop: "1px"}}>
            <div>
                <Link style={{color: "#D39B2C"}} to="/terms-and-condition">{lang.terms_conditions}</Link> | <Link
                style={{color: "#D39B2C"}}
                to="/privacy-policy">{lang.privacy_policy}</Link> | <Link style={{color: "#D39B2C"}}
                                                                          to="/optin">{lang.Optin}</Link> | <Link
                style={{color: "#D39B2C"}}
                to="/optout">{lang.Optout}</Link>
            </div>
        </footer>
    )
}

export default Footer