import React from "react";
import { Box, P } from "../Style";
import styled from "styled-components";
import {
  Logo,
  DashboardIcon,
  ReportIcon,
  RequestIcon,
  CategoryIcon,
  WebSetting,
  JobManagmentIcon,
} from "../AllImages";
import { Link, useLocation } from "react-router-dom";
const navlinks = [
  { title: "Dashboard", url: "/admin/dashboard", icon: <DashboardIcon /> },
  // {
  //   title: "Job applicants",
  //   url: "/admin/jobs/applicants",
  //   icon: <ReportIcon />,
  // },
  { title: "Users Management", url: "/admin/users", icon: <JobManagmentIcon /> },
  { title: "Users Messages", url: "/admin/user-messages", icon: <JobManagmentIcon /> },
  // {
  //   title: "Project requests",
  //   url: "/admin/project-requests",
  //   icon: <RequestIcon />,
  // },
  // { title: "Categories", url: "/admin/categories", icon: <CategoryIcon /> },
  { title: "Website settings", url: "/admin/settings", icon: <WebSetting /> },
];
const Sidebar = () => {
  const { pathname } = useLocation();
  return (
    <Aside>
      <Box className="text-center">
        <Box className="logo color text-uppercase">
          <h1 style={{color: "#D39B2C"}}>Dating</h1>
        </Box>
        <P className="mt-3 mb-0" color="#BDBDBD" size="14px" weight="500">
          Admin Panel
        </P>
        <NavList className="position-relative list-unstyled mb-0">
          {navlinks.map((list, key) => {
            return (
              <li key={key}>
                <Link
                  to={list.url}
                  className={`text-decoration-none d-flex align-items-center ${
                    pathname == list.url ? "active" : ""
                  }`}
                >
                  {list.icon}&nbsp;{list.title}
                </Link>
              </li>
            );
          })}
        </NavList>
      </Box>
    </Aside>
  );
};
const Aside = styled.aside`
  background: #f7f7f7;
  width: 261px;
  padding-top: 35px;
  padding-bottom: 35px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.0168519),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0274815),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.035),
    0px 20px 13px rgba(0, 0, 0, 0.0425185),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0531481),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.07);
`;
const NavList = styled.ul`
  margin-top: 30px;
  padding-top: 18px;
  li {
    margin-bottom: 14px;
    a {
      padding: 10px 20px 10px 50px;
      svg {
        margin-right: 15px;
      }
      transition: 0.3s ease-in-out;
      &:not(.active) {
        font-weight: 400;
        font-size: 14px;
        color: #828282;
      }
      &.active,
      &:hover {
        font-weight: 700;
        color: rgb(211, 155, 44);
        background: rgb(26 117 188 / 10%);
        svg,
        svg path {
          fill: rgb(211, 155, 44);
        }
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% - 21px);
    width: 42px;
    height: 0;
    border: 1px solid #e0e0e0;
  }
`;
export default Sidebar;
