import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  searchIcon,
  user1,
  user2,
  user3,
  user4,
  user5,
  attachPin,
  rightArrow,
  Trash,
} from "../components/AllImages";
import { Image } from "../components/Components";
import { Box, Heading } from "../components/Style";
import { UpgradePremiumModel } from "../components/Ui/Popups";
import { deleteUserChat, galleryDeleteApi, GetAllConnections, getGallery, seenMessages, uploadGalleryAPi, keywordsGet } from "../constant/api/Api";
import { ASSET_URL, IMAGE_URL, SETMESSAGES } from "../constant/Keys";
import { userAvatar } from "../helpers/helper";
import { sendMessagesAction } from "../redux/actions/ClientActions";
import { Link } from "react-router-dom";
import InputEmoji from "react-input-emoji";
import { toast } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from "react-bootstrap/Dropdown";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import config from '../config.json';
import useContextMenu from "../hooks/useContextMenu";
import { ContextMenu } from "../styles/styles";
import useLongPress from "../hooks/useLongPress";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Inbox = ({lang, adminUser=null, targetUser=null}) => {
  const { clicked, setClicked, points, setPoints } = useContextMenu();
  const { messages } = useSelector((state) => state.clients);
  const { user, admin_user } = useSelector((state) => state.auth);

  const [activeuser, setActiveUser] = useState(null);
  const [sendData, setSendData] = useState({  message: "" });
  const [media,setMedia] = useState([]);
  const [galleryMedia,setGalleryMedia] = useState([]);
  const [selectedMediaGallery,setSelectedMediaGallery] = useState([]);
  const [modalView, setModalView] = useState(false);
  const [modalShowGallery, setModalShowGallery] = React.useState(false)
  const [filterdUsers, setFilterdUsers] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const url = new URL(window.location.href);
  const { langauge } = useSelector((state)=>state.clients);
  const [del_loader,setDelLoader] = useState(false);
  const chat___id = url.searchParams.get("u");
  const dispatch = useDispatch();
  const bottomRef = useRef();
  const [selectGallery,setSelectedGallery] = useState([]);
  const [words, setWords] = useState([]);
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messgaeDeleteTargetKey, setMessgaeDeleteTargetKey] = useState(null);
  const [messgaeDeleteTargetId, setMessgaeDeleteTargetId] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);

  const [socketConnectInterval, setSocketConnectInterval] = useState(null);

  const [onlineUsersInterval, setOnlineUsersInterval] = useState(null);
  const [getCUserInterval, setGetCUserInterval] = useState(null);
  const [socket, setSocket] = useState(null);
  const socketRef = useRef(null);

  const longPressBackspaceCallback = (e, message) => {
    e.preventDefault();
    if(!e.touches || e.touches.length !== 1) return;
    console.log("long press is triggered", e, e.touches[0].pageX, e.touches[0].pageY);
    if(!e.touches[0].pageX || !e.touches[0].pageY){
      return;
    }
    let evt = new Event("contextmenu", {
      bubbles: true,
      cancelable: true,
      pageX: e.touches[0].pageX,
      pageY: e.touches[0].pageY,
    })
    evt.pageX = e.touches[0].pageX;
    evt.pageY = e.touches[0].pageY;
    e.target.dispatchEvent(evt);
    navigator.vibrate(1000);
  }

  const backspaceLongPress = useLongPress(longPressBackspaceCallback, 500);

  const activeUserRef = useRef();
  activeUserRef.current = activeuser;

  const currentChatRef = useRef();
  currentChatRef.current = currentChat;

  const setCurrentChatRef = useRef();
  setCurrentChatRef.current = setCurrentChat;
  const chatsRef = useRef();
  const user__conection = admin_user && (new URL(window.location.href).pathname.includes('chat/self')|| new URL(window.location.href).pathname.includes('admin/chat')) ? admin_user : user;

  const seenMessgaeApi = async (list) => {
    if(list?.unread_messages>0){
      try{
        await seenMessages({connection_id:list.id});

      }
      catch(error){

      }
    }
  }

  const renderTooltip = (props) => (
      <Tooltip {...props}>
        {`Delete all ${
            activeuser?.connected_to_user?.id === user__conection.id?
                activeuser?.connected_by_user?.name:
                activeuser?.connected_to_user?.name} chat`
        }
      </Tooltip>
  );
  const scrollBottom = () => {
    if(bottomRef && bottomRef.current){
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const socketConnect = () => {

    const token = localStorage.datingAppauthToken;
    if(token){
      let wsUrl = `${config.websocket}?token=${token}`;
      if(adminUser && targetUser){
        wsUrl += `&admin_user=${adminUser}&target_user=${targetUser}`;
      }
      let tmpSocket = new WebSocket(wsUrl);
      tmpSocket.onopen = (connection) => {
        console.log("Connected to server....");
        setOnlineUsersInterval(setInterval(() => {
          tmpSocket.send(JSON.stringify({type: 'online_users'}));
        }, 10000));
        clearInterval(socketConnectInterval);
        setSocketConnectInterval(null);
        tmpSocket.send(JSON.stringify({type:"get_connections", isNewConnection: chat___id, from: user__conection.id}));
        tmpSocket.send(JSON.stringify({type: 'online_users'}));
      };
      tmpSocket.onerror = (error) => {
        console.log("Error in connection establishment", error);
        // wait 5 seconds and try again
        setSocketConnectInterval(setInterval(function () {
          socketConnect();
        }, 5000));
      };
      tmpSocket.onclose = (event) => {
        console.log("Socket Closed Connection: ", event);
        // wait 5 seconds and try again
        setTimeout(function () {
              socketConnect();
            }
            , 5000);
      };
      tmpSocket.onmessage = onNewMessageHandler;
      setSocket(tmpSocket)
      // update SocketRef
      socketRef.current = tmpSocket;
    }else{
      // wait 10 seconds and try again
      setTimeout(function () {
        socketConnect();
      }, 10000);
    }
  }

  // const attachOnMessage = () => {
  //   console.log("Socket..............", socket)
  //   if (socket.readyState === WebSocket.OPEN) {
  //     socket.send(JSON.stringify({type:"get_connections", isNewConnection: chat___id, from: user__conection.id}));
  //   }else{
  //     socket.onopen = () => {
  //       socket.send(JSON.stringify({type:"get_connections", isNewConnection: chat___id, from: user__conection.id}));
  //     }
  //   }
  //   socket.onmessage = onNewMessageHandler;
  //   if(socketConnectInterval){
  //     clearInterval(socketConnectInterval); 
  //   } 
  // }

  useEffect(() => {

    socketConnect();

    // socket.onclose = () => {
    //   console.log("disconnected");
    //   socketConnect();
    //   socket.onopen = () => {
    //     socket.send(JSON.stringify({type:"get_connections", isNewConnection: chat___id, from: user__conection.id}));
    //   }
    //   socket.onmessage = onNewMessageHandler;
    // };

    getGallery().then(res=>{
      if(res.data.success){
        setGalleryMedia(res.data.data)
      }
    }).catch(err=>{
      console.log(err)
    })

    keywordsGet().then(res=>{
      if(res.data.status == 200) {
        setWords(res.data?.data)
      }
      else {
        toast.error(res.data.message);
      }
    }).catch(err=>{
      console.log(err)
      toast.error('משהו השתבש');
    })

  }, [])

  const retryGetmessages = (currentUser) => {
    if(socket){
      console.log("waiting for socket to connect", currentUser);
      updateCurrentChat(currentUser);
      return true;
    }else{
      return false
    }
  }
  const onNewMessageHandler = async(event) => {
    // get current value of activeuser
    const currentActiveUser = activeUserRef.current;
    let data = JSON.parse(event.data);
    if(data.type === "get_connections"){
      let tmp = data.connections;
      // reorder tmp according to last message time
      tmp.sort((a,b)=>{
        return new Date(b.last_message_time) - new Date(a.last_message_time);
      });
      setChats(tmp);
      chatsRef.current = tmp;
      if(chat___id){
        let currentUser = data.connections.find((item)=>item.id === parseInt(chat___id));
        if(currentUser){
          // setActiveUser(currentUser);
          // setCurrentChat(currentUser);
          if(socketRef.current){
            updateCurrentChat(currentUser);
          }else{
            // wait 5 seconds and try again until socket is connected
            setGetCUserInterval(setInterval(function () {
              console.log("waiting for socket to connect", socketRef);
              if(retryGetmessages(currentUser)){
                clearInterval(getCUserInterval);
                setGetCUserInterval(null);
              }
            }, 1000));
          }
        }
      }
    }else if(data.type === "get_messages"){
      setCurrentChat(data.messages);
      scrollBottom();
    }else if(data.type === "message"){
      console.log("Chat", chatsRef.current)
      let tempChats = chatsRef.current;
      tempChats.forEach((item, index)=>{
        if(item.connection_id === data.connection_id){
          tempChats[index].last_message = data.message;
          tempChats[index].last_message_time = data.created_at;
          tempChats[index].last_message_from = parseInt(data.sender);
          if(!activeuser){
            tempChats[index].unread_messages += 1;
          }else {
            if(activeuser.connection_id !== data.connection_id){
              console.log("--1>", activeuser.connection_id !== data.connection_id)
              tempChats[index].unread_messages += 1;
            }
          }
        }
      });
      // order chats according to last message time
      tempChats.sort((a,b)=>{
        return new Date(b.last_message_time) - new Date(a.last_message_time);
      });
      setChats(prev=>[...tempChats]);
      chatsRef.current = tempChats;
      if(currentActiveUser && data.connection_id === currentActiveUser.connection_id){
        updateCurrentMessages(data);
      }
    }else if(data.type === "delete_message"){
      console.log("Active", currentActiveUser, data.connection_id, "===", currentActiveUser.connection_id);
      if(currentActiveUser && data.connection_id === currentActiveUser.connection_id){
        removeMessageFromChat(data);
      }
    }else if(data.type === "online_users"){
      setOnlineUsers(data.users);
    }else if(data.type === "conversation_delete"){
      let tempChats = chatsRef.current;
      tempChats = tempChats.filter((item) => item.connection_id !== data.connection_id);
      setChats(prev=>[...tempChats]);
      chatsRef.current = tempChats;
      if(activeuser && data.connection_id === activeuser.connection_id){
        setActiveUser(null);
        setCurrentChat([]);
        updateCurrentChat(null  );
      }
    }
  }

  const removeMessageFromChat = (data) => {
    /**
     {
     "type": "delete_message",
     "message_id": 42,
     "connection_id": 6,
     "to": 274
     }
     */
    let newChats = currentChatRef.current;
    if(newChats){
      newChats = newChats.filter((item) => item.message_id != data.message_hash);
      setCurrentChat(newChats);
    }
  }

  const updateCurrentMessages = (data) => {
    let newMessage = {
      connection_id: data.connection_id,
      message_id: data.message_id,
      sender: data.sender,
      receiver: data.receiver,
      message: data.message,
      media: data.media,
      status: 0,
      created_at: data.created_at,
      updated_at: data.updated_at,
    }
    setCurrentChat(prev => [...prev, newMessage]);
    scrollBottom();
  }

  const sendChat = () => {
    if(user__conection.paid===0 && user__conection.gender==="male" && user__conection.is_admin!==1){
      setModalView(true);
    }else{
      if(sendData.message === ""  && media.length === 0 && selectedMediaGallery.length === 0){
        return false;
      }
      if (words && words.length>0 && user__conection.is_admin!==1 ) {
        let blocked = false;
        words.forEach((item,i)=>{
          if(sendData.message.toLowerCase().includes(item['keyword'].toLowerCase())){
            toast.error( `  מההודעה שלך  "${item['keyword']}" keyword אנא הסר `);
            blocked = true;
            return;
          }
        })
        if(blocked){
          return;
        }
      }

      let mediaMessages = [];
      if(media.length>0){
        media.forEach((item,i)=>{
          mediaMessages.push(item.image);
        })
      }

      const rec_id = activeuser.id;
      // generate random unique id 26 chars
      const message_id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      socket.send(JSON.stringify({
        "type":"message",
        "to":rec_id,
        "message":sendData.message,
        "message_id": message_id,
        "connection_id": activeuser.connection_id,
        sender: user__conection.id,
        receiver: rec_id,
        media: JSON.stringify(mediaMessages),
        status: 0,
        created_at: new Date(),
        updated_at: new Date(),
      }))

      let tmp = chats;

      tmp.map((chat, i) => {
        console.log("chat.id", chat.id, activeuser.id);
        if (chat.id === activeuser.id) {
          chat.last_message = sendData.message
          chat.last_message_time = (new Date()).toISOString();
          chat.unread_messages = 0;
        }
        return chat;
      })
      // re-order chats according to last message time
      tmp.sort((a,b)=>{
        return new Date(b.last_message_time) - new Date(a.last_message_time);
      });
      setChats(tmp);
      chatsRef.current = tmp;
      currentChat.push({
        connection_id: currentChat.connection_id,
        message_id: message_id,
        sender: user__conection.id,
        receiver: currentChat.id,
        message: sendData.message,
        media: JSON.stringify(mediaMessages),
        status: 0,
        created_at: new Date(),
        updated_at: new Date(),
      })

      setCurrentChat(currentChat);

      setSendData({ ...sendData, message:""});


    }
    setMedia([]);
    setSelectedMediaGallery([]);
  };


  const allowedFileTypes = ["jpg", "JPG", "PNG", "WEBP", "png", "jpeg", "webp", "heic"];
  const setMediaFormate = async (images) => {
    let array_of_images = [];
    await Array.from(images).forEach(file => {
      var file_size = file.size;
      var file_extension = file.name.split('.').pop();
      if (allowedFileTypes.includes(file_extension)) {

        if(file_size > 10240000){
          toast.error("אפשר עד 10MB");
        }else{
          array_of_images.push(file)
          setMedia(media.concat(array_of_images))
        }

      }else{
        toast.error("לקבל רק תמונות");
      }
    })
  }


  const setMessage = (msg) => {
    setSendData({...sendData, message: msg})
  }


  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })


  const DeleteMessages = async () => {
    console.log("Active user", activeuser);
    const name =  activeuser?.connected_to_user?.id === user__conection.id?
        activeuser?.connected_by_user?.name:
        activeuser?.connected_to_user?.name;
    Swal.fire({
      html: lang.deleteCnfrm,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: lang.delete_it,
      confirmButtonColor: '#d7a940',
      cancelButtonText: lang.no_cancel,
      reverseButtons: true,
      icon:"question"
    }).then(async(result) => {
      if (result.isConfirmed) {

        socket.send(JSON.stringify({
          "type":"conversation_delete",
          "connection_id":activeuser?.connection_id,
        }))
        socket.send(JSON.stringify({
          "type":"get_connections",
        }))
        swalWithBootstrapButtons.fire(
            lang.deleted,
            lang.chat_has_been_deleted,
            'success'
        )
        setActiveUser(null);
        setCurrentChat([]);
        updateCurrentChat(null  );
      } else if (
          result.dismiss === Swal.DismissReason.cancel
      ) {

      }
    })
  }


  const storeSelectedGallery = (item) => {
    console.log("item", item);
    if(selectGallery.find((x)=>x.id===item.id)){
      setSelectedGallery(selectGallery.filter((x)=>x.id!==item.id));
      return;
    }
    setSelectedGallery(selectGallery.concat(item));
    setMedia(media.concat(item));
    console.log("select media", media);
  }


  const saveSelectedGalleryEvent = () => {
    const gall = selectGallery.map((item)=>{return item.image})
    setSelectedMediaGallery(gall);
    setModalShowGallery(false);
  }

  const style = langauge === 'heb' ? {fontSize:"13px",fontWeight:600, float: "right" } : {fontSize:"13px",fontWeight:600};


  const UploadGallery = async (file) => {
    if(!file){
      // toast.error('Please upload minimum 1 picture');
      return;
    }
    setDelLoader(true);
    const formData = new FormData();
    formData.append(`media[0]`,file);
    try{
      const res = await uploadGalleryAPi(formData);
      if(res.data.success){
        toast.success(res.data.message);
        setGalleryMedia(res.data.data.gallery)
      }else{
        toast.error(res.data.message);
      }
    }
    catch(error){
      toast.error('משהו השתבש');
    }
    setDelLoader(false);
  }


  const deleteGalleryImage = async (id,t) => {
    setDelLoader(true)
    try{
      const res = await galleryDeleteApi({gallery_id:id})
      if(res.data.success){
        setGalleryMedia(galleryMedia.filter((x)=>x.id!==id))
        setSelectedGallery(selectGallery.filter((x)=>x.id!==id))
        setSelectedMediaGallery(selectedMediaGallery.filter((x,i)=>i!==t))
      }else{
        toast.error(res.data.message);
      }
    }
    catch(error){

    }
    setDelLoader(false)
  }


  const handleSetMessage = (e) =>{
    setMessage(e.target.value)
  }


  const handleKeyPressed = (e) =>{
    if (e.key === "Enter") {
      sendChat()
    }
  }

  const updateCurrentChat = (user) => {
    // console.log("current chat changed", user);
    // update unread_messages to 0
    if(user){
      chats.forEach((chat, key) => {
        if (chat.id === user.id) {
          chats[key].unread_messages = 0;
        }
      });
    }
    setActiveUser(user);
    if(socketRef){
      socketRef.current.send(JSON.stringify({
        type: "get_messages",
        user_id: user.id,
        connection_id: user.connection_id,
      }));
    }

    if(bottomRef && bottomRef.current){
      bottomRef.current.scroll({
        top: bottomRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const renderMessages = () => {
    // console.log("rendering messages ", currentChat.length);
    let msgs = []
    currentChat.forEach((message, key) => {
      msgs.push(<h6
          className={`${
              message.sender !== user__conection.id ? "inbound" : "outbound"
          } message`}
          key={key}
          onTouchStart={(e) => {
            backspaceLongPress.onTouchStart(e, e.target);
          }}
          onTouchEnd={(e) => {
            backspaceLongPress.onTouchEnd(e, e.target);
          }}

          onContextMenu={(e) => {
            if(message.sender !== user__conection.id){
              return
            }
            e.preventDefault();
            console.log("Event", e);
            console.log("Right Click --=?", message.sender, activeuser, (message.sender !== user__conection.id));

            setClicked(true);
            setPoints({
              x: e.pageX,
              y: e.pageY,
            });
            setMessgaeDeleteTargetKey(key)
            setMessgaeDeleteTargetId(message.id)
            // console.log("Right Click", e.pageX, e.pageY, "-====>", key, message.id);
          }}
      >
        {message?.media && JSON.parse(message.media).length>1?
            <div className="row">
              {JSON.parse(message.media).map((item,i)=>{
                return(
                    <div className="col-6 mb-3" key={i}>
                      {/* <a href={`${IMAGE_URL}${item}`}  target="_blank" download> */}
                      <Zoom>
                        <Image style={{height:"200px",objectFit:"contain",borderRadius:"10px"}} src={`${IMAGE_URL}${item}`} />
                      </Zoom>
                      {/* </a> */}
                    </div>
                )})}
            </div>
            :message?.media && JSON.parse(message.media).length===1?
                <div className={`${message.message?"mb-3":"mb-0"}`}>
                  {/* <a href={`${IMAGE_URL}${JSON.parse(message.media)[0]}`}  target="_blank" download> */}
                  <Zoom>
                    <Image style={{height:"200px",objectFit:"contain",borderRadius:"10px"}} src={`${IMAGE_URL}${JSON.parse(message.media)[0]}`} />
                  </Zoom>
                  {/* </a> */}
                </div>
                :null}
        {message.message}
      </h6>)
    })
    return msgs
  }

  const deleteChat = async () => {
    console.log("delete chat", messgaeDeleteTargetKey, messgaeDeleteTargetId);
    setClicked(false);
    socket.send(JSON.stringify({
      type: "delete_message",
      message_id: messgaeDeleteTargetId,
      connection_id: activeuser.connection_id,
      to: activeuser.id,
    }));
    setCurrentChat(currentChat.filter((x,i)=>i!==messgaeDeleteTargetKey))
  }

  return (

      <>
        <InboxWrapper type={user__conection.is_admin} >
          {clicked && (
              <ContextMenu top={points.y} left={points.x} onClick={()=>deleteChat()}>
                <span >{lang.delete}</span>
              </ContextMenu>
          )}
          <div className="container_ h-100 w-100">
            <div className="wrapper h-100 w-100">
              <div className="head d-flex align-items-center flex-lg-nowrap flex-wrap justify-content-between">
                <h1 className="m-0 text-black text-capitalize">{lang.messages}</h1>
                <div className="search d-flex align-items-center">
                  <input type="text" placeholder={lang.search} onChange={(e)=>setSearchedValue(e.target.value)} />
                  <img src={searchIcon} alt="search icon" />
                </div>
              </div>
              <div className="body_ d-flex" style={{marginBottom:"15em"}}>
                <div className="users-bar h-100" style={{paddingBottom:"10em"}}>
                  <h6>{lang.chat_users}</h6>
                  <div className="online-user-divider"></div>
                  {chats && chats.length>0 && chats.map((res_user, key) => {
                    if(searchedValue){
                      if(!res_user.name.toLowerCase().includes(searchedValue.toLowerCase())){
                        return null;
                      }
                    }
                    return (
                        <div
                            className={`recent-user d-flex active`}
                            key={key}
                            onClick={() => {updateCurrentChat(res_user)}}
                        >
                          <div className={`user-avatar position-relative ${ (onlineUsers.includes(res_user.id))?"online":"offline"}`}>
                            <Image src={config.backEnd+"/"+res_user.profile} alt="user" onError={(e) => e.target.src = userAvatar(res_user)}/>
                          </div>
                          <div className="text-info d-flex align-items-center justify-content-between flex-grow-1">
                            <div className="flex-grow-1">
                              <h2 className="text-capitalize">{res_user?.name}</h2>
                              {res_user.last_message ? (
                                  <p>
                                    {res_user.last_message}
                                  </p>
                              ) : null}
                            </div>
                            {res_user.unread_messages > 0 && res_user.last_message_from === res_user.id ?
                                <span className="unread d-flex align-items-center justify-content-center">
                            {res_user.unread_messages}
                          </span> : ''}
                          </div>
                        </div>
                    );
                  })}
                </div>
                <div className={`chat-box h-100 ${media.length>0?"active_media":null} ${activeuser?.id?"active":""}`}>
                  <div className="head d-flex align-items-center justify-content-between">
                    <i className={`ri-arrow-${langauge==="heb"?"right":"left"}-s-line d-md-none`} onClick={()=>setActiveUser(null)} style={{fontSize:"30px"}}></i>
                    <h2 className="d-flex flex-md-row flex-column align-item-center text-black text-center mb-0 text-capitalize mx-md-0 mx-auto">
                    <span>
                      {activeuser ? (
                          (activeuser.prof_type===-23)?
                              <img
                                  src={config.backEnd+"/"+activeuser?.profile}
                                  alt="user avatar"
                                  className="avatar_"
                                  onError={(e) => e.target.src = userAvatar(activeuser)}
                              />
                              :
                              <Link to={`/profile/${activeuser?.id}`}>
                                <img
                                    // src={userAvatar(activeuser)}
                                    src={config.backEnd+"/"+activeuser?.profile}
                                    alt="user avatar"
                                    className="avatar_"
                                    onError={(e) => e.target.src = userAvatar(activeuser)}
                                />
                              </Link>
                      ) : null}
                    </span>
                      {activeuser ? (
                          <Box className='d-flex align-items-center justify-content-center'>
                      <span className="user_text">
                        {activeuser.name}
                      </span>
                            {/* <OverlayTrigger> */}
                            {/* <img
                        src={userAvatar(activeuser?.connected_to_user?.id === user__conection?.id?activeuser.connected_by_user:activeuser?.connected_to_user)}
                        alt="user avatar"
                        className="avatar_ d-md-block d-none"
                      /> */}
                            {/* </OverlayTrigger> */}
                          </Box>
                      ):null}
                    </h2>
                    {activeuser ? (
                        <button className='border-0 p-0 delete_messages_btn bg-transparent' disabled={del_loader} onClick={()=>DeleteMessages()}>
                          <img src={Trash} width="25px" alt="delete messages" />
                        </button>

                    ) : null}
                  </div>
                  {currentChat ? (
                      <>
                        <div className="body" id="chat_body" >
                          {/* <p className="time text-center">Today at 5:03 PM</p> */}
                          {renderMessages()}
                          {/* <p className="time text-center">Today at 5:03 PM</p> */}
                          <div ref={bottomRef} className="message" style={{display:"hidden"}} />
                        </div>
                        <div className="footer d-flex">
                          <Dropdown align={langauge==="heb"?"end":"start"}>
                            <Dropdown.Toggle variant="none" bsPrefix="custom border-0 attach-border-m p-0 w-auto" style={{minWidth:0,background:'transparent'}}>
                              <label className="attach-file border-0 center" type="button"  onClick={()=>setModalShowGallery(true)}>
                                <img src={attachPin} alt="icon" />
                              </label>
                            </Dropdown.Toggle>
                          </Dropdown>
                          <div className="d-flex flex-grow-1 message-input-wrapper">
                            <div className="flex-grow-1">
                              {media.length>0 || selectedMediaGallery.length>0?
                                  <div className="output-result">
                                    <ul className="d-flex list-unstyled mb-0 list-inline">
                                      {media.length>0 && media.map((item,key)=>{
                                        return(
                                            <li key={key} className="list-inline-item position-relative">
                                              <i className="ri-close-circle-fill" onClick={()=>setMedia(media.filter((x,i)=>i!==key))} style={{fontSize:"20px", color: "Gray"}} type="button"></i>
                                              {/* <img height="58px" src={item.image} alt="Result Image" /> */}
                                              <Image  height="58px" src={config.backEnd+"/"+item.image} alt="user" onError={(e) => e.target.src = config.backEnd+"/default-user.png"}/>
                                            </li>
                                        )
                                      })}
                                      <li>
                                        <Dropdown>
                                          <Dropdown.Toggle variant="none" bsPrefix="dropdown-custom border-0 attach-border-m p-0 w-auto" style={{minWidth:0,background:'transparent'}} onClick={()=>setModalShowGallery(true)}>
                                            <label className="file-upload-gallery d-flex align-items-center justify-content-center">
                                              <i className="ri-add-circle-fill position-static"></i>
                                            </label>
                                          </Dropdown.Toggle>
                                        </Dropdown>
                                      </li>
                                    </ul>
                                  </div>:null}

                              {/* <InputEmoji value={sendData.message} onChange={(txt)=>setMessage(txt)} onEnter={sendChat} placeholder={lang.type_message}/> */}
                              {/* // onChange={(e) => setSendData({ ...sendData, message:e.target.value })} */}
                              <InputEmoji
                                  className={`text-rtl`}
                                  value={sendData.message}
                                  onChange={(txt)=>setMessage(txt)}
                                  onEnter={sendChat}
                                  placeholder={lang.type_message}
                                  keepOpened
                              />

                              {/* <input className={`send-msg-input-element`} type="text" onChange={handleSetMessage}
                            onKeyPress={handleKeyPressed}
                            value={sendData.message}
                            // onChange={(txt)=>setMessage(txt)}
                            // onEnter={sendChat}
                            placeholder={lang.type_message}
                            keepOpened 
                          /> */}

                            </div>
                            <button
                                className="border-0 circle__btn"
                                onClick={() => sendChat()}
                            >
                              <img src={rightArrow} alt=">" />
                            </button>
                          </div>
                        </div>
                      </>
                  ) : (
                      <Heading className="text-center py-5" size={20}>
                        <Box className="d-block">
                          <i
                              className="ri-message-2-fill"
                              style={{ fontSize: "50px", color: "#006aff" }}
                          ></i>
                        </Box>
                        {lang.click_start_chat}
                      </Heading>
                  )}
                </div>
              </div>
            </div>
          </div>
        </InboxWrapper>
        {modalView?
            <UpgradePremiumModel onModalCancel={setModalView} />
            :null}
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            show={modalShowGallery}
            onHide={() => setModalShowGallery(false)}
            centered
            backdrop="static"
        >
          <Modal.Header closeButton={!del_loader}>
            <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:"20px",fontWeight:700}}>
              {user__conection?.name}'s {lang.gallery}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="user-side-wrap mx-0">
            <Row className="user-listing pb-0 d-flex pt-0 mx-0">
              {galleryMedia.length>0 && galleryMedia.map((item,key)=>{
                const active_data = item.id && selectGallery.length> 0 &&
                    selectGallery.find((x)=>x.id===item?.id);
                return(
                    item?.image?
                        <Col lg={3} md={4} xs={6} key={key} className="mb-3 gallery__data gallery___item">
                          <Box className={`user-card h-200 position-relative m-0${active_data?" selected":''}`}
                               style={{height:"200px !important",flexBasis:"auto",maxWidth:"inherit",cursor:"pointer"}}>
                            <Image
                                onClick={()=>storeSelectedGallery(item)}
                                className="w-100"
                                src={IMAGE_URL+item.image}
                                style={{
                                  height: "100%",
                                  maxHeight: "initial",
                                  objectFit:"cover"
                                }}
                            />
                            {!del_loader?
                                <i className="ri-close-circle-fill text-secondary position-absolute"
                                   onClick={()=>deleteGalleryImage(item.id,key)} type="button">
                                </i>
                                :null}
                          </Box>
                        </Col>
                        :null
                )
              })}
              <Col lg={3} md={4} xs={6} className="mb-3 gallery__data gallery___item">
                <FileUploaderGallery>
                  <Box className='user-card bg-transparent m-0' style={{flexBasis:"auto",maxWidth:"inherit",cursor:"pointer"}}>
                    <label className="file-upload-gallery w-100 d-flex align-items-center justify-content-center">
                      <i className="ri-add-circle-fill position-static"></i>
                      <input accept="image/*" onChange={(e)=>UploadGallery(e.target.files[0])} className="d-none" type="file" />
                    </label>
                  </Box>
                </FileUploaderGallery>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="border-0" disabled={del_loader} onClick={()=>saveSelectedGalleryEvent()} style={{color:"#212529"}}>{lang.select}&nbsp;({selectGallery.length})</Button>
          </Modal.Footer>
        </Modal>
      </>
  )
};


const FileUploaderGallery = styled.div`
  .user-card{
    overflow:hidden;
    .file-upload-gallery{
      cursor:pointer;
      height:196px;
      i{
        font-size:50px;
        color:#D39B2C;
      }
    }
    span{
      height:100%;
    }
    &.bg-transparent{
      border:3px dashed #bfbfbf;
      &:before{
        display:none;
      }
    }
    .lazy-load-image-background{
      width:100%;
      height:200px;
      img{
        object-position:top center;
      }
    }
  }
`
export const InboxWrapper = styled.div`
  .user_text {
    font-size: 16px;
    margin: 0 0 0 8px;
  }
  .emoji-mart{
    width:100% !important;
  }
  .active_media #chat_body{
    padding:15px 20px 57px;
  }
  .file-upload-gallery{
    width:50px;
    height:58px;
    border:1.5px dashed #9a9a9a;
    cursor:pointer;
    i{
      color:#d39b2c;
    }
  }
  .output-result{
    background: #f1f3f6;
    position: absolute;
    bottom: 31px;
    width: 100%;
    padding: 10px 20px 13px;
    border-top-left-radius:25px;
    border-top-right-radius:25px;
    ul{
      border-bottom: 1px solid #d1d1d1;
      margin-left: -4px;
      margin-right: -4px;
    }
    i{
      font-size: 20px;
      position: absolute;
      top: -10px;
      left: -7px;
      z-index: 1;
    }
    li{
      margin-bottom:5px;
    }
    img{
      border-radius:4px;
    }
    @media(max-width:767px){
      padding:10px 10px;
      .file-upload-gallery{
        height:40px;
        width:40px;
      }
      img{
        height:40px;
      }
    }
  }
  @media(min-width: 992px){
    height: calc(100vh - 121px);
    padding: ${(props)=> props.type === 1 ? '20px 0px' : '20px 30px' };
  }
  @media(max-width: 991px){
    height: calc(100vh - 80px);
    padding: 20px 15px;
  }
  @media(min-width:991px){
    background-color: ${(props)=> props.type === 1 ? 'transparent' : '#f1f3f6' };
  }
  .container_ {
    margin-inline: auto;
    max-width: 1446px;
    .wrapper {
      height: 100%;
      width: 100%;
      border-radius: 8px;
      @media(min-width: 992px){
        background: #ffffff;
        box-shadow: 0px 12px 250px rgba(0, 0, 0, 0.1);
      }
      .head {
        @media(min-width: 992px){
          height: 70px;
          border-bottom: 1px solid #c0bec7;
          padding: 13px 13px 13px 28px;
        }
        @media(max-width: 991px){
          padding: 15px 0px;
        }
        h1 {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          @media(max-width: 991px){
            font-size: 40px;
          }
        }
        .search {
          width: 370px;
          height: 42px;
          background: #f1f3f6;
          border-radius: 8px;
          padding-right: 10px;
          @media(max-width: 991px){
            margin-top: 30px;
          }
          input {
            background-color: transparent;
            border: 0;
            height: 100%;
            flex: 1;
            padding: 0 0 0 15px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            &::placeholder {
              color: grey;
            }
          }
          img {
            width: 20px;
          }
        }
      }
      .body_ {
        height: calc(100% - 69px);
        .users-bar {
          @media(min-width:992px){
            border-right: 1px solid #c0bec7;
          }
          @media(max-width:991px){
            padding-left: 0px !important;
            padding-right:0px !important;
            ::-webkit-scrollbar{
              display:none !important;
            }
          }
          padding: 20px 28px;
          width: 390px;
          overflow-y: auto;
          scrollbar-width: thin;
          ::-webkit-scrollbar {
            width: 8px;
          }
          ::-webkit-scrollbar-track {
            background: #f1f3f6;
          }
          ::-webkit-scrollbar-thumb {
            background: #c0bec7;
          }
          h6 {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #9a9a9a;
            margin-bottom: 15px;
          }
          .user-avatar {
            min-width: 56px;
            width: 56px;
            height: 56px;
            span {
              width: 100%;
              height: 100%;
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
              object-position: top;
            }
            &::after {
              content: "";
              width: 13px;
              height: 13px;
              border-radius: 50%;
              position: absolute;
              bottom: 2px;
              right: 1px;
            }
            &.online::after {
              background: #7fd31f;
            }
            &.offline::after {
              background: #c0bec7;
            }
          }
          h5 {
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
            text-align: center;
            color: #000000;
            margin: 7px 0 0;
          }
          .online-user-divider {
            margin: 20px 0;
            border-bottom: 1px solid #f1f3f6;
            width: 100%;
          }
          .recent-user {
            margin-bottom: 20px;
            cursor: pointer;
            &.active{
              .text-info {
                border-color: #d39b2c !important;
              }
            }
            .text-info {
              border-bottom: 1px solid #f1f3f6;
              margin-left: 15px;
              padding-bottom: 10px;
              h2 {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 4px;
                color: #000000;
              }
              p {
                font-size: 15px;
                line-height: 19px;
                color: #9a9a9a;
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 200px;
              }
              .unread {
                background: linear-gradient(180deg, #dbae2a 0%, #cf8d2d 100%);
                width: 28px;
                height: 28px;
                border-radius: 50%;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #ffffff;
              }
            }
          }
        }
        .chat-box {
          @media(max-width:991px){
            &:not(.active){
              display: none;
            }
            position: fixed;
            top: 0;
            z-index: 99;
            background: #fff;
            width: 100%;
            left: 0;
          }
          flex: 1;
          .head {
            height: 75px;
            padding: 15px;
            border-color: #f1f3f6;
            box-shadow: 0 1px 9px -2px #c0c0c0;
            h2 {
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
            }
            .avatar_ {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .body {
            padding: 15px 20px;
            height: calc(100% - 150px);
            overflow-y: auto;
            scrollbar-width: thin;
            ::-webkit-scrollbar {
              width: 8px;
            }
            ::-webkit-scrollbar-track {
              background: #f1f3f6;
            }
            ::-webkit-scrollbar-thumb {
              background: #c0bec7;
            }
            .time {
              color: #c0bec7;
              font-size: 13px;
              margin-bottom: 15px;
              clear: both;
            }
            .message {
              -moz-user-select:none;
              -webkit-user-select: none;
              -ms-user-select: none;
              user-select: none;
              margin-bottom: 15px;
              padding: 15px;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #000000;
              max-width: 500px;
              min-width: 110px;
              clear: both;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: 0;
                width: 0;
                height: 0;
              }
              &.inbound {
                float: left;
                background: #dae6ff;
                border-radius: 0px 8px 8px 8px;
                &::before {
                  left: -9px;
                  border-top: 0 solid transparent;
                  border-bottom: 10px solid transparent;
                  border-right: 10px solid #dae6ff;
                }
              }
              &.outbound {
                float: right;
                background: #efefef;
                border-radius: 8px 0 8px 8px;
                &::before {
                  right: -9px;
                  border-top: 10px solid #efefef;
                  border-bottom: 10px solid transparent;
                  border-right: 10px solid transparent;
                }
              }
            }
          }
          .footer {
            height: 65px;
            padding: 10px 15px 15px;
            width: 100%;
            .react-input-emoji--container{
              background:transparent;
              margin-left:0px;
              margin-right:0px;
              border:0;
            }
            .react-input-emoji--input{
              font-size:13px;
              max-height:39px;
              word-break: break-all;
            }
            .attach-border-m{
              margin-right:10px;
              .attach-file {
                width: 50px;
                height: 50px;
                background: #d39b2c;
                border-radius: 50%;
                position: relative;
                flex-shrink: 0;
                input {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                }
              }
            }
            .message-input-wrapper {
              background: #f1f3f6;
              border-radius: 25px;
              height: 50px;
              input {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #000;
                padding-left: 20px;
                &&::placeholder {
                  color: #c0bec7;
                }
              }
              button.circle__btn {
                width: 50px;
                flex-shrink: 0;
                height: 50px;
                background: #d39b2c;
                border-radius: 50%;
                &:disabled{
                  opacity:0.7;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .user_text {
      font-size: 11px;
      margin: 0;
    }
  }
`;
export default React.memo(Inbox);
