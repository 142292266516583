import {useEffect,useState} from "react";
import axios from "axios";
import moment from "moment";
import { Badge } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { cancelSubscription, GetPackageAmount } from "../../constant/api/Api";
import { Box, ThemeButton } from "../Style";
import fileDownload from 'js-file-download'
import { saveAs } from "file-saver";
import { ASSET_URL } from "../../constant/Keys";
import { LOAD_USER_SUCCESS } from "../../redux/constants/userConstant";
import Swal from "sweetalert2";

const Subscription = ({ lang,langauge,payments }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [field,setField] = useState("");
  const [loader,setLoader] = useState(false);

  useEffect(async () => {
      try{
        const res = await GetPackageAmount();
        if(res.data.status===200){
          setField(res.data?.data?.amount)
        }else{
          toast.error(res.data.message);
        }
      }
      catch(error){
        toast.error('משהו השתבש');
      }
  },[])


  useEffect(async() => {
    try{
      const res = await GetPackageAmount();
      if(res.data.status==200){
        setField(res.data?.data?.amount)
      }else{
        toast.error(res.data.message);
      }
    }
    catch(error){
      toast.error('Something went wrong.');
    }
  },[])


  const cancelSub = async () => {
    setLoader(true);
    try{
      const res = await cancelSubscription();
      if(res.data.success) {
        dispatch({
          type: LOAD_USER_SUCCESS,
          payload: res.data.data,
        });
        toast.success(res.data.message);
      }
    }
    catch{
      toast.error('משהו השתבש');
    }
    setLoader(false);
  }   
  const showChancelPopup = () => {
    Swal.fire({
      html: `<div><h5>${lang.cancelConfirmTitle}</h5><p>${lang.cancelConfirmDesc}</p></div>`,
      // title: lang.cancelConfirmTitle,
      // text: lang.cancelConfirmDesc,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: lang.cancelConfirmBtn,
      cancelButtonColor: '#d7a940',
      confirmButtonColor: '#6e7881',
      cancelButtonText: lang.cancelDeclineBtn,
      reverseButtons: true,
      icon:"question"
    }).then(async(result) => {
      if (result.isConfirmed) {
        cancelSub()
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    })
  }
  return (
    <SubscriptionContainer>
      {user?.paid == 1 ? (
        <>
          <Box className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0">{lang.premium}</h5>
            <p className="mb-0">
              
              {lang.next_renew}
              {user.canceled?
              ""
              :
              <Badge style={{maxWidth:"max-content"}} className={`${langauge==="heb"?"me-3 text-left":"ms-3 text-right"}`}>{moment(user.subscription_expiry).format('DD-MM-YYYY')}</Badge>
              }
            </p>
          </Box>
          <Box className="d-flex btn__group__sub justify-content-end mb-4 pb-2 mt-3">
            {user.canceled?
              <span>{lang.subscriptionCancelled}</span>
            :
              <Badge  style={{maxWidth:"max-content", cursor:'pointer', display:"flex"}} className={`${langauge=="heb"?"me-3 text-left":"ms-3 text-right"}`} onClick={()=>showChancelPopup()}> <i className="ri-close-line" style={{color:"#f00", fontSize:"2em"}}></i><span style={{marginTop:"0.5em"}}>&nbsp;{lang.cancel_subscription}</span></Badge>
            }
            
            {/* <button type="button" disabled={loader} style={{background:'gray'}} className="border-0 px-3 py-2" onClick={()=>cancelSub()}>
              <i className="ri-close-line"></i>&nbsp;{lang.cancel_subscription}
            </button> */}
          </Box>
          <Box className="align-items-center justify-content-between">
            {user.free_trial === 1 ?
              <h4 className="d-block mt-4">{lang.onFreeTrial}</h4>
              :
              <span className="d-block amount mt-4">
                <small className="d-block">{"₪299.00"}</small>
                <small className="d-block">{lang.per_month}</small>
              </span>
            }
          </Box>
        </>
      ) : (
        <Box className="text-center">
          <Link to="/upgrade" className="mt-4 d-block">
            <ThemeButton disabled={!field} className="started-btn mx-auto">
              {user.free_trial===0?((user?.already_paid)?lang.get_started_already_paid:lang.get_started):lang.premium_upgrade}
            </ThemeButton>
          </Link>
          <p className="text-black mt-3 ft-16 pt-2" style={{fontWeight:"500"}}>{user.free_trial===0?lang.update_package:lang.update_premium_package}</p>
          {/* {field?"$"+field:""} */}
        </Box>
      )}
      <hr />
      {payments.data.length>0?
        <div className="overflow-auto">
          <table className="w-100">
            <thead>
              <tr className="text-capitalize">
                {/* <th>{lang.username}</th> */}
                <th>{lang.email}</th>
                <th>{lang.payment}</th>
                <th>{lang.date}</th>
                {/* <th className="text-center">{lang.download}</th> */}
              </tr>
            </thead>
            <tbody>
              {payments.data.map((item,key)=>{
                return(
                  <tr key={key}>
                    {/* <td>{item.name}</td> */}
                    <td>{user.email}</td>
                    <td>₪{item.payment}</td>
                    <td>{item.created_at}</td>
                    {/* <td className="text-center">
                      {item.link?
                        <a href={`${ASSET_URL}download-pdf/${item.ID}`} className="border-0 bg-transparent text-primary">
                          <i className="ri-download-2-line" style={{fontSize:"18px"}}></i>
                        </a>
                      :null}
                    </td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      :null}
    </SubscriptionContainer>
  );
};
const SubscriptionContainer = styled.div`
  .btn__group__sub{
    button{
      font-size:13px;
      border-radius:8px;
      color:#fff;
      font-weight:600;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        font-size: 18px;
      }
    }
  }
  h5 {
    font-size: 20px;
    color: var(--text-clr);
  }
  p {
    font-size: 13px !important;
    color: #a3a3a3;
    font-weight: 500;
  }
  p.ft-20{
    font-size:21px !important;
  }
  .badge {
    color: var(--text-clr);
    font-size: 10px;
    padding: 8px 10px;
    color: white
  }
  span.amount {
    font-size: 40px;
    font-weight: 900;
    small {
      font-size: 14px;
      font-weight: 400;
    }
  }
  table {
    th {
      font-size: 13.5px;
      font-weight: 700;
      color: #646464;
    }
    td,
    th {
      border-bottom: 1px solid #dee2e6;
      padding: 10px;
    }
    td {
      font-size: 13px;
    }
  }
`;
export default Subscription;
