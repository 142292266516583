import React from "react";
// import { Content } from "../index";
import Sidebar from "../../components/admin/Sidebar";
import { Box, FlexBox } from "../../components/Style";
import TopBar from "../../components/admin/TopBar";
import PrivateRoutes from "./PrivateRoutes";
// import HebrewLanguage from "../../constant/langauges/HebrewLanguage";
import EnglishLanguage from "../../constant/langauges/EnglishLanguage";
const LayoutAdmin = () => {
  return (
    <React.Fragment>
      <FlexBox className="align-items-start overflow-hidden">
        <Box className="sidebar-container">
          <Sidebar />
        </Box>
        <Box className="main-content w-100">
          <TopBar/>
          <PrivateRoutes lang={EnglishLanguage} />
        </Box>
      </FlexBox>
      <style>{"\
        body{\
          background: #f7f7f7;\
        }\
        .main-content{\
          padding:38px;\
          margin-left:267px;\
        }\
      "}</style>
    </React.Fragment>
  );
};
export default LayoutAdmin;
