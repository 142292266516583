
// create Terms.js in src\pages\Terms.js
// for terms and conditions
import React from 'react'
import { useSelector } from 'react-redux';
import { ThemeContainer } from '../components/Style';
import HebrewLanguage from '../constant/langauges/HebrewLanguage';
import EnglishLanguage from '../constant/langauges/EnglishLanguage';
import Footer from '../components/Ui/Footer';
import { LanguageDropdownSelector, LogoSite } from "../components/Components"; //LanguageDropdownSelector
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import googlePay from '../assets/images/google-pay.jpg'
import applePay from '../assets/images/apple-pay.png'

const ApplePay = () => {
    const { langauge } = useSelector((state)=>state.clients);
    const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;

    return (
        <React.Fragment>
        {/* <div className="main-div"> */}
          <div lang={langauge==="heb"?HebrewLanguage:EnglishLanguage} />
            <div className="main-content">
                <ThemeContainer>
                    <div className="container">
                        <div style={{textAlign:"center", paddingTop:"20%", height:"87vh"}}>
                            <img alt='apple pay img' style={{width:"30em", marginBottom:"2em", maxWidth:"100%"}} src={applePay}></img>
                            <p>{lang.applePayComplete}</p>
                        </div>

                    </div>
                </ThemeContainer>
            </div>
          <Footer />
        {/* </div> */}
      </React.Fragment>
    )
}

export default ApplePay
