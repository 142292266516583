import React, { Suspense } from 'react'
import Spinner from 'react-bootstrap/Spinner'
// import { useLocation } from 'react-router-dom'
import {
  Route,
  Switch,
  Redirect,
  // BrowserRouter as Router
} from 'react-router-dom'
import { FlexBox } from '../components/Style'
import routes from './Routes'
// import { Loading } from '../components/Components'
import { useSelector } from "react-redux";


const Content = ({lang, logout, socket, socketConnect}) => {
  const { token, admin_user} = useSelector((state) => state.auth);
  return (
    <React.Fragment>
      <Suspense fallback={<FlexBox className="justify-content-center" style={{height:"100vh"}}><Spinner animation="grow" size="lg" variant="light" /></FlexBox>}>
        <Switch>
          {routes.map((route, idx) => {
            return route.component && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => (
                  token && admin_user?.is_admin!==1  ? (
                    <route.component lang={lang} {...props} socket={socket} logout={logout} socketConnect={socketConnect} />
                  ) : (
                    <Redirect
                      to={{
                        pathname: token && admin_user?.is_admin===1 ? "/admin/dashboard":"/",
                        state: { from: props.location },
                      }}
                    />
                  )
                )} 
              />
            )
          })}
          <Route path='*' render={() =>
          (
            <Redirect to="/404" />
          )
          } />
        </Switch>
      </Suspense>
    </React.Fragment>
  )
}

export default React.memo(Content)
