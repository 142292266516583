import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DiscoverIcon } from "../components/AllImages";
import { GetLikes } from "./Discover";
import { Link } from "react-router-dom";
import { Heading } from "../components/Style";
import { getAge, userAvatar } from "../helpers/helper";
// import { ASSET_URL } from "../constant/Keys";
import { LogoSite } from "../components/Components";

import './Upgrade.css';

const WishList = ({lang}) => {
  const {likedUsers} = useSelector((state)=>state.clients);
  const dispatch = useDispatch();

  useEffect(async () => {
    await GetLikes(dispatch);
  },[!likedUsers])


  
  return (
    <main>
      <div className="container px-0 full-height">  
       {/* style={{height:"100vh"}} */}
        <div className="logo color pt-4 mb-2 text-center d-md-none">
          <LogoSite height="55px" />
        </div>
        
        <div className="card card__responsive">
          <div className="card-header res_head py-md-3 px-md-4 px-0 border-bottom align-items-center mb-4">
            <div className="title" style={{width:"100%"}}>
              <h2 className="text-capitalize page-content-name mt-4 mt-md-0 favourite-header-text">{lang.favourite}</h2>
            </div>
          </div>
          
          <div className="card-body card__body">
            {likedUsers.length>0?
            <>
              <div className="user-listing pt-0 mb-4">
                {likedUsers.map((data,i) => {
                const avatar = userAvatar(data.user);
                  return(
                    <div className="user-card" key={i}>
                      <Link to={`/profile/${data.user?.id}`} className="d-block h-100">
                        <img src={avatar} />
                        <div className="user-footer">
                          <p className="text-capitalize">{data.user?.name}</p>
                          <span className={`badge ${data.user?.gender === "male" ?"male":"female"}`}>
                            <i className={`ri-${data.user.gender==="male"?"men":"women"}-line`}></i>&nbsp;
                            {data.user.birth_date?getAge(data.user.birth_date):"-"}
                          </span>
                        </div>
                      </Link>           
                    </div>    
                  )
                })}
              </div>
            </>
            :
            <div className="text-center">
              <Heading className="pt-5 text-black text-center pb-3" style={{fontSize:"20px"}}>{lang.exploar_users}</Heading>
              <Link to="/home" className="btn-primary text-white text-capitalize btn mb-5 border-0">
                <DiscoverIcon className="btn-svg-white" />&nbsp;{lang.home}
              </Link>
            </div>
            }
          </div>
        </div>
      </div>
    </main>
  );
};

export default WishList;
