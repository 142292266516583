import React from "react";
import styled from "styled-components";
import { Col, Form, Row } from "react-bootstrap";
// import { Logo } from "../components/AllImages";
import { useForm } from "react-hook-form";
import {
  FlexBox,
  Heading,
  Input,
  Label,
  P,
  ThemeButton,
} from "../components/Style";

import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch,useSelector } from "react-redux";
import { LoginBg, LoginBgMobile } from "../components/AllImages";
// import { useHistory } from "react-router";
import * as yup from 'yup';
import { LogIn_Request } from "../redux/actions/authActions";
import { ValidationMessage, useWindowSize } from "../helpers/helper";


const Login = () => {
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {width} = useWindowSize()


  const loginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required().min(6)
  });

  const { register, handleSubmit, formState: { errors } } =  useForm({resolver: yupResolver(loginSchema)}); // watch
  const onSubmit =  async (data) => {dispatch(LogIn_Request(data, "/admin/login"));};
  // const styles = {marginTop:"-10px"}

  return (
    <ScreenView>
      <Row className="h-100">
        <Col lg={6} className="h-100 left-side" style={{background:`url(${width>500?{LoginBg}:{LoginBgMobile}})`, backgroundSize:"cover", backgroundPosition:"center"}}>
          <FlexBox className="h-100 justify-content-center user-side-wrap align-items-start pt-3">
            <Box className="logo color">
              <h1>Dating</h1>
            </Box>
          </FlexBox>
        </Col>
        <Col lg={6} className="h-100 right-side p-4 p-lg-5">
          <FlexBox className="h-100 align-items-start justify-content-between flex-column">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Heading className="mx-0" mb="10px" size={28}>
                  Admin Log In
                </Heading>
                <P mb="25px">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius
                  sed pretium venenatis nunc.
                </P>
                <Box className="mb-3">
                  <Label className="mb-2">Email address</Label>
                  <Input
                    type="email"
                    className={`bg-transparent ${errors.email?"border-danger":""}`}
                    placeholder="Enter email address"
                    {...register("email")}
                  />
                 {errors?.email?<ValidationMessage error={errors?.email} />:null}
                </Box>
                <Box className="mb-3">
                  <Label className="mb-2">Password</Label>
                  <Input
                    type="password"
                    className={`bg-transparent ${errors.password?"border-danger":""}`}
                    placeholder="Enter password"
                    {...register("password")}
                  />
                {errors?.password?<ValidationMessage error={errors?.password} />:null}
                </Box>
              </Box>
              <FlexBox className="justify-content-center w-100">
                <ThemeButton disabled={loading} type="submit" className="border-0 d-flex align-items-center justify-content-center">
                 {loading ? "Loading..." : "Login"}
                </ThemeButton>
              </FlexBox>
            </Form>
          </FlexBox>
        </Col>
      </Row>
    </ScreenView>
  );
};
const ScreenView = styled.div`
  overflow: hidden;
  height: 100vh;
  .left-side {
    background-color: #f2f2f2;
  }
`;
const Box = styled.div`
  display: block;
`;
export default Login;
