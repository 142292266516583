import React, { useEffect, useState } from 'react'
import { Box, DiscoverTemplateStyled, Heading, P } from '../components/Style'
import  styled from 'styled-components'
import { Pagination } from 'swiper'
import Slider from '../components/Slider'
import { Image, SpinnrLoader } from '../components/Components';
import { useSelector,useDispatch } from 'react-redux'
import { GetAllLikes, GetAllUsers, PostLikeUser, RemoveLike } from '../constant/api/Api'
import { SETCLIENTS, SETLIKES } from '../constant/Keys'
import { Link } from 'react-router-dom'
import {  toast } from 'react-toastify'
import { getAge, userAvatar } from '../helpers/helper'


export const GetUsers = async (dispatch) => {
  const {data} = await GetAllUsers();
  if(data.success){
    dispatch({type:SETCLIENTS,payload:data.data})
    return {loader:false,status:200,data:data.data};
  }
  else {
    return {loader:false,status:400};
  }
}
export const GetLikes = async (dispatch) => {
    const response = await GetAllLikes();
    if(response.data.success){
      dispatch({type:SETLIKES,payload:response.data.data})
      return {loader:false,status:200};
    }
    else {
      return {loader:false,status:400,message:response.data.message};
    }
}
const postLike = async (e,id,clients,dispatch,likedUser) => {
    e.preventDefault();
    try{
        const {data} = await PostLikeUser({user_id:id});
        if(data.success){
            const findUser = await clients.find(function (element) {
                return element.id == id;
            });
            if(findUser){
                const pre = likedUser.concat({user:findUser});
                dispatch({type:SETLIKES,payload:pre});
                toast.success(data.message);
            }
            else{
                toast.error(data.message);
            }
        }else{
            toast.error('משהו השתבש');
        }
    }
    catch(error){
        toast.error('משהו השתבש');
    }
}
const RemoveLiked = async (e,id,clients,dispatch,likedUser) => {
    e.preventDefault();
    try{
        const {data} = await RemoveLike({user_id:id});
        if(data.success){
            const findUser = await clients.find(function (element) {
                return element.id == id;
            });
            if(findUser){
                const pre = likedUser.filter((x)=>x.user.id!==findUser.id);
                dispatch({type:SETLIKES,payload:pre});
                toast.success(data.message);
            }
            else{
                toast.error(data.message);
            }
        }else{
            toast.error('משהו השתבש');
        }
    }
    catch(error){
        toast.error('משהו השתבש');
    }
}
const DiscoverSlider = ({allData,data}) => {
    const dispatch = useDispatch();
    const {likedUsers} = useSelector((state)=>state.clients);
    const avatar = userAvatar(data);
    const liked = likedUsers.find(c => c.user.id == data.id);
    return(
        <DiscoverTemplateStyled className="bg-white custom-slider-discover">
            <Link to={`profile/${data.id}`}>
                <Box className="discover-img">
                    <Image src={avatar} className="w-100" 
                        height="350px"
                        width="100%" 
                    />
                </Box>
                <Box className="d-flex align-items-center traslate-circles justify-content-between">
                    <Box type="button" className="btn-cirlce-round rounded-circle d-flex align-items-center justify-content-center">
                        <i className="ri-restart-line"></i>
                    </Box>
                    <Box type="button" className="btn-cirlce-round lg-circle-btn rounded-circle d-flex align-items-center justify-content-center">
                        <i className="ri-close-fill"></i>
                    </Box>
                    <Box type="button" onClick={(e)=>
                        !liked?postLike(e,data.id,allData,dispatch,likedUsers):RemoveLiked(e,data.id,allData,dispatch,likedUsers)} 
                        className="btn-cirlce-round lg-circle-btn rounded-circle 
                        d-flex align-items-center justify-content-center">
                        <i className={`ri-heart-${liked?"fill":"line"}`}></i>
                    </Box>
                    <Box type="button" className="btn-cirlce-round rounded-circle d-flex align-items-center justify-content-center">
                        <i className="ri-star-s-fill"></i>
                    </Box>
                </Box>
                <Box className="py-4 text-center">
                <Heading size={22} className="text-capitalize" weight={700}>{data.name}</Heading>
                <P className="mb-0 text-capitalize" color="#9A9A9A">{data?.city}&nbsp;&nbsp;<small>
                    <i className={`ri-${data.gender=="male"?"men":"women"}-line`}></i>&nbsp;{data.birth_date?getAge(data.birth_date):"-"}</small>
                </P>
            </Box>
            </Link>
        </DiscoverTemplateStyled>
    )

}
const Discover = () => {
  const {clients} = useSelector((state)=>state.clients);
  const {likedUsers} = useSelector((state)=>state.clients);
  const dispatch = useDispatch();
  const [loader,setLoader] = useState(true);
  useEffect(async () => {
    const res = await GetUsers(dispatch);
    setLoader(res.loader);
  },[!clients])
  useEffect(async () => {
    await GetLikes(dispatch);
  },[!likedUsers])
  return (
    <main style={{padding: "20px 30px"}}>
        <div className="mx-auto" style={{maxWidth: "1366px"}}>
            <div className="card pb-5">
                <div className="card-body">
                    <div className="card-header mb-3 border-bottom justify-content-between">
                        <div className="title">
                            <h2>Discover</h2>
                        </div>
                    </div>   
                    {clients && clients.length==0?
                        loader?
                        <div className="text-center py-5">
                            <SpinnrLoader color="#000" />
                        </div>
                        :null
                    :null}
                    {clients && clients.length>0?
                    <SliderContainer className='py-4 custom-swiper-templates'> 
                        <Slider
                            updateOnWindowResize
                            spaceBetween={40}
                            slidesPerView={3}
                            grabCursor={true}
                            pagination={{ clickable: true}}
                            modules={[Pagination]}
                            ChildElements={{ data: clients, template: DiscoverSlider }}
                        />
                    </SliderContainer>
                    :
                    !loader?
                    <Heading className="py-5 text-center text-danger mb-0">No Users Found</Heading>
                    :null
                    }
                </div>
            </div>
        </div>
    </main>

  )
}
export const SliderContainer = styled.div`
  .swiper{
    padding-bottom:${(props)=>props.mbSlider?props.mbSlider:70}px;
    .swiper-pagination-bullet-active{
        background: linear-gradient(180deg, #FF8961 0%, #FD649D 100%) !important;  
    }
    .swiper-pagination-bullet{
        border:1px solid #FF8961 ;   
        background:transparent;
        width:11px;
        height:11px;
    }
  }
`;

export default Discover;