import React from 'react'
// import Dropdown from 'react-bootstrap/Dropdown';


const Nearby = () => {
  return (
    <main>
    <div className="container">
        <div className="card">
            <div className="card-body">
                <div className="card-header border-bottom align-items-center">
                    <div className="title">
                        <h2>Nearby</h2>
                    </div>
                </div>
                <div className="user-listing">
                    <div className="user-card">
                        <img src="https://thispersondoesnotexist.com/image" />
                        <div className="user-footer">
                            <p>Jane Cooper</p>
                            <span className="badge female"><i className="ri-women-line"></i> 23</span>
                        </div>
                        <div className="offer-card">
                            <p>Put yourself first in search</p>
                            <a href="" className="btn">Active</a>
                        </div>
                    </div>
                    <div className="user-card">
                        <img src="https://thispersondoesnotexist.com/image" />
                        <div className="user-footer">
                            <p>Jane Cooper</p>
                            <span className="badge male"><i className="ri-women-line"></i> 23</span>
                        </div>
                    </div>
                    <div className="user-card">
                        <img src="https://thispersondoesnotexist.com/image" />
                        <div className="user-footer">
                            <p>Jane Cooper</p>
                            <span className="badge female"><i className="ri-women-line"></i> 23</span>
                        </div>
                    </div>
                    <div className="user-card">
                        <img src="https://thispersondoesnotexist.com/image" />
                        <div className="user-footer">
                            <p>Jane Cooper</p>
                            <span className="badge male"><i className="ri-women-line"></i> 23</span>
                        </div>
                    </div>
                    <div className="user-card">
                        <img src="https://thispersondoesnotexist.com/image" />
                        <div className="user-footer">
                            <p>Jane Cooper</p>
                            <span className="badge female"><i className="ri-women-line"></i> 23</span>
                        </div>
                    </div>
                    <div className="user-card">
                        <img src="https://thispersondoesnotexist.com/image" />
                        <div className="user-footer">
                            <p>Jane Cooper</p>
                            <span className="badge male"><i className="ri-women-line"></i> 23</span>
                        </div>
                    </div>
                    <div className="user-card">
                        <img src="https://thispersondoesnotexist.com/image" />
                        <div className="user-footer">
                            <p>Jane Cooper</p>
                            <span className="badge male"><i className="ri-women-line"></i> 23</span>
                        </div>
                    </div>
                    <div className="user-card">
                        <img src="https://thispersondoesnotexist.com/image" />
                        <div className="user-footer">
                            <p>Jane Cooper</p>
                            <span className="badge female"><i className="ri-women-line"></i> 23</span>
                        </div>
                    </div>
                    <div className="user-card">
                        <img src="https://thispersondoesnotexist.com/image" />
                        <div className="user-footer">
                            <p>Jane Cooper</p>
                            <span className="badge male"><i className="ri-women-line"></i> 23</span>
                        </div>
                    </div>
                    <div className="user-card">
                        <img src="https://thispersondoesnotexist.com/image" />
                        <div className="user-footer">
                            <p>Jane Cooper</p>
                            <span className="badge male"><i className="ri-women-line"></i> 23</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
  )
}

export default Nearby;