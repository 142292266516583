import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { LanguageDropdownSelector, LogoSite } from "../components/Components";
import { Box } from "../components/Style";
import { useSelector } from "react-redux";
import HebrewLanguage from "../constant/langauges/HebrewLanguage";
import EnglishLanguage from "../constant/langauges/EnglishLanguage";


export const Welcome = () => {
  const { langauge } = useSelector((state)=>state.clients);
  const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;
  const queryParameters = new URLSearchParams(window.location.search)
  const serviceApp = queryParameters.get("utm_source")
  if(serviceApp && (serviceApp==='apple' || serviceApp==='android' || serviceApp==='browser')){
    window.localStorage.setItem("service_app", serviceApp)
  }
  return (
    <div className="user-side-wrap welcome_wrapper">
      <section className="login-wrapper" style={{minHeight:"calc(100vh)"}}>
        <Box className="py-4 text-end px-3">
          <LanguageDropdownSelector lang={lang} />
        </Box>
        <div className="container" dir={langauge==="heb"?"rtl":"ltr"}>
          <Row className="justify-content-center">
            <Col sm={4}>
              <div className="login-widget pb-4" style={{minHeight:"calc(100vh - 75.22px)"}}>
                <div className="text-white">
                  <div className="logo color mb-3">
                    <LogoSite height="60px" />
                  </div>
                  <Link to="/login" className="btn">
                    {lang.login}
                  </Link>
                  <Link to="/register" className="btn border">
                    {lang.create_account}
                  </Link>
                  <div className="social-btn mt-4">
                    {/* <button className="social fb">
                      <i className="ri-facebook-fill"></i>
                    </button>
                    <button className="social google">
                      <i className="ri-google-fill"></i>
                    </button> */}
                  </div>
                  <div className="text-center">
                    <p className="text-white">
                      {lang.click_to_start}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
};
export default Welcome;
