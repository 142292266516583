import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import GlobalStyle from "./components/GlobalStyle";
import Layout from "./routes/Layout";
import "./assets/css/style.css";
import "./assets/remix-icons/fonts/remixicon.css";
import { Box } from "./components/Style";
import { useSelector, useDispatch } from "react-redux";
import LayoutAdmin from "./routes/admin/LayoutAdmin";
import { GetRandomUsers } from "./constant/api/Api"; //UserStatus
// import { SETRANDOMUSERS } from "./constant/Keys";
import { UsersRandomalyAction } from "./redux/actions/ClientActions";
import { logout } from "./redux/actions/authActions";
import { toast } from "react-toastify";
import { LOAD_USER_SUCCESS } from "./redux/constants/userConstant";
import { useLocation } from "react-router";
import { getSingleUser } from "./constant/api/Api";
import config from './config.json';

let socket = null;

const UserApp = () => {
  const [onlineUsersInterval, setOnlineUsersInterval] = useState(null);
  const { langauge } = useSelector((state)=>state.clients);
  const {user} = useSelector((state)=>state.auth);
  const [logout_user,setLogoutUser] = useState(false)
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect( () => {
    const fetchDetails = async () => {
      if(user?.id) {
        try {
          const res = await getSingleUser(user?.id);
          if(res.data.success) {
            dispatch({
              type: LOAD_USER_SUCCESS,
              payload: res.data.data
            });
          }
        }
        catch(error){
        }
      }
    }
    fetchDetails();
  },[dispatch, pathname, user?.id])

  // logout functionality
  useEffect(()=>{
    if(logout_user===true){
      toast.success('התנתק בהצלחה');
      dispatch(logout())
    }
  },[dispatch, logout_user])


  // socket.on('disconnection', (data) => getUserActiveStatus(0));
  return (
    <>
      <GlobalStyle />
      <Box className="user-side-wrap" dir={langauge==="heb"?"rtl":"ltr"}>
        <Layout logout={setLogoutUser} />
      </Box>
    </>
  );
};


const AdminApp = () => {
  return(
    <>
      <GlobalStyle />
      <LayoutAdmin socket={socket} />
    </>
  )
}
export {UserApp , AdminApp};