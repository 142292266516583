import React,{useState, useEffect, useRef} from "react";
import { Box } from "../components/Style";
import { Badge, Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { GetUsers } from "../pages/Discover";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { GetAllUsers } from '../constant/api/Api'
import config from '../config.json';
import { FlexBox, Input, Label, ThemeButton } from "../components/Style";
import { Link } from "react-router-dom";
import { addUser,connectUser,updateUserStatus } from "../constant/api/Api";
import { toast } from "react-toastify";

const UsersManagement = () => {
  const [clientsData, setClientData] = useState([]);
  let userRefMap = {};
  const column = [
    {
      title: "Sr. #",
      field: "id",
    },
    {
      title: "Username",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Gender",
      field: "gender",
    },
    {
      title: "Payment Status",
      field: "paid",
    },
    {
      title: "Date Created",
      field: "created_at",
    },
    {
      title: "Action",
      field: "action",
    }
  ];
  const [socket, setSocket] = useState(null);
  const socketRef = useRef(null);
  const [socketConnectInterval, setSocketConnectInterval] = useState(null);
  const [is_update,setIsUpdate] = useState({id:"",is:false});
  const [loaderConnect,setLoaderConnect] = useState(false);


  const connectingUser = async (id) => {
    setLoaderConnect(true);
    try{
      const res = await connectUser({connection_with:id});
      if(res.data.status){
        toast.success('החיבור נוצר בהצלחה.');
        setLoaderConnect(false);
      }
      window.location.href = `/admin/chat/self/?u=${id}`;
    }
    catch{
      setLoaderConnect(false);
    }
  }

  const ActionButtons = ({client}) => {
    return (
      <FlexBox>
        <Link to={`/admin/user/${client.user_id}`} title="View" className="p-0 bg-transparent me-2 border-0">
          <i className="ri-eye-line" style={{fontSize:"17px",color:"#1A75BC"}}></i>
        </Link>
        
        <Dropdown>
          <Dropdown.Toggle disabled={loaderConnect} variant="none" bsPrefix="dropdown-custom border-0 attach-border-m p-0 w-auto" style={{minWidth:0,background:'transparent'}}>
            <i className="ri-message-2-line" style={{fontSize:"17px",color:"#828282"}}></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={`/admin/chat?target_user=${client.user_id}`}>
              Chat as { client.name }
            </Dropdown.Item>
            <Dropdown.Item href='javascript:void(0)' onClick={()=>connectingUser(client.user_id)}>
              Chat as admin
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </FlexBox>
    );
  };

  const resetMessageCount = (clientData) => {

    if(clientData.length>0){
      const data = [];  
      for (var x = 0; x < (clientData.length); x++) {
        const updated_data = Object.assign({}, clientData[x]);
        updated_data.status = "canceled";
        if(clientData[x].canceled){
          updated_data.paid = <Badge pill bg={"primary"}>Canceled</Badge>
          updated_data.status = "canceled";
        }else if(clientData[x].free_trial===1){
          updated_data.paid = <Badge pill bg={"info"}>Trial</Badge>
          updated_data.status = "trial";
        }else if(clientData[x].paid){
          updated_data.paid = <Badge pill bg={"success"}>Paid</Badge>
          updated_data.status = "paid";
        }else{
          updated_data.paid = <Badge pill bg={"secondary"}>Free</Badge>
          updated_data.status = "free";
        }
        updated_data.action = <ActionButtons client={clientData[x]}  />
        updated_data.gender =  <i
          className={`d-block text-center ri-${
            clientData[x].gender === "male" ? "men" : "women"
          }-line`}
        ></i>
        updated_data.created_at = <span>{moment(clientData[x].created_at).format("MMM Do YYYY")}</span>
        // updated_data.unread_count = 0;
        data.push(updated_data);
        // let ref = useRef(null);
      }
      setClientData(data);
    }


    // setClientData(messageCountObj)
  }

  const socketConnect = () => {

    const token = localStorage.datingAppauthToken;
    if(token){
      let tmpSocket = new WebSocket(`${config.websocket}?token=${token}`);
      tmpSocket.onopen = (connection) => {
        console.log("Connected to server");
        tmpSocket.send(JSON.stringify({type: 'initial_message_count'}));
        clearInterval(socketConnectInterval);
        setSocketConnectInterval(null);
        setInterval(()=>{
          tmpSocket.send(JSON.stringify({type: 'initial_message_count'}));
        }, 3000)
      };
      tmpSocket.onerror = (error) => {
        console.log("Error in connection establishment", error);
        // wait 5 seconds and try again
        setSocketConnectInterval(setInterval(function () {
          socketConnect();
        }, 5000));
      };
      tmpSocket.onclose = (event) => {
        console.log("Socket Closed Connection: ", event);
        // wait 5 seconds and try again
        setTimeout(function () {
          socketConnect();
        }
        , 5000);
      };
      tmpSocket.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if(data.type === "initial_message_count"){
          resetMessageCount(data.messageCount);
        }
      }
      setSocket(tmpSocket)
      // update SocketRef
      socketRef.current = tmpSocket;
    }else{
      // wait 10 seconds and try again
      setTimeout(function () {
        socketConnect();
      }, 10000);
    }  
  }

  useEffect(() => {
    socketConnect();
  },[])

  return (
    <>
      <Box className="mt-4">
        <Box className="mt-4 pt-3">
          <TableStyled>
            <thead>
              <tr>
                {column.map((item, index) => {
                  return (
                    <th key={index}>
                      <Box>{item.title}</Box>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {clientsData.map((list, i) => {
                return (
                  <tr key={i}>
                    {column.map((item, index) => {
                      return (
                        <td key={i + index}>
                          {(index===1 && list.unread>0)?(<><Badge pill bg={"primary"} >{list.unread}</Badge> </>):""}
                          {item.field==="id"?i+1:list[item.field]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableStyled>
        </Box>
      </Box>
    </>
  );
};

const TableStyled = styled.table`
  display: table;
  border-collapse: collapse;
  width: 100%;
  tr,
  td,
  th {
    border: 0;
    padding: 0;
  }
  thead {
    tr th {
      &:first-child div {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-child div {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      div {
        padding: 17px;
        background: #f2f2f2;
        color: #828282;
        font-weight: 700;
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
  }
  tbody td {
    padding: 10px 17px;
    font-size: 13px;
  }
`;

export default UsersManagement;
