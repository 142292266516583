import React,{useState,useEffect} from "react";
import { Box, FlexBox, Input, Label, ThemeButton } from "../components/Style";
import DataTable from "../components/Ui/DataTable";
import Select from "react-select"
import { Badge, Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TableSearchBar } from "../components/Components";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { genderOptions, paymentStatus } from "../helpers/SelectOptions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { GetUsers } from "../pages/Discover";
import { addUser,connectUser,updateUserStatus } from "../constant/api/Api";
import { toast } from "react-toastify";
import { BASE_URL, SETCLIENTS } from "../constant/Keys";
import Swal from "sweetalert2";
import { useForm,Controller } from "react-hook-form";
import { buildFormData } from "../helpers/helper";
import CloseButton from 'react-bootstrap/CloseButton';
const UsersManagement = () => {
  const resetFields = { username: '', password: '', confirm_password:"", gender:"", email:"", paid:""}
  const { clients } = useSelector((state)=>state.clients);
  const { admin_user } = useSelector((state) => state.auth);
  const [clientsData,setClientData] = useState([]);
  const [selectValue, setSelectValue]= useState('');
  const [selectUserName, setSelectUserName]= useState('');
  const [selectStatusValue, setSelectStatusValue]= useState('');
  const [searchVal,setSearchVal] = useState("");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [is_update,setIsUpdate] = useState({id:"",is:false});
  const handleClose = () => {setIsUpdate({...is_update,id:"",is:false});reset(resetFields);setShow(false)}
  const handleShow = () => setShow(true);
  const [btn_loader,setBtnLoader] = useState(false);
  const { register, handleSubmit, watch, getValues, control, formState: { errors }, reset} = useForm();
  const [loaderConnect,setLoaderConnect] = useState(false);
  const onSubmit =  async (data) => {
    setBtnLoader(true);
    const formData = new FormData();
    buildFormData(formData, data);
    if(is_update.is){
      formData.append('user_id',is_update.id);
    }
    try{
      const res = await addUser(formData,is_update.is?"/update-user":"/add-user");
      if(res.data.success || res.data.status==200){
        if(!is_update.is){
          const updated_for = clients.concat(res.data.data);
          dispatch({type:SETCLIENTS,payload:updated_for});
          window.scrollTo(0, document.body.scrollHeight);
        }else{
          const updated_for = clients.concat();
          const index_for = clients.findIndex((x)=>x.id==res.data.data.id);
          updated_for[index_for].name=res.data.data.name;
          updated_for[index_for].gender=res.data.data.gender;
          updated_for[index_for].paid=res.data.data.paid;
          updated_for[index_for].email=res.data.data.email;
          dispatch({type:SETCLIENTS,payload:updated_for});
        }
        handleClose();
        toast.success(res.data.message);
      }else{
        toast.error(res.data.message);
      }
    }
    catch(error){
      toast.error('משהו השתבש');
    }
    setBtnLoader(false);
  };
  useEffect(()=>{
    if(is_update.is){
      const obj_data_user = Object.assign({},clients.find((x)=>x.id==is_update.id));
      obj_data_user.username = obj_data_user.name;
      reset(obj_data_user)
    }
  },[is_update])
  const connectingUser = async (id) => {
    setLoaderConnect(true);
    try{
      const res = await connectUser({connection_with:id});
      if(res.data.status){
        toast.success('החיבור נוצר בהצלחה.');
        setLoaderConnect(false);
      }
      window.location.href = `/admin/chat/self/?u=${id}`;
    }
    catch{
      setLoaderConnect(false);
    }
  }
  const ActionButtons = ({client}) => {
    return (
      <FlexBox>
        <Link to={`/admin/user/${client.id}`} title="View" className="p-0 bg-transparent me-2 border-0">
          <i className="ri-eye-line" style={{fontSize:"17px",color:"#1A75BC"}}></i>
        </Link>
        <button title="Edit" onClick={()=>{setIsUpdate({...is_update,id:client.id,is:true});handleShow(true);}} className="p-0 bg-transparent me-2 border-0">
          <i className="ri-pencil-line text-secondary" style={{fontSize:"17px"}}></i>
        </button>
        <button title="Delete" className="p-0 bg-transparent border-0 me-2" onClick={async()=>{
            Swal.fire({
              title: 'Are you really want to delete?',
              showCancelButton: true,
              confirmButtonText: 'Delete',
              showLoaderOnConfirm: true,
              icon:"question",
              confirmButtonColor:"#dc3545",
              preConfirm: async () => {
                return await fetch(`${BASE_URL}delete-user`, {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'authorization':"Bearer " + localStorage.datingAppauthToken
                  },
                  body: JSON.stringify({user_id:client.id})
                })
                .then(response => {
                  if (!response.ok) {
                    throw new Error(response.statusText)
                  }
                  return response.json()
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch({type:SETCLIENTS,payload:clients.filter((x)=>x.id!==client.id)});
                Swal.fire({
                  title: `User deleted successfully`,
                  icon:"success"
                })
              }
            })
          }}>
          <i className="ri-delete-bin-5-line" style={{fontSize:"17px",color:"#EB5757"}}></i>
        </button>
        <Form.Check 
          type="switch"
          title="user status"
          checked={client?.status==1?true:false}
          onChange={async()=>{
            try{
              const res = await updateUserStatus({user_id:client.id,status:client.status==1?0:1});
              if(res.data.status==200){
                const updated_for = clients.concat();
                const index_for = clients.findIndex((x)=>x.id==client?.id);
                updated_for[index_for].status=client.status==1?0:1;
                dispatch({type:SETCLIENTS,payload:updated_for});
                toast.success(res.data.message);
              }else{
                toast.error(res.data.message);
              }
            }
            catch(error){
              toast.error('משהו השתבש');
            }
          }}
        />
         <Dropdown>
          <Dropdown.Toggle disabled={loaderConnect} variant="none" bsPrefix="dropdown-custom border-0 attach-border-m p-0 w-auto" style={{minWidth:0,background:'transparent'}}>
            <i className="ri-message-2-line" style={{fontSize:"17px",color:"#828282"}}></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={`/admin/chat?target_user=${client.id}`}>
              Chat as { client.name }
            </Dropdown.Item>
            <Dropdown.Item href='javascript:void(0)' onClick={()=>connectingUser(client.id)}>
              Chat as admin
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </FlexBox>
    );
  };
  const column = [
    {
      title: "Sr. #",
      field: "id",
    },
    {
      title: "Username",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Gender",
      field: "gender",
    },
    {
      title: "Payment Status",
      field: "paid",
    },
    {
      title: "Date Created",
      field: "created_at",
    },
    {
      title: "Action",
      field: "action",
    },
  ];
  const styles = {marginTop:"-10px",marginBottom:"10px"}
  useEffect(() => {
    GetUsers(dispatch);
  },[])
  useEffect(()=>{
    const clientData = clients.filter((x)=>x.id!==admin_user?.id);
    if(clientData.length>0){
      const data = [];  
      for (var x = 0; x < (clientData.length); x++) {
        const updated_data = Object.assign({}, clientData[x]);
        updated_data.status = "canceled";
        if(clientData[x].canceled){
          updated_data.paid = <Badge pill bg={"primary"}>Canceled</Badge>
          updated_data.status = "canceled";
        }else if(clientData[x].free_trial===1){
          updated_data.paid = <Badge pill bg={"info"}>Trial</Badge>
          updated_data.status = "trial";
        }else if(clientData[x].paid){
          updated_data.paid = <Badge pill bg={"success"}>Paid</Badge>
          updated_data.status = "paid";
        }else{
          updated_data.paid = <Badge pill bg={"secondary"}>Free</Badge>
          updated_data.status = "free";
        }
        
        updated_data.action = <ActionButtons client={clientData[x]}  />
        updated_data.gender =  <i
          className={`d-block text-center ri-${
            clientData[x].gender === "male" ? "men" : "women"
          }-line`}
        ></i>
        updated_data.created_at = <span>{moment(clientData[x].created_at).format("MMM Do YYYY")}</span>
        data.push(updated_data);
      }
      if(searchVal || selectValue || selectStatusValue || selectUserName){
        var searchResult = data;
        if(searchVal){
          searchResult = searchResult.filter(
            (current_user) =>
            current_user.email.toLowerCase().indexOf(searchVal.toLowerCase()) >
            -1
          );
        }
        if(selectValue){
          const genderClass = selectValue.value==="female"?"ri-women-line":"ri-men-line";
          searchResult = searchResult.filter((i)=>i.gender.props.className.indexOf(genderClass)>-1);
        }
        if(selectStatusValue && selectStatusValue.value){
          const genderClass = selectValue.value==="female"?"ri-women-line":"ri-men-line";
          searchResult = searchResult.filter((i)=>i.status===selectStatusValue.value);
        }
        if(selectUserName){
          searchResult = searchResult.filter(
            (current_user) =>
            current_user.name.toLowerCase().indexOf(selectUserName.toLowerCase()) >
            -1
          );
        }
        setClientData(searchResult);
      }else{
        setClientData(data);
      }
    }
  },[clients, searchVal, selectValue, selectStatusValue, selectUserName])
  const emailFilter = (val) => {
    setSearchVal(val);
  }
  const usernameFilter = (val) => {
    setSelectUserName(val);
  }
  return (
    <>
      <Box className="mt-4">
        <Box className="mt-4 pt-3">
          <FlexBox className="justify-content-between align-items-center w-100">
            <FlexBox className="mt-2 align-items-center mb-3 w-100">
              <Label className="me-2 mb-0" size="13px" weight="400" style={{marginBottom:"20px"}}>Gender</Label>
              <Box style={{ width: "150px" }} className="me-3 custom-select-bar fixed-with">
                <Select options={genderOptions} placeholder="Select..."  
                value={selectValue}
                onChange={val => setSelectValue(val)}  />
              </Box>
              <Label className="me-2 mb-0" size="13px" weight="400" style={{marginBottom:"20px"}}>Status</Label>
              <Box style={{ width: "150px" }} className="me-3 custom-select-bar fixed-with">
                <Select options={[
                    { label: "All", value: "" },
                    { label: "Paid", value: "paid" },
                    { label: "Free", value: "free" },
                    { label: "Trial", value: "trial" },
                    { label: "Canceled", value: "canceled" },
                  ]} placeholder="Select..."  
                  value={selectStatusValue}
                  onChange={val => setSelectStatusValue(val)}  />
              </Box>

              <Box style={{ width: "260px" }} className="me-2 d-flex align-items-center">
                <TableSearchBar onChange={emailFilter} value={searchVal} placeholder="Search by email" />
                {searchVal || selectValue?
                <CloseButton className="ms-3" onClick={()=>{setSelectValue("");setSearchVal("")}} />
                :null}
              </Box>
              <Box style={{ width: "260px" }} className="me-2 d-flex align-items-center">
                <TableSearchBar onChange={usernameFilter} value={selectUserName} placeholder="Search by username" />
                {selectUserName || selectUserName?
                <CloseButton className="ms-3" onClick={()=>{setSelectUserName("");setSelectUserName("")}} />
                :null}
              </Box>
              <ThemeButton onClick={handleShow} className="ms-auto px-5">Create user</ThemeButton>
            </FlexBox>
          </FlexBox>
          <DataTable rows={clientsData} column={column} />
        </Box>
      </Box>
      <Modal show={show} backdrop="static" onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>{is_update.is?"Edit":"Create New"}&nbsp;User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Label  size="14px" className="mb-2" weight={400} color="#2E2E2E">
              Username
            </Label>
            <Input type="text" placeholder="Enter username" {...register("username", { required: true })} />
            {errors.username && <small className="text-danger d-block" style={styles}>This field is required</small>}
            <Label  size="14px" className="mb-2" weight={400} color="#2E2E2E">
              Email
            </Label>
            <Input type="email" placeholder="Enter email" {...register("email", { required: true })}  />
            {errors.email && <small className="text-danger d-block" style={styles}>This field is required</small>}
            <Box className="custom-select-bar mb-3">
              <Label  size="14px" className="mb-2" weight={400} color="#2E2E2E">
                Gender
              </Label>
              <Controller   
                control={control}
                name="gender"
                render={({ field: { onChange, value }}) => (
                  <Select options={genderOptions} 
                    {...register('gender')} 
                    value={genderOptions.find(c => c.value === value)}
                    onChange={val => onChange(val.value)}
                  />
                )}
              />
              {errors.gender && <small className="text-danger d-block" style={styles}>This field is required</small>}
            </Box>
            <Box className="custom-select-bar mb-3">
              <Label  size="14px" className="mb-2" weight={400} color="#2E2E2E">
                Payment Status
              </Label>
              <Controller   
              // defaultValue= "1"
                control={control}
                name="paid"
                render={({ field: { onChange, value }}) => (
                  <Select options={paymentStatus} 
                    {...register('paid')}
                    value={paymentStatus.find(c => c.value === value)}
                    onChange={val => onChange(val.value)}
                  />
                )}
              />
              {errors.paid && <small className="text-danger d-block" style={styles}>This field is required</small>}
            </Box>
            <Label size="14px" className="mb-2" weight={400} color="#2E2E2E">
              Password
            </Label>
            <Input type="password" placeholder="Enter password" {...register("password", { required:!is_update.is?true:false })}  />
            {errors.password && <small className="text-danger d-block" style={styles}>This field is required</small>}
            <Label  size="14px" className="mb-2" weight={400} color="#2E2E2E">
              Confirm Password
            </Label>
            <Input type="password" placeholder="Enter confirm password" {...register("password_confirmation", {
              required:!is_update.is?true:false,
              validate: (val) => {
                const { password } = getValues();
                return password == val || "Confrim password should be match with password!";
              },
             })} 
            />
            {errors.confirm_password && <small className="text-danger d-block" style={styles}>{errors.confirm_password.type=="validate"?"Confrim password should be match with password!":"This field is required"}</small>}
            <Modal.Footer>
              <Button disabled={btn_loader} variant="none" className="border-0 btn-danger" style={{background:"#dc3545"}} onClick={handleClose}>
                Close
              </Button>
              <Button disabled={btn_loader} variant="none" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UsersManagement;
